
<template>
    <div>

        <v-divider></v-divider>
        <div v-if="!showManage" class="mr-6 mt-4">

            <hb-data-table-header>
                <template v-slot:description>
                    Automate Management Processes.
                </template>
                <template v-slot:actions>
                    <hb-btn small color="secondary" @click="showAdd = true">Add New Workflow</hb-btn>
                </template>
            </hb-data-table-header>
                
            <hb-data-table
                :headers="workflowHeaders"
                :items="groups"
                @click:row="editWorkflow"
            >
                <template v-slot:item.name="{ item }">{{ item.name }}</template>
                <template v-slot:item.propertiesCount="{ item }">

                    <hb-tooltip>
                        <template v-slot:item>
                            <span style="display: inline-block; border-bottom: 1px dashed #101318; padding-bottom: 2px;">{{ filteredProperties(item.Properties).length }} {{ filteredProperties(item.Properties).length  === 1 ? 'Property' : 'Properties' }}</span>
                        </template>
                    <template v-slot:header>
                        List of properties using this delinquency process.
                    </template>
                    <template v-slot:body>
                        <div v-for="p in item.Properties" :key="p.id">
                            <div>{{ getProperty(p).number }} {{ getProperty(p).name }}</div>
                        </div>
                    </template>
                </hb-tooltip>

                </template>
                <template v-slot:item.triggersCount="{ item }">{{item.triggers_count}} Stages</template>
                <template v-slot:item.duration="{ item }">{{item.duration}} Days</template>
                <template v-slot:item.actions="{ item }">
                    <hb-menu
                        options
                        align-right
                    >
                        <v-list>
                            <v-list-item @click="editProperties(item)">
                                <v-list-item-title>Edit Properties</v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item @click="editWorkflow(item)">
                                <v-list-item-title>Manage Stages</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="openDeleteWorkflowModal(item)">
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="duplicateWorkflow(item)">
                                <v-list-item-title>Duplicate</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
            </hb-data-table>
        </div>
        
        <AddEditWorkflow v-if="showAdd" :group="selected_group" v-model="showAdd" :facilityList="facilityList" @close="closeWindow" @refresh="getGroups"></AddEditWorkflow>
        <Automation v-if="showManage"  :group="selected_group" @removeSelected="removeSelected" :isOps="isOps" @editProperties="editProperties" @refresh="getGroups"></Automation>

        <hb-modal v-model="confirmRemoveWorkflow" show-help-link v-if="confirmRemoveWorkflow" size="medium" title="Delete Workflow" confirmation @close="closeWorkflowModal()">
            <template v-slot:content>
                <div class="py-4 px-6">
                    Are you sure you want to delete the following workflow?
                    <br /><br />
                    <strong>{{selected_group.name}}</strong>
                    <br /><br />
                    This action cannot be undone.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" @click="deleteWorkflow(selected_group)">Delete</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import api from "../../../assets/api.js";
import router from "../../../router";
import AddEditWorkflow from "./AddEditWorkflow.vue";
import Automation from "./Index.vue";

export default {
    name: "AutomationGroups",
    data() {
        return {
            showManage: false,
            groups:[],
            selected_group: {},
            workflowHeaders: [
                {
                    text: "Workflows",
                    value: "name",
                    width: 500
                },
                {
                    text: "Properties",
                    value: "propertiesCount",
                    sortable: false
                },
                {
                    text: "Stages",
                    value: "triggersCount",
                    sortable: false
                },
                {
                    text: "Duration",
                    value: "duration",
                    sortable: false
                },
                {
                    text: null,
                    value: "actions",
                    sortable: false,
                    align: "right",
                    width: 10
                }
            ],
            workflows: [],
            showAdd: false,
            facilityList: [],
            confirmRemoveWorkflow: false,
  
        };
    },
    components: {
        AddEditWorkflow,
        Automation
    },
    //Added by BCT Team
    props: {
        isOps:{
            type: Boolean,
            default:false
        }
    },
    
    created() {
        this.fetchfacilities();
        this.getGroups();
    },
    computed: {
        ...mapGetters({
             properties: 'propertiesStore/filtered' 

        })
    }, 

    filters: {
    
    },
    methods: {
        filteredProperties(properties){
            
            return this.properties.filter(p => properties.find(prop => prop.id === p.id)); 
        }, 
        getProperty(p){
            let property = this.properties.find(prop => prop.id === p.id )
            if(!property) return {}
            return property
        }, 
        async getGroups(updatedProperties = []) {
            let r = await api.get(this, api.TRIGGERS + "groups"); 

            this.groups = r.trigger_groups; 
            if(updatedProperties && updatedProperties.length){
                this.selected_group.Properties = updatedProperties.map((item) => { return {id: item} })
            } else{
                this.selected_group.Properties = this.selected_group.Properties || []
            }
        },
        removeSelected(){
            this.selected_group = {};
            this.showManage = false;
            this.fetchfacilities();
            this.getGroups();
        },
        editProperties(g) {
            this.selected_group = g;
            this.showAdd = true;
        },
        async deleteWorkflow(group) {
            await this.deleteGroup(group);
            this.closeWorkflowModal();
            await this.getGroups()
        },
        async deleteGroup(group) {
            await api.delete(this, api.TRIGGERS + "groups/" + group.id);
        }, 
        async duplicateWorkflow(group) {
            let r = await api.post(this, api.TRIGGERS + "/groups/" + group.id + "/duplicate"); 
            this.getGroups();
        },
        async fetchfacilities() {
            this.facilityList = this.properties.map(p => {
                return {
                    id: p.id,
                    details: p.Address.address2 ? `${p.name}, ${p.Address.address}, ${p.Address.address2}, ${this.$options.filters.formatAddress(p.Address)}` : 
                    `${p.name}, ${p.Address.address}, ${this.$options.filters.formatAddress(p.Address)}`,
                };
            });
        },
        closeWindow() {
            // this.selected_group = {}
            this.showAdd = false;
            this.group_id = '';
        },
        editWorkflow(group){
            this.selected_group = group;
            this.showManage = true;
        },
        openDeleteWorkflowModal(i){
            this.selected_group = i;
            this.confirmRemoveWorkflow = true;
        },
        closeWorkflowModal(){
            this.selected_group = {}; 
            this.confirmRemoveWorkflow = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.subdued {
    color: #a5adb4;
    line-height: 18px;
}
.hb-table-label {
    color: #101318 !important;
}
.night-light-color {
    color: #637381;
}
</style>