<template>
  <div>
    <div class="section-content no-padding fonts">
      <v-row class="d-flex justify-center">
        <v-col>
          <label>Step 1:</label>
          <a @click="download()" target="_blank">
            <hb-btn class="ml-4" color="secondary">
              Download {{ type }} Spreadsheet</hb-btn
            >
          </a>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col>
          <label>Step 2:</label>
          <label style="padding-left:20px;"
            >Fill out information offline.</label
          >
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col>
          <label>Step 3:</label>
          <hb-btn class="ml-4" id="spreadsheetupload" @click="uploadDocs()">
            Upload {{ type }} Spreadsheet</hb-btn
          >
        </v-col>
      </v-row>
    </div>
    <div class="section-content fonts no-padding" v-if="file && status">
      <v-row class="d-flex">
        <v-col class="title p-16 ">
          <span>Uploaded Spreadsheet</span>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center" v-if="status === 'success'">
        <v-col class="p-16" cols="10">
          {{ file }}
        </v-col>
        <v-col cols="2" class="pull-right text-green">
          <hb-icon color="#02AD0F">mdi-check</hb-icon> Passed Validation
        </v-col>
      </v-row>
      <v-row class="d-flex align-center" v-if="file && status === 'error'" >
        <v-col cols="6">
          {{ file }}
        </v-col>
        <v-col cols="6" class="align-right">
          <hb-icon color="#FB4C4C">mdi-close</hb-icon><span class="text-red pr-2"> Failed Validation</span>
          <hb-btn color="secondary" @click="downloadErrorFile()">
            Download
          </hb-btn>
        </v-col>
      </v-row>
    </div>
    <UploadFile
      v-if="dialog"
      :uploadLink="uploadLink"
      :type="type"
      @closeDialog="closeDialog"
      @changedStatus="changedStatus"
    ></UploadFile>
  </div>
</template>

<script>
import UploadFile from "./UploadSpeadSheet.vue";
import api from "../../../../assets/api";
import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
export default {
  name: "spreadsheet",
  mixins: [notificationMixin],
  props: {
    type: {
      type: String,
      default: ""
    },
    downloadLink: {
      type: String,
      default: ""
    },
    uploadLink: {
      type: String,
      default: ""
    }
  },
  components: {
    UploadFile
  },
  data() {
    return {
      file: "",
      dialog: false,
      status: ""
    };
  },
  watch: {
    dialog() {},
    status() {}
  },
  methods: {
    uploadDocs() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    changedStatus(status, file) {
      this.status = status;
      this.file = file;
      status == 'success' ? this.$emit("reLoadData") : '';
    },
    download() {
      this.$http.get(this.downloadLink, { responseType: "blob" }).then(res => {
        var blob = new Blob([res.body], {
          type: res.headers.get("content-type")
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.type.replace(/\s/g, "") + ".xlsx";
        link.click();
      });
    },
    downloadErrorFile() {
      this.status = '';
      this.$http
        .post(api.DOWNLOAD_ERROR_TEMPLATE, { filename: this.file },{ responseType: "blob" })
        .then(res => {
          var blob = new Blob([res.body], {
            type: res.headers.get("content-type")
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.type.replace(/\s/g, "") + "Errors.xlsx";
          link.click();
          
        }).catch(err => {
            this.showMessageNotification({ description: err.data.msg });
        });
    }
  }
};
</script>

<style scoped>
.border {
  border: 1px solid #CCCCCC;
}
.title {
  font-size: 20px;
  line-height: 32px;
}
.text-green {
  color: #02ad0f;
  text-align: end;
}

.text-red {
  color: #fb4c4c;
  text-align: end;
}
.p-16 {
  padding-left: 16px;
}

.fonts {
  font-family: "Graphik Web";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #101318;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  min-width: 220px;
  cursor: pointer;
}
#spreadsheetupload .v-btn__content {
  min-width: 216px;
}

.no-padding{
  border-bottom : 2px solid #cccccc;
  padding:0 10px !important;
  margin-bottom:10px;
}
</style>
