<template>
  <div class="footer-box row" :style="!rightDrawerFlag ? 'height: 42px;': 'height: 54px;'" v-if="activeTabData.footerConfirmLabel">
    <div class="col-8">
      <div class="footer-note" v-html="activeTabData.footerNote">
      </div>
    </div>
    <div class="col-4 text-align-end">
      <ConfirmCheckbox></ConfirmCheckbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ConfirmCheckbox from '../ConfirmCheckbox/Index.vue'
export default {
  components:{
    ConfirmCheckbox
  },
  data() {
    return {
      confirmChecked: false,
      dataLength: 0
    };
  },
  props: {
    rightDrawerFlag : {
      type: Boolean,
    }
  },
  computed: {
    ...mapGetters({
      activeTabData: "onboardingTabsStore/getActiveTab",

    }),
  },
};
</script>
<style scoped lang="scss" >
.footer-box {
  background-color: #e1fae3;
}
.footer-note{
  font-size: 14px;
}
.text-align-end{
  text-align: end !important;
}

</style>