<template>

    <div class="ma-0 pa-0" :class="{'section-content' : !isOPS && !disablePageInfo}">
        <div class="primary-section-content mt-n1 mr-6">
            <hb-header full v-if="!isOPS && !disablePageInfo">
                <hb-page-header
                    title="Administrators"
                    description="Manage Administrators, control permissions, and set up notifications."
                >
                </hb-page-header>
            </hb-header>

            <!-- Added by BCT: Added button above the notifiction message in OPS-->
            <div v-if="isOPS" class="ops-add-admin-btn mb-5">
                <hb-btn small class="float-right" @click="showAdd = true" color="secondary">Add Administrator</hb-btn>
            </div>

            <status  v-show="!isOPS"  class="mt-3" @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
            
            <!-- Added by BCT: Hide section in OPS -->
            <hb-data-table-header v-if="!isOPS">
                <template v-slot:actions>
                    <hb-btn small color="secondary" @click="showAdd = true">Add User</hb-btn>
                </template>
            </hb-data-table-header>

            <v-card class="mt-0 mb-3" elevation="0">
                <v-data-table
                    :headers="headers"
                    :items="admins"
                    disable-pagination
                    hide-default-footer
                    class="hb-data-table hb-data-table-cursor-on"
                    @click:row="viewAdmin"
                >
                    <template v-slot:[`item.first`]="{ item }">
                        {{ item.first }} {{ item.last }}
                    </template>
                    <!-- <template v-slot:[`item.email`]="{ item }">
                        {{ item.email }}
                    </template> -->
                    <template v-slot:[`item.status`]="{ item }">
                        <span v-if="item.status == '1'">
                            Active
                        </span>
                        <span v-else-if="item.status == '0'">
                            Inactive
                        </span>
                    </template>
                </v-data-table>
            </v-card>

        </div>


        <slide-out-window :class="{hide: !showContact}"  @close="hideContactWindow">

            <admin-view
                v-if="selected.id"
                :contact="selected"
                slot="body"
                @close="hideContactWindow"
            >
            </admin-view>
        </slide-out-window>


        <edit-user v-model="showAdd" v-if="showAdd" @saved="showSuccessMsg" @showSuccessMsg="updated" :selected="selected" :getCountryCode="countryCode" @closeWindow="closeWindow" @refetch="fetchData"></edit-user>

    </div>
</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import EditUser from './EditUser.vue';

    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { mapGetters } from 'vuex';

    import SortArrow from '../assets/SortArrow.vue';
    import SlideOutWindow from '../includes/SlideOutWindow.vue';
    import Pagination from '../assets/Pagination.vue';
    import AdminView from '../admin/AdminView.vue';

    import moment from 'moment';
    var qs = require('qs');

    import { parsePhoneNumber } from 'libphonenumber-js';
    import { notificationMixin } from "../../mixins/notificationMixin.js";

    export default {
        name: "UsersIndex",
        mixins:[notificationMixin],

        // Added by BCT Team: To hide in OPS
        props: ['isOPS', 'disablePageInfo'],
        data() {
            return {
                admins:[],
                selected:{},
                showEdit:false,
                showDelete: false,
                showAdd:false,
                showContact:false,
                filters:{
                    name: '',
                    email: '',
                    limit: 10,
                    offset: 0,
                    page: 1,
                    sort: '',
                    sortdir: ''
                },
                headers: [
                    { text: 'Name', value: 'first' },//name
                    { text: 'Email', value: 'email' },
                    { text: 'Status', value: 'status' }
                ],
                countryCode: ''
            }
        },
        components:{
            Modal,
            EditUser,
            SortArrow,
            SlideOutWindow,
            Pagination,
            AdminView,
            Status
        },
        created (){
            this.fetchData();
        },
        filters: {

        },
        computed: {
            ...mapGetters({
                storeInterPropertyName: 'authenticationStore/getInterPropertyName'
            })
        },
        methods:{
            showSuccessMsg(msg,response){
               // this.successSet(this.$options.name, msg)
                     let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );
                    const role = response.admin.Roles.find(data => data.name.toLowerCase().split(" ").join("-") !== this.storeInterPropertyName) 
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: response.admin.first+" "+response.admin.last + " has been successfully added as a " + role.name + "."});
            },
            updated(msg,response, payload = null){
                    let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );    
                    const fullName = payload ? payload.first +" "+ payload.last : response?.admin?.first +" "+ response?.admin?.last;               
                    const role = response.admin.Roles.find(data => data.name.toLowerCase().split(" ").join("-") !== this.storeInterPropertyName) 
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: fullName + " has been successfully updated as a " + role.name + "."});
            },
            goToPage(page){
                this.filters.offset = (+this.filters.limit * (+page-1));
                this.search(true);
            },
            prev(){
                this.filters.offset =  (+this.filters.offset <= +this.filters.limit) ? 0 : +this.filters.offset - +this.filters.limit;
                this.search(true);
            },
            next(){
                this.filters.offset = +this.filters.offset + +this.filters.limit;
                this.search(true);
            },


            fetchData(){
                var urlParams = qs.parse(this.$route.query);
                this.startLoading(this.$options.name);

                api.get(this, api.ADMIN, urlParams).then(r => {
                    this.admins = [ ...r.admins ];
                });

            },

            setSort(type){

                if(this.filters.sort != type){
                    this.filters.sort = type;
                    this.filters.sortdir = "DESC";
                } else if(this.filters.sort == type){
                    if(this.filters.sortdir == "ASC"){
                        this.filters.sort = '';
                        this.filters.sortdir = '';
                    } else if(this.filters.sortdir == "DESC") {
                        this.filters.sortdir = 'ASC';
                    }
                }
                this.search();
            },

            viewAdmin(u){
                //console.log(u);
                //this.showContact = true;

                api.get(this, api.ADMIN  + u.contact_id).then(r => {

                    try {
                      var parseEditedPhone = parsePhoneNumber('+' + r.admin.Phones[0].phone);
                    } catch(err) {
                        // console.log(err);
                    }

                    if(parseEditedPhone){
                        this.countryCode = parseEditedPhone.countryCallingCode;
                        r.admin.Phones[0].phone = parseEditedPhone.nationalNumber;
                    }

                    this.selected = JSON.parse(JSON.stringify(r.admin));
                    this.showAdd = true
                });
            },

            hideContactWindow(){
                this.showContact = false;
                this.selected = {};
            },





            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
            },

            editItem(u){
                this.selected = u;
                this.showEdit = true;
            },
            deleteItem(u){

                this.selected = u;
                this.showDelete = true;
            },
            deleteConfirm(){

                api.delete(this, api.USERS, this.selected.user_id ).then(r => {
                    this.showDelete = false;

                    this.fetchData();
                });
            }
        }
    }



</script>

<style scoped>

    .ops-add-admin-btn {
        height: 40px;
    }

</style>
