<template>
  <div>
    <SpreadSheet
      type="products"
      :downloadLink="downloadlink"
      :uploadLink="uploadLink"
      @reLoadData="reLoadData"
    ></SpreadSheet>
    <Products :isOPS="true" ref="products" level="Corporate"></Products>
  </div>
</template>

<script>
import Products from "../../../settings/Products.vue";
import SpreadSheet from "../../../includes/Onboarding/Spreadsheet/Index.vue";
import api from "../../../../assets/api.js";
export default {
  components: {
    Products,
    SpreadSheet
  },
  data() {
    return {
      downloadlink: api.DOWNLOAD_SPREADSHEET_PRODUCTS,
      uploadLink: api.UPLOAD_SPREADSHEET_PRODUCTS,
      reLoadDataPrdoucts: false
    };
  },
  methods: {
    reLoadData() {
      this.$refs.products.fetchData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
