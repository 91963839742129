<template>
  <div>
    <v-card class="mx-auto">
      <v-card-text v-if="onboardingData.company.length && onboardingData.propertyData.length">    
         <div class="mb-2">
          <hb-notification
            v-if="onboardingData.propertyData[0].property_status == 'merge-fail'"
            type="warning"
            notDismissable
            >
              We encountered some issue during merge. Please contact your Technical Lead for further action.           
          </hb-notification>
        </div>
        <div class="go-live-title text--primary ml-2">
          Your Go Live Date:          
            {{ onboardingData.propertyData[0].golive_date | formatDate }}
          
        </div>
        <div class="info-container mt-2 ml-2">
          <b>Technical Lead: </b>{{ onboardingData.company[0].tech_contact_name}}|
                {{ onboardingData.company[0].tech_contact_phone | formatPhone}} |
                {{ onboardingData.company[0].tech_contact_email }}
        </div>
      </v-card-text>
      <hr />
      <v-container class="mb-6 ml-2 mr-2">
        <v-row no-gutters>
          <v-col v-if="onboardingData.company.length && onboardingData.propertyData.length">
            <div >
              <div class="setup-msg">You are going to be merging:</div>
              <div class="company-name" >
                {{ onboardingData.propertyData[0].name}}
              </div>
              <div class="company-address">
                {{AddressDetails }}
              </div>
              <div class="get-started-btn-container" >
                <hb-btn v-if="btnEnable && (onboardingData.propertyData[0].property_status == 'merge-in-progress')" class="get-started-btn" @click="openModal()">
                  Merge to Hummingbird
                </hb-btn>                
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="mr-2 mb-2">
              <div class="container-note">
                <b>Note:</b> We will conduct QA testing in your live HB
                environment, including testing with real credit cards, to be
                sure everything is working as expected. You can either provide a
                credit card for this testing (and void / refund )
              </div>
            </div>
          </v-col>
        </v-row>
         <confirmMerge
          v-if="dialog"
          :dialogModal="dialog"
          @closeModal="closeModal"
          @confirm="mergeInHummingbird" ></confirmMerge>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import api from "../../../assets/api.js";
import confirmMerge from "../../includes/Onboarding/ConfirmMerge/Index.vue";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';

export default {
  mixins: [notificationMixin],
  data() {
    return {
      btnEnable:true,
      dialog: false
    };
  },
  components:{
    confirmMerge
  },
  computed: {
    ...mapGetters({
      links: "onboardingTabsStore/getOnboardingTabs",
      onboardingData: "onboardingTabsStore/getOnboardingData"
    }),
    AddressDetails() {
      return this.onboardingData.property.Address.address2 ? 
    `${this.onboardingData.property.Address.address}, ${this.onboardingData.property.Address.address2}, ${this.$options.filters.formatAddress(this.onboardingData.property.Address)}` :
      `${this.onboardingData.property.Address.address}, ${this.$options.filters.formatAddress(this.onboardingData.property.Address)}`}
  },
  filters: {
    formatDate(value) {
      if (!value) return null;
      return moment(value).format("MMMM DD YYYY");
    }
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },  
    async mergeInHummingbird() {
      this.closeModal();
       this.$store.dispatch("notificationsStore/setValidatingNotification", {
        id: "merge-notification",
        title: 'Merging to Hummingbird',
        text : 'We are currently merging your information to the Hummingbird database.'
      });
      document.getElementById("overlay").style.display = "block";
      let params = {property_name: this.onboardingData.propertyData[0].name};
      await api.post(this, api.BEGINMERGE, params).then(res => {
           this.mergeSuccess()
      }).catch(e => {
          this.mergeFail();
      });
    },
    mergeSuccess(){
        document.getElementById("overlay").style.display = "none";
        this.$store.dispatch("onboardingTabsStore/setActiveTab", this.links['property']); 
        this.$store.dispatch("notificationsStore/setRemoveMergeNotification", {  id: "merge-notification_Open", formErrors: [] });
        this.$store.dispatch( "notificationsStore/setNotificationWithButton", { id: this.$options.name, text: 'Settings has been merged to Hummingbird on ' + moment().format('MMM DD, YYYY @ h:mma.'), btnText : 'Go to Hummingbird' , property_status : 'completed_property_setting'} );
        this.$store.dispatch('onboardingTabsStore/setOnboardingData');
    },
    mergeFail(){  
      document.getElementById("overlay").style.display = "none";
      this.$store.dispatch("notificationsStore/setRemoveMergeNotification", {  id: "merge-notification_Open", formErrors: [] });
      let params = {property_id: this.onboardingData.propertyData[0].property_id, status : 'merge-fail'} ;
      this.showMessageNotification({ description: 'We encountered some issue during merge. Please contact your Technical Lead for further action.' });
      api.post(this, api.UPDATESTATUS, params, this.$options.name).then(r => {      
        this.$store.dispatch('onboardingTabsStore/setOnboardingData');
      })
    }
  }
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.go-live-title {
  font-size: 18px;
  color: black;
  font-weight: 600;
}
.info-container {
  font-size: 14px;
}
.container-note {
  font-size: 12px;
  color: #637381;
}
.get-started-btn {
  background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  color: white;
}
.setup-msg {
  font-size: 14px;
  font-weight: bold;
}
.company-name {
  margin-top: 15px;
  font-size: 20px;
  font-weight: bold;
}
.company-address {
  font-size: 14px;
  margin-top: 15px;
}
.get-started-btn-container {
  margin-top: 40px;
}
</style>

