var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"fill-height mt-60",attrs:{"absolute":"","permanent":"","width":"220","right":""}},[_c('router-link',{attrs:{"to":'property'}},_vm._l((_vm.links),function(itemChild,b){return _c('v-list-item',{key:b,staticClass:"list-item-child",class:[
        (itemChild.title === _vm.tab.title && _vm.$route.name === 'property') ? 'activeClass' : 'notActive' 
      ]},[_c('v-list-item-action',{staticClass:"ma-0 ml-1 mr-1",class:[
          itemChild.status == 'locked' ? 'disableClass' : 'enableClass'
        ],on:{"click":function($event){return _vm.activeLinkTab(itemChild)}}},[(itemChild.status == 'locked')?_c('hb-icon',{attrs:{"small":true,"color":'#101318'}},[_vm._v("mdi-lock")]):_vm._e(),(itemChild.status == 'complete')?_c('hb-icon',{attrs:{"small":true,"color":"#02AD0F"}},[_vm._v("mdi-check-circle-outline")]):_vm._e()],1),_c('v-list-item-content',{class:[
          itemChild.status == 'locked' ? 'disableClass' : 'enableClass'
        ],on:{"click":function($event){return _vm.activeLinkTab(itemChild)}}},[_c('v-list-item-title',[_c('span',{staticClass:"hb-default-font-size",attrs:{"title":itemChild.title}},[_vm._v(_vm._s(itemChild.title))])])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }