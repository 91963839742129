<template>
    <div>
        <div class="slide-out-section">
            <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
            <div class="interaction-category-header">
                <h3 class="interaction-heading">Notification Settings</h3>
                <p>You will be notified in the app when any of the below happens.  You can optionally receive an email or text message in addition to the in-app notifications so that you can respond right away.</p>

            </div>
        </div>

        <div class="slide-out-section settings-form"  >
            <div class="table">

                <div class="table-row" v-for="(type, i) in activity_types" :key="i">
                    <div class="table-cell">
                        {{type.label}}
                    </div>
                    <div class="table-cell">
                        <div class="checkbox">
                            <input
                                    type="checkbox"
                                    v-model="type.email"
                                    :id="'checkbox-email-' + type.activity_action_id + '_'+ type.activity_object_id"
                                    :name="'checkbox-email-' + type.activity_action_id + '_'+ type.activity_object_id"
                                    :data-name="'checkbox-email-' + type.activity_action_id + '_'+ type.activity_object_id"
                                    class="w-checkbox-input">

                            <label :for="'checkbox-email-' + type.activity_action_id + '_'+ type.activity_object_id" class="w-form-label">
                               Send me an email
                            </label>
                        </div>
                    </div>

                    <div class="table-cell">
                        <div class="checkbox">
                            <input
                                    type="checkbox"
                                    v-model="type.text"
                                    :id="'checkbox-text-' + type.activity_action_id + '_'+ type.activity_object_id"
                                    :name="'checkbox-text-' + type.activity_action_id + '_'+ type.activity_object_id"
                                    :data-name="'checkbox-text-' + type.activity_action_id + '_'+ type.activity_object_id"
                                    class="w-checkbox-input">

                            <label :for="'checkbox-text-' + type.activity_action_id + '_'+ type.activity_object_id" class="w-form-label">
                                Send me a text
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slide-out-section">
            <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
            <button @click="saveSettings" :disabled="isLoading($options.name)" class="w-button primary-btn">Save Settings</button>
            <span v-show="isLoading($options.name)" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </div>
    </div>

</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: "NotificationSettings",
        data() {
            return {
                loaded:false,
                lead:{
                    id: null
                },
                chosen:[],
                emails:[],
                texts:[],
                activity:[],
                convertSelector: '',
                id: null,
                notifications: {
                },
                activity_types:[]



            }
        },
        created(){
            this.id = this.contact_id;
            this.fetchNotifications();
        },
        components:{
            Loader,
            Status,
            Dropdown,
            Datepicker
        },
        filters:{
            formatDate(value){
                if(!value) return null;
                return moment(value).format('MM/DD/YYYY');
            }
        },
        methods: {

            fetchNotifications(){
                api.get(this, api.ACTIVITY + 'activity-types/notification').then(r => {
                    this.activity_types = JSON.parse(JSON.stringify(r.notification_types));
                    this.fetchData();
                });
            },
            fetchData(){
                api.get(this, api.ADMIN + this.contact_id + '/notifications').then(r => {


                    this.activity_types = this.activity_types.map(a => {
                        var index = r.notifications.findIndex(n => {
                            return n.activity_action_id == a.activity_action_id && n.activity_object_id == a.activity_object_id
                        });

                        if(a <= 0) {
                            a.notification_id = null;
                            a.text = 0;
                            a.email = 0;
                            return a;
                        }
                        a.notification_id = r.notifications[index].id;
                        a.text = r.notifications[index].text;
                        a.email = r.notifications[index].email;
                        return a;
                    });

                    this.loaded = true;
                    this.$nextTick(() => {
                        this.$forceUpdate();
                    })
                });
            },

            saveSettings(){

                var data = {
                    notifications: this.activity_types
                }
                api.put(this, api.ADMIN + this.contact_id + '/notifications', data).then(r => {
                    this.successSet(this.$options.name, "Notification settings have been updated");
                });
            }

        },
        props:['contact_id'],
        watch:{
            contact_id(contact_id){
                this.id = contact_id;
                this.fetchData();
            }
        }
    }
</script>

<style scoped>

    .settings-form .w-form-label {
        display: block;
        cursor: pointer;
        font-weight: normal;
        margin-bottom: 0px;

    }

    .bordered {
        border: 1px solid #f0f0f5;


    }
    .interaction-category-header label{
        margin-left: 10px;
    }
    .interaction-category-header{
        margin-bottom:10px;
    }

</style>