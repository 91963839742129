<template>
    <div class="admins-grid m3">
      <users-index :isOPS="true" ref="usersIndex"></users-index>
    </div>
</template>

<script>
import UsersIndex from "../../../users/Index.vue";

export default {
  name: "Administrators",
  components: {
    UsersIndex
  }, 
};
</script>

<style lang="scss">
  .admins-grid {
    margin-top: -10px;
  }
</style>