<template>

    <div class="section-content pa-0 ma-0">
        <v-divider class="mt-n1"></v-divider>

        <div class="mr-6">

            <!-- <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status> -->

            <hb-data-table-header class="pt-1">
                <template v-slot:description>
                    <span v-if="label == 'promotion'">View and manage your promotions. Set dollar amount, percent based, or fixed rate promotions.</span>
                    <span v-else-if="!isOPS">View and manage your discounts. Set dollar amount, percent based, or fixed rate discounts. Discounts do not expire but can be enabled or disabled.</span>
                </template>
                <template v-slot:actions>
                    <hb-btn v-if="hasPermission('manage_promotions') && !isOPS" small color="secondary" @click="showAdd = true">Create New {{ label == 'promotion' ? 'Promotion' : 'Discount' }}</hb-btn>
                    <hb-btn v-if="isOPS" small color="secondary" @click="showAdd = true">Add Discount</hb-btn>
                </template>
            </hb-data-table-header>

            <hb-data-table
                :headers="computeHeader"
                :items="promotions"
                :loading="loading"
                @click:row="editItem"
            >
                <template v-slot:item.name="{ item }">
                    <v-tooltip bottom open-delay="250">
                        <template v-slot:activator="{ on }">
                            <v-hover v-slot:default="{ hover }">
                                <span v-on="on" class="border-bottom-dashed">
                                    {{ item.name }}
                                </span>
                            </v-hover>
                        </template>
                        <span>
                            <div class="py-1" style="width: 201px;">
                                <span class="font-weight-medium" v-if="item.description && item.description != ''">Description:<br /></span>
                                <span class="text-body-2 line-clamp" v-if="item.description && item.description != ''">{{ item.description }}<br /></span>
                                <span class="font-weight-medium" v-if="item.Creator && item.Creator.id">Created By: {{item.Creator.first}} {{item.Creator.last}}<br /></span>
                                <span class="text-body-2">{{item.created_at | formatDateTimeCustom('MMM DD, YYYY @ h:mm a')}}</span>
                            </div>
                        </span>
                    </v-tooltip>
                    <span v-if="label == 'promotion'" class="hb-text-light">
                        <br />
                        <template v-if="item.active_period == 'active'">
                            <span class="text-capitalize">{{item.active_period}}</span>
                        </template>
                        <template v-else-if="item.active_period == 'end_on'">
                            <span>Ends on: {{item.end_date | formatDateServices }}</span>
                        </template>
                        <template v-else>
                            <span>{{item.start_date | formatDateServices }} - {{item.end_date | formatDateTimeCustom('MMM DD, YYYY')}}</span>
                        </template>
                    </span>
                </template>
                <template v-if="label != 'promotion' && hasPermission('national_account_enabled')" v-slot:item.is_national_account="{ item }">
                    <span v-if="item.is_national_account">
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-enable"></hb-icon>
                    </span>
                    <span v-else>
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-disable"></hb-icon>
                    </span>
                </template>
                <template v-slot:item.amount="{ item }">
                    <span v-show="item.type == 'dollar' || item.type == 'fixed'">$</span>
                    <span>{{ item.value }}</span>
                    <span v-show="item.type == 'percent'">%</span>
                    <span v-if="item.type == 'fixed'"> Fixed</span>
                    <span v-else-if="item.type == 'percent' || item.type == 'dollar'"> Off</span>
                    <span v-if="label == 'promotion'">
                        <br /><span class="hb-text-light">Duration: {{item.months}} Month</span>
                    </span>
                </template>
                <template v-slot:item.Properties="{ item }">
                    <v-tooltip bottom open-delay="250">
                        <template v-slot:activator="{ on }">
                            <v-hover v-slot:default="{ hover }">
                                <span v-on="on" class="border-bottom-dashed">

                                    {{ item.Properties.length }} Properties
                                </span>
                            </v-hover>
                        </template>
                        <span>
                            <div class="py-1" style="width: 150px;">
                                <span class="text-body-2" v-if="item.Properties.length > 0">
                                    <v-row class="px-1" v-for="(property, i) in filteredProperties(item.Properties)" :key="i">
                                        <span class="truncate">
                                            <span class="font-weight-medium">{{property.number}}</span>-{{property.name}}
                                        </span>
                                    </v-row>
                                    <v-row class="px-1" v-if="item.Properties.length > 15">...</v-row>
                                </span>
                                <span class="text-body-2" v-else>
                                    No Properties Assigned
                                </span>
                            </div>
                        </span>
                    </v-tooltip>
                </template>
                <template v-if="label == 'promotion'" v-slot:item.PromotionRules="{ item }">
                    <v-tooltip bottom open-delay="250">
                        <template v-slot:activator="{ on }">
                            <v-hover v-slot:default="{ hover }">
                                <span v-on="on" class="border-bottom-dashed">
                                    {{ item.PromotionRules.length }} Rules
                                </span>
                            </v-hover>
                        </template>
                        <span>
                            <div class="py-1" style="width: 201px;">
                                <span v-if="item.PromotionRules.length > 0">
                                    <template v-for="(rule, i) in item.PromotionRules" >
                                        <v-row class="px-2 font-weight-medium" v-if="rule.attribute == 'price'" :class="{'pb-2' : i < (item.PromotionRules.length - 1)}">
                                            {{getAttributeLabel(rule.attribute).label}}
                                            <span class="pl-1" v-for="(r, index) in rule.values">
                                                {{r.value_label}}<span v-if="index < (rule.values.length - 1)" class="pr-1">, </span>
                                            </span>
                                        </v-row>
                                        <span v-else>
                                            <v-row class="px-2 font-weight-medium">
                                                {{getAttributeLabel(rule.attribute).label}} {{rule.values.length}}
                                            </v-row>
                                            <v-row class="px-2 font-weight-regular" :class="{'pb-2' : i < (item.PromotionRules.length - 1)}">
                                                <span v-for="(r, index) in rule.values" :key="index" >
                                                    {{r.value_label}}<span v-if="index < (rule.values.length - 1)" class="pr-1">, </span>
                                                </span>
                                            </v-row>
                                        </span>
                                    </template>
                                </span>
                                <span v-else>
                                    No Rules Assigned
                                </span>
                            </div>
                        </span>
                    </v-tooltip>
                </template>
                <!-- <template v-slot:item.pretax="{ item }">{{ item.pretax ? 'Yes': 'No' }}</template> -->
                <template v-slot:item.enable="{ item }">
                    <span v-if="item.enable">
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-enable"></hb-icon>
                    </span>
                    <span v-else>
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-disable"></hb-icon>
                    </span>

                </template>
                <template v-slot:item.actions="{ item }">
                    <hb-menu
                        options
                        align-right
                    >
                        <v-list>
                            <v-list-item @click="editItem(item)">
                                <v-list-item-title>View/Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItem(item)" v-if="hasPermission('manage_promotions')">
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="enableItem(item)" v-if="(hasPermission('enable_promotions') || hasPermission('manage_promotions')) && !isOPS">
                                <v-list-item-title ><span v-if="item.enable">Disable</span><span v-else>Enable</span> {{actionLabel}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
            </hb-data-table>

        </div>

        <edit-promotion v-model="showAdd" :isOPS="isOPS" :label="label" v-if="showAdd" :selected="selected" @showSuccess="showSuccessMessage" @close="closeWindow()" @refetch="fetchData" />

        <hb-modal v-if="showDelete" show-help-link v-model="showDelete" size="medium" :title="label == 'promotion' ? 'Delete Promotion' : 'Delete Discount'" @close="closeWindow" confirmation>
            <template v-slot:content>
                <div class="py-4 px-6">
                    Are you sure you want to delete the selected {{label == 'promotion' ? 'promotion' : 'discount'}}?
                    <br /><br />
                    This action cannot be undone.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn :disabled="isLoading('delete')" color="destructive" @click="deleteConfirm">Delete {{ label == 'promotion' ? 'Promotion' : 'Discount' }}</hb-btn>
                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
    import Modal from '../../assets/Modal.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import EditPromotion from './EditPromotion.vue';
    import draggable from 'vuedraggable';
    import api from '../../../assets/api.js';
    import { EventBus }  from '../../../EventBus.js'
    import Status from '../../includes/Messages.vue';
    import { PROMOTION } from './Constants.js';
    import { mapGetters } from 'vuex';
    import { notificationMixin } from "../../../mixins/notificationMixin.js";

    export default {
        name: "Index",
        mixins: [notificationMixin],
     // Added by BCT Team
        props: ['isOPS'],

        data() {
            return {
                list: [],
                promotions:[],
                selected: {},
                selectedId: "",
                showAdd: false,
                showEdit: false,
                showDelete: false,
                label: 'promotion',
                activeTab: 'promotion',
                loading: false,
                attributes: [
                    {label: 'Sizes:', value: 'size'},
                    {label: 'Categories:', value: 'unit_category'},
                    {label: 'Floors:', value: 'floor'},
                    {label: 'Price:', value: 'price'},
                    {label: 'Address:', value: 'address'},
                    {label: 'Mixes:', value: 'category'},
                    {label: 'Zip:', value: 'zip'},
                    {label: 'Neighborhood:', value: 'neighborhood'},
                ]
            }
        },
        components:{
            Modal,
            EditPromotion,
            draggable,
            Loader,
            Status
        },
        created(){
            this.PROMOTION = PROMOTION;

            EventBus.$emit('HB-Show-Tabs-List-Settings');
            EventBus.$on('HB-settings-Promotions:Active-Tab', this.eventSetValues);
            this.fetchData();
        },
        destroyed() {
            EventBus.$off('HB-settings-Promotions:Active-Tab', this.eventSetValues);
            EventBus.$emit('HB-Show-Tabs-List-Settings');
        },
        filters:{
            addOrdinal(value){
                if(!value) return null;
                var s = ["th","st","nd","rd"];
                var v = value % 100;
                return value + (s[(v-20)%10]||s[v]||s[0]);
            },
        },
        watch: {
          activeTab(){
            this.promotions = [];
            this.fetchData();
          },
          promotions(val) {
            val.forEach(promotion => {
                if (promotion.id === this.selectedId) {
                    this.selected = promotion
                }
            });
          }
        },
        computed: {
            ...mapGetters({
              hasPermission: 'authenticationStore/rolePermission'
            }),
            computeHeader() {
                // Added By BCT 
                return PROMOTION.HEADERS.filter(p => {
                    if (this.isOPS) {
                        return ((!p.visible || p.visible == this.label) && p.isOPS === this.isOPS);
                    } else {
                        if (p.value === 'is_national_account' && !this.hasPermission('national_account_enabled')) {
                            return false; // Exclude the 'National Account' header if the permission is not granted
                        }
                        return !p.visible || p.visible == this.label;
                    }
                });
            },
            actionLabel() {
              return this.label == 'promotion' ? 'Promo' : 'Discount';
            }
        },

       // Added by BCT Team
        mounted() {
         if (this.isOPS == true) {
             this.label = 'discount';
         }
        },
        methods:{
            filteredProperties(properties) {
                return (properties.length > 15) ? properties.slice(0, 15) : properties;
            },
            getAttributeLabel(attr) {
                return this.attributes.find(a => (a.value == attr));
            },
            closeWindow(){
                this.selected = {};
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
            },
            fetchData(){
              //this.promotions = [];

              // Added By BCT
              this.loading = true;
              this.label = this.isOPS ? "discount" : this.label;
              api.get(this, api.PROMOTIONS.substring(0, api.PROMOTIONS.length - 1)  + ('?label=' + this.label)).then(r => {
                  this.promotions = r.promotions;
                  this.loading = false;
              });
            },

            // saveNewOrder(){
            //     var data = {promotions: this.promotions};
            //     api.post(this, api.PROMOTIONS_SORT, data)


            // },
            editItem(c){

                this.selected = c;
                this.selectedId = c?.id;
                this.showEdit = true;
                this.showAdd = true
            },
            deleteItem(c){
                this.selected = c;
                this.showDelete = true;
            },
            async deleteConfirm(){
              try {
                await api.delete(this, api.PROMOTIONS, this.selected.id).then(r => {
                  this.showDelete = false;
                  this.fetchData();
                  let type = this.label == 'promotion' ? 'Promotion ' : 'Discount ';
                  let payload = {
                    id: this.$options.name,
                    type: 'success',
                    description: `The ${type} "${this.selected.name}" has been deleted.`
                  }
                  this.selected = {};
                  this.showMessageNotification(payload);
                });
              } catch(err) {
                this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }] });
              }
            },
            async enableItem(c){
              try {
                await api.put(this, api.PROMOTIONS + c.id + '/status', {enable: !c.enable}).then(r => {
                  this.fetchData();
                });
              } catch(err) {
                this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }] });
              }
            },
            showSuccessMessage(productName, isEdited = false){
                let message = ( isEdited ?  ( this.label == 'promotion' ? 'Promotion "' : 'Discount "' ) + productName + '" has been updated.' : ( this.label == 'promotion' ? 'New Promotion "' : 'New Discount "' ) + productName + '" has been created.');
                this.successSet(this.$options.name, message);
            },
            eventSetValues(value){
                this.label = value;
                this.activeTab = value;
            }
        }
    }
</script>

<style scoped>
.list-item-height {
    min-height: 40px !important;
    min-width: 156px;
}
.border-bottom-dashed {
    border-bottom: 1px dashed;
}
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
    .add-role-btn{
    font-size: 15px;
    text-decoration: none;
  }
  .add-role-btn:focus{
    border: none;
  }
  .roles-table {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
    border-radius: 4px;
  }
  .roles-table .table-head{
    background: #FFFFFF;
    /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
    border-bottom: 1px solid #ccc;
  }
  .roles-table .table-head strong{
    color: #474F5A;
  }
  .roles-table .table-row:hover:not(:first-child){
    background: #F4F6F8;
    cursor: pointer;
  }
  .roles-table .table-row:first-child {
    cursor: inherit;
  }
  .roles-table .table-row .table-cell{
    border-bottom: 1px solid #dce8ef;
    color: #101318;
  }
  .light-text{
    color: #637381;
  }
  .not-visible{
      visibility: hidden;
  }
  .roles-table .table-row:hover .not-visible{
      visibility: visible;
  }
  .truncate {
    display: inline-block;
    vertical-align:middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
    .line-clamp {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
    }
    /* added by BCT team */
    .discount_btn{
        color:black !important;
        text-decoration: none;
    }
</style>
