<template>
  <hb-modal
    size="medium"
    title="Confirm Merge"
    v-model="dialogModal"
    @close="close()"
    :closeInsteadOfCancel="true"
    :confirmation="true"
    show-help-link
  >
    <template v-slot:content>
      <div class="content-height px-6 font-style">
        <p>
          By merging your information to Hummingbird, you will no longer be able to make edits to your settings inside OPS. All future changes will be made inside Hummingbird.
        </p>
        <br/>
        <p>Are you ready to merge your settings?</p>
      </div>
    </template>
    <template v-slot:icon> </template>
    <template v-slot:actions>
      <hb-btn color="primary" @click="merge()" > Confirm </hb-btn>
    </template>
  </hb-modal>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    dialogModal:{
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    merge(){
      this.$emit("confirm");
    }
  }
};
</script>

<style scoped>
.content-height {
  min-height: 150px;
  max-height: 600px;
  overflow: auto;
}
.px-6 {
  padding: 24px;
}
.font-style {
  font-family: "Graphik Web";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #101318;
}
</style>
