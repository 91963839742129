<template>
  <div class="section-content ml-0 pt-0 pr-0 mt-n2">
    <div v-if="!$props.facilityID">
        <HbPropertySelector
            id="facility"
            :items="facilityList"
            :disabled="isLoading($options.name) || !isFacilities"
            :loading="isLoading($options.name) || !isFacilities"
            item-text="name"
            item-value="id"
            v-validate="'required'"
            v-model.lazy="property_id"
            data-vv-scope="gate"
            data-vv-name="facility"
            data-vv-as="Property"
            v-show="!isOPS"
            :error="errors.has('gate.facility')"
        />
    </div>

    <div class="primary-section-content mr-6">

        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        
        <status @resetStatus="successClear('saveConfigTemplate')" v-if="successHas('saveConfigTemplate')" :message="successGet('saveConfigTemplate')" status="success"></status>
        <status @resetStatus="errorClear('saveConfigTemplate')" v-if="errorHas('saveConfigTemplate')" :message="errorGet('saveConfigTemplate')" status="error"></status>
        
        <v-row no-gutters v-if="property_id">
            <v-col cols="12" no-gutters>
                <hb-card no-title>
                    
                    <v-col class="px-4 pt-4">
                        Choose the lease you would like to use for each type of space.
                    </v-col>
                    <div>
                        <v-col class="px-4 py-0">
                            <v-radio-group v-model="view" row class="mt-0">
                                <v-radio 
                                    v-for="item in getUnitTypes()" 
                                    :key="item.unit_type_id" 
                                    :label="item.display_name" 
                                    :value="item.unit_type_label" 
                                    v-if="getTemplatesLength(`${item.unit_type_label}`) > 0"                                   
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-divider></v-divider>

                            <hb-form
                                v-if="getTemplatesLength(view) > 0"
                                label="Lease Configuration"
                                :editable="hasPermission('view_edit_templates')"
                                :active.sync="active"
                                @cancel="cancel"
                                @save="save"
                                :empty="!selectedTemplate?.Template?.name"
                            >
                                <template v-slot:display>
                                    {{ selectedTemplate.Template.name }}
                                </template>
                                <template v-slot:edit>
                                    <hb-select
                                    v-model="preSelectedTemplate"
                                    v-validate="'required'"
                                    :clearable="false"
                                    :items="filteredTemplates"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    id="name"
                                    name="name"
                                    data-vv-scope="form_1"
                                    data-vv-as="Lease Type"
                                    :error="errors.has('form_2.lease_type')"
                                    placeholder="Select Lease Type"
                                    ></hb-select>
                                </template>
                            </hb-form>

                        <div v-if="selectedTemplate && selectedTemplate?.Template">
                            <div class="hb-font-header-3-medium pa-4">Configured Lease for {{ getUnitTypeTitleBasedOnLabel(this.view) | capitalize}} Spaces</div>
                            <v-divider></v-divider>
                        </div>


                        <div v-if="selectedTemplate && selectedTemplate?.Template && selectedTemplate?.Template?.id">
                            
                            <GeneralConfigurations :template="selectedTemplate.Template" :read-mode="true" :show-space-type="false" :facilityLevel="true" ref="GeneralConfigurations"></GeneralConfigurations>
                            <MoveInTransferConfigurations v-if="selectedTemplate.Template && selectedTemplate.Template.id" :template="selectedTemplate.Template" :read-mode="true" :facilityLevel="true" ref="MoveInTransferConfigurations"></MoveInTransferConfigurations>
                            <move-out :template="selectedTemplate.Template" :read-mode="true" :facilityLevel="true" />
                            <v-divider></v-divider>
                            
                            <BillingConfigurations :template="selectedTemplate.Template" :read-mode="true" :facilityLevel="true" />

                        </div>

                        <div
                            v-else-if="selectedTemplate && selectedTemplate?.Template && !selectedTemplate?.Template.id"
                            class="pa-6"
                        >
                            <hb-empty-state message="There is no configured lease saved for this space type. Please add a Lease Configuration above to continue."></hb-empty-state>
                        </div>

                    </div>

                </hb-card>
            </v-col>
        </v-row>

        <hb-empty-state
            v-else
            message="Please select a Property to continue."
        >
        </hb-empty-state>
    </div>
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import Loader from '../../assets/CircleSpinner.vue';
import Status from '../../includes/Messages.vue';
import GeneralConfigurations from '../configureLease/GeneralConfigurations.vue';
import MoveInTransferConfigurations from '../configureLease/MoveInTransferConfigurations.vue';
import BillingConfigurations from '../configureLease/BillingConfigurations.vue';
import MoveOut from '../configureLease/MoveOut.vue';
import HoverableEditField from "../../assets/HoverableEditField.vue";
import { EventBus } from '../../../EventBus.js';
import { mapGetters } from 'vuex';
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
    name: "LeaseConfiguration",
    data() {
        return {
            activelyEditing: '',
            tabValue: '',
            property_id: "",
            templates:[],
            view: "",
            item_config:{
                id: '',
                Template: {
                    id: '',
                    name: ''
                }
            },
            selected_unit_type_template: {
                id: '',
                name:''
            },
            isFacilities: false,
            active: '',

        };
    },
    mixins: [notificationMixin],
    components: { Loader, Status, GeneralConfigurations, MoveInTransferConfigurations, MoveOut, HoverableEditField, BillingConfigurations},
    async created() {
        EventBus.$on('activeEdit', this.setActiveEditName);
        EventBus.$on('saveData', this.save);
        if (this.$props.facilityID) {
            this.property_id = this.$props.facilityID;
        }
        await this.fetchTemplates();
        this.isFacilities = this.facilityList.length;
    },
    destroyed() {
        EventBus.$off('activeEdit', this.setActiveEditName);
        EventBus.$off('saveData', this.save);
        this.closeMessageNotification();
    },
    computed: {
        ...mapGetters({
            facilityList: 'propertiesStore/filtered',
            onboardingData: "onboardingTabsStore/getOnboardingData",
            hasPermission: 'authenticationStore/rolePermission'
        }),
        
        filteredTemplates(){
            const unitTypeMeta = this.getUnitObjectBasedOnLabel(this.view)
            return this.templates.filter(t => t.unit_type_id == unitTypeMeta.unit_type_id)
        },
        selectedTemplate(){
            if(this.view || (this.availableViews.length == 1 && this.availableViews[0] == this.view )){
                this.item_config.unit_type = this.view;
                return this.item_config;
            }
        },
        availableViews(){
            var views = [];
            this.templates.map(t => {
                if(views.indexOf(t.unit_type) < 0 ) views.push(t.unit_type)
            });
            return views;
        },
        preSelectedTemplate : {
            get: function() {
                return this.selected_unit_type_template;
			},
			set: function(value) {
                this.selected_unit_type_template = value;
			}
        },
    },
    methods: {
        setActiveEditName(name) {
            this.activelyEditing = name;
        },
        getTemplatesLength(type){
            if(type != ''){
                const typeMeta = this.getUnitObjectBasedOnLabel(type)
                return this.templates.filter(t => t.unit_type_id == typeMeta.unit_type_id).length
            }
            return 0;
        },
        fetchTemplates(){
            api.get(this, api.TEMPLATES).then(r => {
                this.templates = r.templates;
            });
        },
        transformGetData(data) {
            const transObj = { ...data };

            transObj.products = {
                movein: [{}],
                transfer: [{}],
                service: [{}]
            };
            
            let p = {};
            let prodsValue = data.products;
            for (let i = 0; i < prodsValue.length; i++) {   
                p[prodsValue[i].category_type] = p[prodsValue[i].category_type] || [];
                prodsValue[i].category_type && p[prodsValue[i].category_type].push(prodsValue[i]);

                if(prodsValue[i].category_type) {
                    transObj.products[prodsValue[i].category_type] = p[prodsValue[i].category_type];
                }
            }

            transObj.checklist = {
                lease: [],
                autopay: [],
                'enroll-coverage': [],
                'deny-coverage': [],
                vehicle: [],
                military: [],
                other: [],
                nameOnly: []
            }

            let d = {};
            const docsValues = data.checklist;
            
            for (let i = 0; i < docsValues.length; i++) {
                d[docsValues[i].document_tag || 'nameOnly'] = d[docsValues[i].document_tag || 'nameOnly'] || [];
                d[docsValues[i].document_tag || 'nameOnly'].push(docsValues[i]);
                transObj.checklist[docsValues[i].document_tag || 'nameOnly'] = d[docsValues[i].document_tag || 'nameOnly'];
            }

            return transObj;
        },
        fetchData(){
            try{
                const typeMeta = this.getUnitObjectBasedOnLabel(this.view)
                api.get(this, api.PROPERTIES + this.property_id + '/templates?unit_type_id='+ typeMeta.unit_type_id).then(r => {
                    if(Object.keys(r).length > 0){
                        this.item_config.Template = this.transformGetData(r.Template);
                        this.selected_unit_type_template = this.item_config.Template
                    }else{
                        this.selected_unit_type_template = {
                            id: '',
                            name: ''
                        };
                        this.item_config = {
                            id: '',
                            unit_type: '',
                            Template: {
                                id: '',
                                name: ''
                            }
                        }
                    }
                });
            }catch(err){
                // do nothing
            }
        },
        save(){
            let _this = this;
            let type = this.view;
            let save = {
                unit_type: type,
                unit_type_id: this.getUnitObjectBasedOnLabel(type).unit_type_id,
                lease_template_id : this.selected_unit_type_template.id
            }
            api.post(_this, api.PROPERTIES + this.property_id + '/templates',  save, 'saveConfigTemplate').then(r => {
                _this.activelyEditing = '';
                this.showMessageNotification({ id: this.$options.name, type: "success", description: 'Lease template has been successfully updated.' });
                this.active = '';
                this.fetchData();
            });
        },
        cancel(){
            this.active = '';
            this.preSelectedTemplate = this.selectedTemplate.Template;
        },
        removeTemplate(){
            api.delete(this, api.PROPERTIES + this.property_id + '/templates/', this.selectedTemplate.id, 'deleteConfigTemplate').then(r => {
                  this.fetchData();
              }).catch((err) => {
                    this.showMessageNotification({ id: this.$options.name, type: "error", description: err });
                });;

        },
        resetValues(){
            this.view = '';

            this.item_config = {
                id: '',
                unit_type: '',
                Template: {
                    id: '',
                    name: ''
                }
            }
            
            this.selected_unit_type_template = {
                id: '',
                name: ''
            }
        }
    },
    props: ["facilityID","isOPS","level"],
    watch:{
        property_id(){
            this.resetValues();
        },
        view(){
            this.fetchData();
        }
    },
    mounted(){
        if(this.isOPS && this.level === 'Property' && this.onboardingData.propertyData.length > 0){
            this.property_id = this.onboardingData.propertyData[0].property_id;
        } 
    }
};
</script>

<style lang="scss" scoped>
.interaction-text {
  padding-bottom: 10px;
}
.interaction-text {
  padding: 16px 0 16px 24px;
  background-color: white;
}
.interaction-section {
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: 0px 2px 0px rgba(11, 18, 29, 0.1),
    0px 2px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
}
.interaction-section-body {
  height: 0;
  overflow: hidden;
  background: white;
}
.interaction-section-body.open {
  height: auto;
  border-top: 2px solid #dce8ef;
}
.interaction-section h2 {
  font-size: 14px;
  line-height: 24px;
  color: #101318;
  background-color: #ffffff;
  box-sizing: border-box;
  font-weight: normal;
  margin: 0;
}
.lease-heading{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #101318;
}
.remove-btn {
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
  border: 1px solid #C4CDD5;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  border-radius: 4px;
}
.config-lease{
    font-weight: 500;
    font-size: larger;
}
</style>