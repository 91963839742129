<template>
  <hb-modal
    v-model="dialog"
    size="large"
    :title="dialogTitle"
    @close="$emit('close')"
    :footerCancelOption="!editMode"
    show-help-link
  >
    <!-- To be updated with other modal icons (discussed with design team) -->
    <!-- <template v-slot:icon>
      <span class="ml-2 mb-1">
        <hb-icon>mdi-bullhorn</hb-icon>
      </span>
    </template> -->
    <template v-slot:subheader>
      <span class="hb-text-light">
        {{ subHeaderText }} {{ promotionType.value }} to offer at your properties. Fill in all the details below to customize this {{ promotionType.value }} to your specifications.
      </span>
    </template>
    <template v-slot:content>
      <v-row
        class="hb-table-row ma-0 pa-0"
        v-if="label == PROMOTION.DISCOUNT_LABELS.promotion.value"
      >
        <v-col md="3" class="key-label pl-6 pt-3">
          <div>
            Active Period
            <v-tooltip bottom class="ml-10">
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <span v-on="on">
                    <hb-icon small color="#c4cdd5" class="ml-1"
                      >mdi-information</hb-icon
                    >
                  </span>
                </v-hover>
              </template>
              <span>
                <div class="py-1">
                  <span class="font-weight-medium"> Description </span><br />
                  <v-divider class="my-1"></v-divider>
                  The active period determines <br />
                  the dates when this promotion <br />
                  will be available.
                </div>
              </span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col md="9" class="pa-0 ma-0">
          <hoverable-edit-field
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            :activeEditing="activelyEditing == 'active_period'"
            name="active_period"
            @cancel="cancelEdit"
          >
            <template slot="editingMode">
              <v-row class="ma-0">
                <v-col md="4" class="pa-0">
                  <v-select
                    :error-messages="errors.first('active_period')"
                    hide-details
                    attach
                    :items="Object.values(PROMOTION.ACTIVE_PERIODS)"
                    class="pa-0 mt-0"
                    data-vv-as="active period"
                    id="active_period"
                    name="active_period"
                    label="Select"
                    single-line
                    v-model="promotion.active_period"
                    v-validate="'required'"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row
                v-if="promotion.active_period == PROMOTION.ACTIVE_PERIODS.active.value"
                class="ma-0 mt-3"
              >
                <v-col md="12" class="pa-0">
                  This promotion will remain active until manually disabled.
                </v-col>
              </v-row>

              <div
                v-if="
                  promotion.active_period ==
                  PROMOTION.ACTIVE_PERIODS.date_range.value
                "
              >
                <v-row class="ma-0 mt-3">
                  <v-col md="10" class="pa-0">
                    Select a start and end date for this promotion.
                  </v-col>
                </v-row>
                <v-row class="ma-0 mt-0 ml-0 pl-0">
                  <v-col md="6" class="pa-0">
                    <hb-date-picker
                      label="Start Date"
                      clearable
                      data-vv-as="due date"
                      name="due_date"
                      v-validate="'required'"
                      v-model="promotion.start_date"
                      :single-line="true"
                      :solo="false"
                    >
                    </hb-date-picker>
                  </v-col>
                  <v-col md="6" class="pa-0 pl-3">
                    <hb-date-picker
                      label="End Date"
                      clearable
                      data-vv-as="due date"
                      name="due_date"
                      v-model="promotion.end_date"
                      v-validate="'required'"
                      :single-line="true"
                      :solo="false"
                      :min="promotion.start_date"
                    >
                    </hb-date-picker>
                  </v-col>
                </v-row>
              </div>

              <div
                v-if="
                  promotion.active_period ==
                  PROMOTION.ACTIVE_PERIODS.end_on.value
                "
              >
                <v-row class="ma-0 mt-3">
                  <v-col md="11" class="pa-0">
                    This promotion will remain active until the date specified
                    below.
                  </v-col>
                </v-row>
                <v-row class="ma-0 mt-0 ml-0 pl-0">
                  <v-col md="6" class="pa-0">
                    <hb-date-picker
                      label="End Date"
                      clearable
                      data-vv-as="due date"
                      name="due_date"
                      v-validate="'required'"
                      v-model="promotion.end_date"
                      :single-line="true"
                      :solo="false"
                    >
                    </hb-date-picker>
                  </v-col>
                </v-row>
              </div>

              <v-row class="ma-0 pa-0">
                <v-col md="6" class="mb-0 pa-0 mt-3 ml-1">
                  <v-checkbox
                    class="ml-n1 my-0"
                    flat
                    hide-details
                    id="promotion_enabled"
                    name="promotion_enabled"
                    single-line
                    solo
                    v-model="promotion.enable"
                  >
                    <span slot="label" class="hb-text-night">
                      Enable this promotion.
                    </span>
                  </v-checkbox>
                </v-col>
              </v-row>
            </template>
            <template slot="readMode" v-if="editMode">
              <span>{{ PROMOTION.ACTIVE_PERIODS[promotion.active_period].text }}</span>
              <span v-if="promotion.active_period != 'active'">:<br /></span>
              <span v-if="promotion.active_period == 'date_range'">
                {{ promotion.start_date | formatDateServices }} - {{ promotion.end_date | formatDateServices }}
              </span>
              <span v-if="promotion.active_period == 'end_on'">
                {{ promotion.end_date | formatDateServices }}
              </span>
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row>

      <v-row class="hb-table-row ma-0 pa-0">
        <v-col md="3" class="key-label pl-6 pt-3">
          <div>Name*</div>
        </v-col>
        <v-col md="9" class="ma-0 pa-0">
          <hoverable-edit-field
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            :activeEditing="activelyEditing == 'name'"
            name="name"
            @cancel="cancelEdit"
          >
            <!-- Editable mode -->
            <template slot="editingMode">
              <v-text-field
                class="ma-0"
                type="text"
                hide-details
                v-validate="'required|max:255'"
                v-model="promotion.name"
                required
                id="name"
                name="name"
                :data-vv-as="label == 'promotion' ? 'Promo Name' : 'Discount Name'"
                :label="`${label} Name` | capitalize"
                :class="{'custom-field-error' : errors.first('name')}"
                :error-messages="errors.first('name')"
              ></v-text-field>
            </template>
            <!-- Read mode -->
            <template slot="readMode" v-if="editMode">
              {{ promotion.name }}
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row>

      <v-row class="hb-table-row ma-0 pa-0" >
        <v-col md="3" class="key-label pl-6 pt-3">
          <div>Description*</div>
        </v-col>
        <v-col md="9" class="ma-0 pa-0">
          <hoverable-edit-field
            :activeEditing="activelyEditing == 'description'"
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            name="description"
            @cancel="cancelEdit"
          >
            <template slot="editingMode">
              <v-textarea
                class="ma-0"
                hide-details
                v-validate="'required|max:1000'"
                v-model="promotion.description"
                id="description"
                name="description"
                label="Enter a description"
                :error-messages="errors.first('description')"
                rows="2"
              ></v-textarea>
            </template>
            <template slot="readMode" v-if="editMode">
              {{ promotion.description }}
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row>

      <v-row class="hb-table-row ma-0 pa-0">
        <v-col md="3" class="key-label pl-6 pt-3">
          <div>
            {{ promotionType.text | capitalize }}
            Amount*
          </div>
        </v-col>
        <v-col md="9" class="ma-0 pa-0">
          <hoverable-edit-field
            :activeEditing="activelyEditing == 'promoAmount'"
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            name="promoAmount"
            @cancel="cancelEdit"
          >
            <template slot="editingMode">
              <v-row class="ma-0">
                <v-col md="4" class="pa-0">
                  <v-select
                    :error-messages="errors.first('amount_options')"
                    :class="{'custom-field-error' : errors.first('amount_options')}"
                    hide-details
                    attach
                    :items="Object.values(PROMOTION.PROMO_TYPES)"
                    class="pa-0 mt-0"
                     :data-vv-as="label == 'promotion' ? 'Promo Type' : 'Discount Type'"
                    id="amount_options"
                    name="amount_options"
                    label="Type"
                    single-line
                    v-model="promoType"
                    v-validate="'required'"
                  ></v-select>
                </v-col>
                <v-col md="4" class="pa-0 pr-3 pl-6">
                  <v-text-field
                    :error-messages="errors.first('value')"
                    :class="{'custom-field-error' : errors.first('value')}"
                    required
                    class="pa-0 mt-0"
                    id="value"
                    name="value"
                    label="Amount"
                    hide-details
                    single-line
                     :data-vv-as="label == 'promotion' ? 'Promo Amount' : 'Discount Amount'"
                    v-model="promotion.value"
                    v-validate="`${validateAmount}`"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
            <!-- Read mode -->
            <template slot="readMode" v-if="editMode">
              {{ promotion.value }} {{ promoType }}
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row>
      <v-row class="hb-table-row ma-0 pa-0" v-if="promotionType.value === 'discount' && hasPermission('national_account_enabled')">
        <v-col md="3" class="key-label pl-6 pt-3">
          <div>National Account</div>
        </v-col>
        <v-col md="9" class="ma-0 pa-0">
          <hoverable-edit-field
            :activeEditing="activelyEditing == 'nationalaccount'"
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            name="nationalaccount"
            @cancel="cancelEdit"
          >
            <template slot="editingMode">
              <HbCheckbox
            v-model="promotion.is_national_account"
            label="Apply discount for national account only"
        />
            </template>
            <template slot="readMode" v-if="editMode">
              {{ promotion.is_national_account ? 'Yes' : 'No' }}
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row>

      <Rounding :selected="selected" @save="saveRounding" from="promotions"></Rounding>
      <v-row
        class="hb-table-row ma-0 pa-0"
        v-if="label == PROMOTION.DISCOUNT_LABELS.promotion.value"
      >
        <v-col md="3" class="key-label pl-6 pt-3">
          <div>
            {{ promotionType.text | capitalize }} Period
            <v-tooltip
              bottom
              class="ml-10"
              v-if="label == PROMOTION.DISCOUNT_LABELS.promotion.value"
            >
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <span v-on="on">
                    <hb-icon small color="#c4cdd5" class="ml-1"
                      >mdi-information</hb-icon
                    >
                  </span>
                </v-hover>
              </template>
              <span>
                <div class="py-1">
                  <span class="font-weight-medium"> Description </span><br />
                  <v-divider class="my-1"></v-divider>
                  The Promo Period starts from <br />
                  the beginning of the lease and <br />
                  will continue for its duration <br />
                  unless you set the details of <br />
                  the promotion here. <br />
                </div>
              </span>
            </v-tooltip>
          </div>
        </v-col>

        <v-col md="9" class="ma-0 pa-0">
          <hoverable-edit-field
            :activeEditing="activelyEditing == 'promoPeriod'"
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            name="promoPeriod"
            @cancel="cancelEdit"
          >
            <template slot="editingMode">
              <v-row
                v-if="
                  promoType === PROMOTION.PROMO_TYPES.fixed || promoType === ''
                "
              >
                <v-col md="12">
                  <v-checkbox
                    class="ml-n1 my-0"
                    flat
                    hide-details
                    id="consecutive_pay"
                    name="consecutive_pay"
                    single-line
                    solo
                    v-model="promotion.consecutive_pay"
                  >
                    <span slot="label" class="hb-text-night"
                      >Apply this promotion to the next consecutive month.</span
                    >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row
                class="hb-text-light"
                v-if="
                  promoType === PROMOTION.PROMO_TYPES.fixed || promoType === ''
                "
              >
                <v-col md="12">
                  Fixed rate promotion will apply to the next consecutive month.
                </v-col>
              </v-row>
              <v-row v-if="!promotion.consecutive_pay">
                <v-col md="10">
                  What month of the lease should the promotion start ?
                </v-col>
                <v-col md="2" class="pa-0">
                  <v-text-field
                    :error-messages="errors.first('offset')"
                    hide-details
                    class="pa-0 my-0"
                    id="offset"
                    name="offset"
                    v-model="adjustedOffset"
                    v-validate="'required|integer|min_value:1'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="10">Duration of Promotion (Months)</v-col>
                <v-col md="2" class="pa-0">
                  <v-text-field
                    :error-messages="errors.first('months')"
                    hide-details
                    class="pa-0 my-0"
                    id="months"
                    name="months"
                    v-model="promotion.months"
                    v-validate="'required|integer|min_value:1'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="10">Required number of Months to Pay</v-col>
                <v-col md="2" class="pa-0">
                  <v-text-field
                    :error-messages="errors.first('required_months')"
                    hide-details
                    class="pa-0 my-0"
                    data-vv-as="Number of months"
                    id="required_months"
                    name="required_months"
                    v-model="promotion.required_months"
                    v-validate.persist="'integer|min_value:1'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!promotion.consecutive_pay">
                <v-col md="10">
                  Apply Promotion to the next month after this day
                  <div class="hb-text-light mt-1">
                    *This setting is not applicable to anniversary billing
                  </div>
                </v-col>
                <v-col md="2" class="pa-0">
                  <v-text-field
                    :disabled="adjustedOffset > 1"
                    label="Day"
                    :error-messages="errors.first('days_threshold')"
                    hide-details
                    class="pa-0 my-0"
                    data-vv-as="Promotion day"
                    id="days_threshold"
                    name="days_threshold"
                    v-model="promotion.days_threshold"
                    v-validate="'integer|min_value:1|max_value:31'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
            <template slot="readMode" v-if="editMode">
              <v-row>
                <v-col md="10" class="mt-0 pb-0">
                  What month of the lease should the promotion start ?
                </v-col>
                <v-col class="mt-0 pb-0">
                  {{ adjustedOffset }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="10" class="mt-0 pb-0">
                  Duration of Promotion (Months)
                </v-col>
                <v-col class="mt-0 pb-0">
                   {{ promotion.months }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="10" class="mt-0 pb-0">
                  Required number of Months to Pay
                </v-col>
                <v-col class="mt-0 pb-0">
                  {{ promotion.required_months }}                  
                </v-col>
              </v-row>
              <v-row>
                <v-col md="10" class="mt-0 pb-0">
                  Apply Promotion to the next month after this day
                </v-col>
                <v-col class="mt-0 pb-0">
                  {{ promotion.days_threshold }}
                </v-col>
              </v-row>
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row>

      <!-- Pretax is always true (Change done in promo audit) -->
      <!-- <v-row
        class="hb-table-row ma-0 pa-0"
        v-if="label == PROMOTION.DISCOUNT_LABELS.promotion.value"
      >
        <v-col md="3" class="key-label pl-6 pt-4">
          <div>Tax</div>
        </v-col>
        <v-col md="9" class="ma-0 pa-0">
          <hoverable-edit-field
            :activeEditing="activelyEditing == 'tax'"
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            name="tax"
          >
            <template slot="editingMode">
              <v-checkbox
                :readonly="isEditPromotion"
                class="ml-n1 my-0"
                data-name="pretax"
                flat
                hide-details
                id="pretax"
                name="pretax"
                single-line
                solo
                v-model="promotion.pretax"
              >
                <span slot="label" class="hb-text-night"
                  >Apply promotion before tax is calculated.</span
                >
              </v-checkbox>
            </template>
            <template slot="readMode" v-if="editMode">
              {{ promotion.pretax == true ? "Yes" : "No" }}
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row> -->

      <v-row
        class="hb-table-row ma-0 pa-0"
        v-if="label == PROMOTION.DISCOUNT_LABELS.promotion.value"
      >
        <v-col md="3" class="key-label pl-6 pt-3">
          <div>Promo Rules</div>
        </v-col>
        <v-col md="9" class="ma-0 pa-0">
          <hoverable-edit-field
            :activeEditing="activelyEditing == 'promoRules'"
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            name="promoRules"
            saveButtonText="Save All Rules"
            @cancel="cancelEdit"
          >
            <template slot="editingMode">
              <v-row class="mt-1 ma-0 pa-0" v-if="!add_new_promotion">
                <v-col md="8" class="pl-0">
                  <span class="font-weight-medium"
                    >What does the Promotion apply to:</span
                  >
                </v-col>
                <v-col md="4" class="pl-8">
                  <a @click="add_new_promotion = true" class="hb-link">+ Add New Rule</a
                  >
                </v-col>
              </v-row>

              <promotion-rules
                v-if="promotion.promotion_rules" 
                :promotionRules="promotion.promotion_rules"
                @removeRules="removeRules"
              />

              <div v-if="add_new_promotion">
                <strong> Set Up New Rule: </strong>
                
                <!-- add new rule -->
                <edit-promotion-rule 
                  :promotionRules="promotion.promotion_rules"
                  @addPromotionRule="addPromotionRule"
                />
              </div>
            </template>
            <template slot="readMode" v-if="editMode">
              <div>
                <div
                  class="pl-0"
                  v-for="(rule, ruleIndex) in promotion.promotion_rules"
                  :key="ruleIndex"
                >
                  <v-row>
                    <v-col md="11">
                      <v-row>
                        <span class="ml-3">
                          Space {{ getRuleAttributeText(rule.attribute).text }} is:
                        </span>
                        <v-col md="8" class="ma-0 pa-0 ml-2">
                          <span v-if="rule.attribute != 'price'">
                            All {{ getRuleAttributeText(rule.attribute).plural }} Selected ({{ rule.values.length }})
                          </span>
                          <span v-else>
                            {{getRuleValueDescription(rule.values[0].value_label,rule.attribute,rule.comparison)}}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row>
      <!-- Added by BCT Team : "isOPS" -->
      <v-row class="hb-table-row ma-0 pa-0" v-if="!isOPS" >
        <v-col md="3" class="key-label pl-6 pt-3">
          <div>Enable Properties</div>
        </v-col>
        <v-col md="9" class="ma-0 pa-0">
          <hoverable-edit-field
            :activeEditing="activelyEditing == 'enableProperties'"
            :hasPermissionToEdit="hasPermission('manage_promotions')"
            name="enableProperties"
            @cancel="cancelEdit"
          >
            <template slot="editingMode">
              <v-checkbox
                class="ml-n1 my-0"
                data-name="all_properties"
                flat
                hide-details
                id="all_properties"
                name="all_properties"
                single-line
                solo
                v-model="promotion.all_properties"
                label="Select all properties"
                :indeterminate.sync="propertiesIndeterminate"
              >
              </v-checkbox>
              <div
                v-for="(property, propertyIndex) in properties"
                :key="propertyIndex"
              >
                <v-checkbox
                  class="ml-n1 my-0"
                  data-name="properties_selected"
                  flat
                  hide-details
                  id="properties_selected"
                  name="properties_selected"
                  single-line
                  solo
                  v-model="properties_selected"
                  :value="property.id"
                  :label="property.name"
                >
                </v-checkbox>
              </div>
            </template>
            <template slot="readMode" v-if="editMode">
              All Properties Selected ({{ properties_selected.length }})
            </template>
          </hoverable-edit-field>
        </v-col>
      </v-row>

      <!-- Added by BCT Team : "isOPS" -->
      <manage-coupons
        :promotionType="promotionType"
        v-if="isEditPromotion && !isOPS"
        :promotion_id="promotion.id"
      />
    </template>
    <template v-slot:actions>
      <hb-btn
        v-if="editMode"
        color="secondary"
        @click="$emit('close')"
      >
        Close
      </hb-btn>
      <hb-btn
        v-if="!editMode"
        color="primary"
        :disabled="isLoading($options.name)"
        @click="save"
      >
        Save
      </hb-btn>
      <span v-show="isLoading($options.name)">
        <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
      </span>
    </template>
  </hb-modal>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import Dropdown from "../../assets/Dropdown.vue";
import Loader from "../../assets/CircleSpinner.vue";
import ManageCoupons from "../ManageCoupons.vue";
import moment from "moment";
import Multiselect from "../../assets/MultiSelect.vue";
import Status from "../../includes/Messages.vue";
import Tabs from "../../assets/Tabs.vue";
import HbDatePicker from "../../assets/HummingbirdDatepicker";
import Rounding from "../../assets/Rounding.vue";
import HoverableEditField from "../../assets/HoverableEditField.vue";
import EditPromotionRule from "./EditPromotionRule.vue";
import PromotionRules from "./PromotionRules.vue";
import { EventBus } from '../../../EventBus.js';
import { PROMOTION } from "./Constants.js";
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
  name: "EditPromotion",
  data() {
    return {
      hoveredChip: null,
      add_new_promotion: true,
      isEditPromotion: false,
      adjustedOffset: 1,
      promoType: "",
      promotion: {
        is_national_account: false,
        enable: true,
        active_period: "active",
        start_date: null,
        active_promo: false,
        description: "",
        end_date: moment().format("YYYY-MM-DD"),
        max_users: "",
        months: 1,
        name: "",
        days_threshold: "",
        consecutive_pay: false,
        offset: 0,
        pretax: 1,
        promo_code: "",
        promotion_rules: [],
        promotion_types: [],
        rate_type: "Discount",
        required_months: "1",
        start_date: moment().format("YYYY-MM-DD"),
        type: "percent",
        value: "",
        all_properties: false,
      },
      rule_comparison: [],
      rule_attributes: [],
      promotion_types: [],
      coupon: {
        code: "",
        description: "",
        max_uses: null,
        start_date: null,
        end_date: null,
        active: true,
      },
      active_period: "",
      properties_selected: [],
      customErrors: {
        duplicateRuleCriteria:
          "This criteria or part of criteria has already been added.",
      },
      activelyEditing: '',
      subHeaderText: 'Add a new',
      roundingData: null
    };
  },
  mixins: [notificationMixin],
  components: {
    Multiselect,
    Tabs,
    Dropdown,
    Status,
    ManageCoupons,
    Loader,
    HbDatePicker,
    Rounding,
    HoverableEditField,
    EditPromotionRule,
    PromotionRules
  },
 // Added by BCT Team : "isOPS"
  props: ["selected", "label", "value","isOPS"],

  async created() {
    this.PROMOTION = PROMOTION;
    console.log(this.label);
    
    if (this.label === PROMOTION.DISCOUNT_LABELS.promotion.value) {
      await this.fetchCategories();
      await this.fetchFloors();
      await this.fetchProperties();

      if (!this.unit_sizes.length) {
        await this.fetchSizes();
      }

      if (!this.unit_categories.length) {
        await this.fetchUnitCategories();
      }
    }

    if (this.selected.id) {
      this.subHeaderText = 'View and edit your';
      this.setPromotionValues();
      this.roundingData = this.selected.rounding;
    }

    EventBus.$on('updatePromotionRule', this.updatePromotionRule);
    EventBus.$on('activeEdit', this.setActiveEditName);
    EventBus.$on('displayAddNewRuleOption', this.displayAddNewRuleOption);
    EventBus.$on('saveData', this.save);
    EventBus.$on("saveRoundingData", this.saveRounding);
  },

  destroyed() {
    this.closeMessageNotification();

    EventBus.$off('updatePromotionRule', this.updatePromotionRule);
    EventBus.$off('activeEdit', this.setActiveEditName);
    EventBus.$off('displayAddNewRuleOption', this.displayAddNewRuleOption);
    EventBus.$off('saveData', this.save);
    EventBus.$off("saveRoundingData", this.saveRounding);
  },

  computed: {
    ...mapGetters({
      unit_sizes: "filterStore/unit_size",
      unit_categories: "filterStore/unit_category",
      properties: "propertiesStore/filtered",
      categories: "settingsStore/unitMixes",
      floors: "settingsStore/unitFloors",
      hasPermission: 'authenticationStore/rolePermission'
    }),

    editMode() {
      if (this.selected.id) {
        return true;
      }
      return false;
    },

    promotionType() {
      return PROMOTION.DISCOUNT_LABELS[this.label];
    },

    validateAmount() {
      if (this.promoType === this.PROMOTION.PROMO_TYPES.dollar) {
        return "required|decimal:2|max_value:999|min_value:.01";
      } else if (this.promoType === this.PROMOTION.PROMO_TYPES.percent) {
        return "required|decimal:2|max_value:100|min_value:.01";
      } else if (this.promoType === this.PROMOTION.PROMO_TYPES.fixed) {
        return "required|decimal:2|max_value:999|min_value:0";
      } else {
        return "required";
      }
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    dialogTitle() {
      let dialogueText = this.selected.id ? "View/Edit" : "Add";
      return `${dialogueText} ${this.$options.filters.capitalize(this.label)}`;
    },

    propertiesIndeterminate: {
      get() {
        return (
          this.properties_selected.length > 0 &&
          this.properties_selected.length != this.properties.length
        );
      },
      set(value) {},
    },
  },

  methods: {
    ...mapActions({
      fetchSizes: "filterStore/getSizes",
      fetchUnitCategories: "filterStore/getCategories",
      fetchProperties: "settingsStore/fetchProperties",
      fetchFloors: "settingsStore/fetchFloors",
      fetchCategories: "settingsStore/fetchCategories"
    }),

    addPromotionRule(newRule) {
      this.promotion.promotion_rules.push(newRule);
      this.displayAddNewRuleOption(false);
    },

    setActiveEditName(name) {
      this.activelyEditing = name;
      this.add_new_promotion = false;
    },

    cancelEdit(isCancelled) {
      if (isCancelled) {
        this.setPromotionValues();
      }
    },

    updatePromotionRule(updatedRule) {
      let updatedIndex = this.promotion.promotion_rules.findIndex(pr => pr.attribute == updatedRule.attribute);   
      this.promotion.promotion_rules[updatedIndex] = updatedRule;
      this.displayAddNewRuleOption(false);
    },

    displayAddNewRuleOption(condition) {
      this.add_new_promotion = condition;
    },

    checkIfErrorFound(key) {
      return this.errors && this.errors.items.find((e) => e.key == key);
    },

    setPromotionValues() {
      this.promotion = Object.assign(
        {},
        this.promotion,
        JSON.parse(JSON.stringify(this.selected))
      );
      this.isEditPromotion = true;
      this.adjustedOffset = this.promotion.offset + 1;
      this.promoType = PROMOTION.PROMO_TYPES[this.promotion.type];
      this.promotion.promotion_rules = this.promotion.PromotionRules || [];
      this.promotion.days_threshold = !this.selected.days_threshold
        ? ""
        : this.selected.days_threshold;
      this.properties_selected =
        this.promotion.Properties.map((p) => p.id) || [];
    },

    formulatePropertiesInfo(property) {
      if (!property) return "";

      let propertyInformation = "";
      if (property.number) propertyInformation += property.number;
      if (property.Address && property.Address.city)
        propertyInformation += ` - ${property.Address.city}`;
      if (property.Address && property.Address.address)
        propertyInformation += ` - ${property.Address.address}`;

      return propertyInformation;
    },

    mouseOverChip(chipId) {
      this.hoveredChip = chipId;
    },

    mouseOutChip() {
      this.hoveredChip = null;
    },

    displayLabel(attribute, label) {
      if (attribute && attribute.length > 0) {
        return label;
      }
      return "";
    },

    removeRules(attribute) {
      this.promotion.promotion_rules = this.promotion.promotion_rules.filter(
        (pr) => pr.attribute != attribute
      );

      if (this.promotion.promotion_rules.length == 0) {
        this.add_new_promotion = true;
      }
    },

    validateValues(oldRuleValues, newRuleValues) {
      return oldRuleValues.some((oldRule) =>
        newRuleValues.some((newRule) => newRule.value === oldRule.value)
      );
    },

    validate(newRule) {
      const newRuleExisted = this.promotion.promotion_rules.some(
        (pr) =>
          pr.comparison === newRule.comparison &&
          pr.object === newRule.object &&
          pr.attribute === newRule.attribute &&
          this.validateValues(pr.values, newRule.values)
      );

      return !newRuleExisted;
    },

    // eg: price > 20 is converted to Greater than 20
    getRuleValueDescription(value, attribute, comparisonOperator) {
      if (attribute.toLowerCase() == "price") {
        let comparisonOperatorText = "";

        if(isNaN(value)) {
          return value;
        } else {
          value = this.$options.filters.formatMoney(value);
        }

        const equalityOperators = Object.keys(
          this.PROMOTION.OPERATORS.EQUALITY
        );

        for (let i = 0; i < equalityOperators.length; i++) {
          if (
            this.PROMOTION.OPERATORS.EQUALITY[
              equalityOperators[i]
            ].value.toLowerCase() == comparisonOperator
          ) {
            comparisonOperatorText = this.PROMOTION.OPERATORS.EQUALITY[
              equalityOperators[i]
            ].text;
            break;
          }
        }

        return `${comparisonOperatorText} ${value}`;
      } else {
        return value;
      }
    },

    getRuleAttributeText(attribute) {
      return this.PROMOTION.RULE_ATTRIBUTES.unit.find(
        (a) => a.value.toLowerCase() === attribute
      );
    },

    mapComparisonOperatorToPhrase(comparison_operator) {
      switch (comparison_operator) {
        case "=":
          return "is";
        case "!=":
          return "is not";
        default:
          return "is"; // comparison_operator.value can be >, < or =, in case of price currently
      }
    },

    async fetchPromotionTypes() {
      let r = await api.get(this, api.PROMOTIONS + "types");
      this.promotion_types = r.promotion_types;
    },

    formatPromotionData() {
      this.promotion.offset = this.adjustedOffset - 1 || 0;

      const {
        name,
        description,
        value,
        active_period,
        start_date,
        end_date,
        enable,
        days_threshold,
        consecutive_pay,
        pretax,
        required_months,
        is_national_account,
      } = this.promotion;

      let data = {
        label: this.label,
        name: name,
        description: description,
        value: value,
        is_national_account : is_national_account,
        months: this.label == "discount" ? null : this.promotion.months,
        offset: this.adjustedOffset - 1 || 0,
        pretax: pretax ? 1 : 0,
        required_months: required_months || 1,
        days_threshold: days_threshold,
        consecutive_pay: !!consecutive_pay,
        enable: enable,
        active_period: active_period,
        start_date: this.label == "discount" ? null : start_date,
        end_date: this.label == "discount" ? null : end_date,
        rounding: this.roundingData,
        Properties: this.properties_selected.map((property_id) => {
          return {
            id: property_id,
          };
        }),
        PromotionRules: this.promotion.promotion_rules.map((pr) => {
          return {
            id: pr.id,
            object: pr.object,
            attribute: pr.attribute,
            comparison: pr.comparison,
            values: Array.isArray(pr.values) ? 
              pr.values.map((v) => {
                return v.value;
              }) : [pr.values],
          };
        }),
      };

      // Map the value to key - 'dollar' to '$' etc
      data.type = Object.keys(this.PROMOTION.PROMO_TYPES).find(
        (promoType) => this.PROMOTION.PROMO_TYPES[promoType] === this.promoType
      );

      return data;
    },

    checkDateRange(promotion) {
      if(promotion.end_date < promotion.start_date)
      {
        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: 'Start Date cannot be greater than End Date' }] });
        return false;
      }
      return true;
    },
    saveRounding(rounding) {
      this.roundingData = rounding;
    },
    async save() {
      const validationStatus = await this.$validator.validateAll();

      if (validationStatus) {
        const data = this.formatPromotionData();
        if(!this.checkDateRange(data) && data.active_period == PROMOTION.ACTIVE_PERIODS.date_range.value) return;
        try {
          let response = "";
          if (this.selected.id) {
            response = await api.put(
              this,
              api.PROMOTIONS + this.selected.id,
              data
            );
            this.setActiveEditName('');
          } else {
            response = await api.post(this, api.PROMOTIONS, data);
          }

          this.$emit("refetch");
          //this.$emit("showSuccess", data.name, this.selected.id);
          if(!this.selected.id) this.$emit("close");
          let currentLocalDate = this.$options.filters.formatDateTimeCustom(
            new Date(),
            "MMM DD, YYYY @ h:mma."
          );
          let actionText = this.selected.id ? "updated" : "created";
           let type = this.label == 'promotion' ? 'Promotion ' : 'Discount ';
          let payload = {
            id: this.$options.name,
            type: 'success',
            description: `The ${type} '${data.name}' has been ${actionText} on ${currentLocalDate}`,
          };
          this.showMessageNotification(payload);
        } catch (err) {
          this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }] });
        }
      }
    },
  },

  watch: {
    promoType(val) {
      if (val != PROMOTION.PROMO_TYPES.fixed) {
        this.promotion.consecutive_pay = false;
      }
    },

    adjustedOffset(val) {
      this.promotion.days_threshold = (val > 1) ? 1 : "";
    },
    "promotion.consecutive_pay"(val) {
      if (val == 0) return;

      this.promotion.offset = 1;
      this.promotion.days_threshold = "";
      this.adjustedOffset = 1;
    },

    async "promotion.all_properties"(val) {
      if (val) {
        this.properties_selected = this.properties.map((p) => p.id);
      } else {
        this.properties_selected = [];
      }
    },

    "properties_selected"(val) {
      if(this.properties.length == this.properties_selected.length){
        this.promotion.all_properties = true;
      } else if(!this.properties_selected.length){
        this.promotion.all_properties = false;
      }
    },

    "promotion.end_date"(val) {
      if (val) {
        if (this.promotion.end_date < this.promotion.start_date) {
          this.showMessageNotification({ id: this.$options.name, type: "error", description: 'End Date should be greater then Start Date' });
        }
      }
    }
  },
};
</script>

<style scoped>
.beginning-lease-text {
  font-weight: normal;
}

.param {
  background-color: white;
  border-radius: 10px;
  font-weight: 100;
}

.mrl--25px {
  margin-left: -25px;
  margin-right: -24px;
}
.key-label {
  background: #f4f6f8;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101318;
}
.top-border {
  border-top: 1px solid #dfe3e8;
}
.no-top-border {
  border-top: 0;
}
.border-bottom {
  border-bottom: 1px solid #dfe3e8;
}
.overflow-text {
  overflow-wrap: anywhere;
}
.scrollable-div {
  max-height: 350px;
  overflow: auto;
}
.promotion-icon {
  position: relative;
  top: 5px;
}
.no-border-section {
  border: 0 !important;
}
.edit-able-row:hover {
  background: #f4f6f8;
}
.icon {
  cursor: pointer;
}
</style>

<style>
.promotion-field.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  padding-left: 0 !important;
}
.rule-selector i {
  display: none !important;
}
.custom-field-error {
  border-bottom: 2px solid #fb4c4c !important;
}
</style>
