<template>
  <div>
    <v-card class="mx-auto">
      <v-container class="mb-6">
        <v-row no-gutters>
          <v-col v-if="onboardingData.company.length && onboardingData.propertyData.length">
            <div>
              <div class="go-live-title text--primary ml-2">
                Your Go Live Date: <span>  {{ onboardingData.propertyData[0].golive_date | formatDate }} </span>
              </div>
              <div class="info-container mt-2 ml-2" v-if="onboardingData.company[0].tech_contact_name">
                <b>Technical Lead: </b>{{ onboardingData.company[0].tech_contact_name}}|
                {{ onboardingData.company[0].tech_contact_phone | formatPhone }} |
                {{ onboardingData.company[0].tech_contact_email }}
              </div>
              <div class="get-started-btn-container" v-if="hideStartBtn">
                <hb-btn class="get-started-btn" style="color:#ffff;" @click="updateBtnStatus()">
                    Get Started
                </hb-btn>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="">
              <div class="container-note">
                <b>Note:</b> We will conduct QA testing in your live HB
                environment, including testing with real credit cards, to be
                sure everything is working as expected. You can either provide a
                credit card for this testing (and void / refund )
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
export default {
  computed: {
    ...mapGetters({
      propertyTabs: "onboardingTabsStore/getPropertyTabs",
      tabs: "onboardingTabsStore/getOnboardingTabs",
      onboardingData: "onboardingTabsStore/getOnboardingData",
      companyStatus: "onboardingTabsStore/getCompanyStatus",
      menus: "onboardingTabsStore/getMenus"
    }),
    hideStartBtn() {
      if(this.companyStatus == 'launched' && this.menus.corporate.status == 'locked'
      && this.menus.property.status == 'locked') {
        return false;
      } else {
        return true;
      }
    }
  },
  filters: {
    formatDate(value) {
      if (!value) return null;
      return moment(value).format("MMMM Do YYYY");
    }
  },
  methods: {
    updateBtnStatus() {
      if(this.companyStatus == 'launched' && this.menus.corporate.status == 'locked') {
        this.$router.push('/onboarding/property');
        this.$store.dispatch(
          "onboardingTabsStore/setActiveTab",
          this.tabs["property"]
        );
      } else {
        this.$router.push('/onboarding/corporate');
        this.$store.dispatch(
        "onboardingTabsStore/setActiveTab",
        this.tabs["corporate"]
      );
      }
    }
  }
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.go-live-title {
  font-size: 18px;
  color: black;
}
.info-container {
  font-size: 14px;
}
.container-note {
  font-size: 12px;
  color: #637381;
}
.get-started-btn {
  background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  color: white;
}
.setup-msg {
  font-size: 14px;
  font-weight: bold;
}
.company-name {
  margin-top: 15px;
  font-size: 20px;
  font-weight: bold;
}
.company-address {
  font-size: 14px;
  margin-top: 15px;
}
.get-started-btn-container {
  margin: 15px 10px;
}
</style>