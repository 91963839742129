export const PROMOTION = Object.freeze({
  ACTIVE_PERIODS: {
    active: { text: "Active", value: "active" },
    date_range: { text: "Date Range", value: "date_range" },
    end_on: { text: "Ends On", value: "end_on" },
  },

  // added by BCT : this header fields should be visible in HB but not in OPS
  HEADERS: [
    { text: "Name", value: "name", isOPS : true},
    { text: "Amount", value: "amount" , isOPS : true},
    { text: "# of Properties", value: "Properties" , isOPS : false},
    { text: "Rules", value: "PromotionRules", visible: 'promotion' ,isOPS : false},
    { text: "Enabled", value: "enable", align: 'center', isOPS : false },
    { text: "National Account", value: "is_national_account", align: 'center',visible: 'discount', isOPS : false },
    { text: "", value: "actions", align: "right", sortable: false, width: 10,isOPS : true },
   
  ],
  RULE_OBJECTS: ["property", "unit"],
  PROMO_TYPES: {
    dollar: "$",
    percent: "%",
    fixed: "Fixed Rate",
  },
  DISCOUNT_LABELS: {
    promotion: { value: "promotion", text: "Promo" },
    discount: { value: "discount", text: "Discount" },
  },
  OPERATORS: {
    EQUALITY: {
      greater: {
        text: 'Greater than',
        value: '>'
      },
      less: {
        text: 'Less than',
        value: '<'
      },
      equal: {
        text: 'Equal To',
        value: '='
      }
    }
  },
  RULE_ATTRIBUTES: {
    property: ["Address", "Neighborhood", "Zip"],
    unit: [
      {
        text: 'Size',
        value: 'size',
        plural: 'Sizes'
      },
      {
        text: 'Category',
        value: 'unit_category',
        plural: 'Categories'
      },
      {
        text: 'Mix',
        value: 'category',
        plural: 'Mixes'
      },
      {
        text: 'Floor',
        value: 'floor',
        plural: 'Floors'
      },
      {
        text: 'Price',
        value: 'price',
        plural: 'Prices'
      },
    ],
  },
  PERMISSIONS: {
    MANAGE: 'manage_promotions',
    ENABLE: 'enable_promotions'
  }
});
