<template>
  <div>
    <button
      class="confirm-btn"
      :disabled="activeTabData.status == 'complete'"
      @click="onClickConfirm"
    >
      <span class="checkbox clickable-cursor">
        <input
          type="checkbox"
          class="w-checkbox-input clickable-cursor"
          v-model="confirmChecked"
          :disabled="confirmChecked"
        />
        <label class="hb-text-night clickable-cursor">
          {{ activeTabData.footerConfirmLabel }}
        </label>
      </span>
    </button>

    <!-- confirm checkbox confiration modal  -->
    <hb-modal
      v-model="confirmSettingModal"
      v-if="confirmSettingModal"
      size="medium"
      title="Confirm Accounting Settings"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="pt-6 pb-2 px-6">
          Please note that accurate Accounting Settings are critical to your
          success in Hummingbird. These inputs will determine any transactions
          made are captured in the correct accounts and reflected in the
          corresponding reports.<br/>Are you sure all settings, GL codes and,
          assignments within the Journal Template are accurate?<br/> Confirming these
          settings will confirm these settings on the database
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn @click="confirmedSetting" color="primary">Confirm</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../../../EventBus";
import moment from "moment";
import api from "../../../../assets/api";
import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
export default {
  name: "ConfirmCheckbox",
  mixins: [notificationMixin],
  data() {
    return {
      confirmChecked: false,
      canConfirm: false,
      errorMessage: "",
      confirmSettingModal: false,
      cocoonUrl: "",
      adminPortalUrl: ""
    };
  },
  computed: {
    ...mapGetters({
      activeTabData: "onboardingTabsStore/getActiveTab",
      onboardingPercentage: "onboardingTabsStore/getProgressPercentage",
      onboardingData: "onboardingTabsStore/getOnboardingData",
      companyName: "authenticationStore/getCompanyName"
    })
  },
  watch: {
    activeTabData() {
      this.getConfirmCheckboxStatus();
    }
  },
  mounted() {
    this.getConfirmCheckboxStatus();
  },
  methods: {
    onClickConfirm() {
      this.canConfirm = false;
      // check event when feature grid data mandatory
      if (this.activeTabData.validateData) {
        // Initialilze
        this.errorMessage =
          "Setup your " +
          this.activeTabData.title +
          " in order to confirm the setting.";
        // Emit event to Get data length from child component
        EventBus.$emit(this.activeTabData.validateData);
        this.confirmSettingEventDispatch();
      } else {
        this.confirmSetting();
      }
    },
    getConfirmCheckboxStatus() {
      this.confirmChecked =
        this.activeTabData.status == "complete" ? true : false;
    },
    // check to accounting setting for open confirmation modal
    confirmSetting() {
      if (this.activeTabData.tabName == "AccountingSettings") {
        this.confirmSettingModal = true;
      } else {
        this.confirmChecked = !this.confirmChecked;
        this.$store
          .dispatch("onboardingTabsStore/updateOnboardingTabStatus", {
            type: this.$route.name
          })
          .then(res => {
            this.sendMail();
            this.sendMailOnSomeConfirm();
          });
        this.showMessageNotification({ type: "success", description: "The " + this.activeTabData.title + " has been approved on " + moment().format("MMM DD, YYYY @ h:mma.") });
      }
    },
    sendMail() {
      if (this.onboardingPercentage > 90) {
        let hostNameurl;
        var hostName = window.location.hostname;
        var nameSplit = hostName.split(".");
        nameSplit.shift();
        var name = nameSplit.join(".");
        hostNameurl = name;
        this.adminPortalUrl = "admin." + hostNameurl.split(".")[0] + ".com";
        this.cocoonUrl =
          this.onboardingData.company[0].subdomain +
          "." +
          hostNameurl +
          "/onboarding";
        let address = this.onboardingData.property.Address.address !== null
            ? this.onboardingData.property.Address.address+', '
            : "";
          
         address += this.onboardingData.property.Address.address2 !== null
          ? this.onboardingData.property.Address.address2+','
          : "";
        address +=this.onboardingData.property.Address.city !== null
          ? this.onboardingData.property.Address.city+', '
          : "";
        address += this.onboardingData.property.Address.state !== null
          ? this.onboardingData.property.Address.state+', '
          : "";
        address += this.onboardingData.property.Address.zip !== null
          ? this.onboardingData.property.Address.zip
          : "";
        var payload = {
          subject:
            this.onboardingData.property.name +
            "- waiting for migration approval",
          contact_email: this.onboardingData.company[0].tech_contact_email,
          email_type: "ri_settings_complete",
          ri_name: this.onboardingData.company[0].tech_contact_name,
          property_number: this.onboardingData.property.number,
          property_name: this.onboardingData.property.name,
          property_address: address,
          owner_name:
            this.onboardingData.company[0].firstname +
            " " +
            this.onboardingData.company[0].lastname,
          golive_date: this.onboardingData.propertyData[0].golive_date,
          cocoon_url: this.cocoonUrl,
          admin_portal_url: this.adminPortalUrl,
          merge_status :this.onboardingData.propertyData[0].merge_status
        };
        try {
          api.post(this, api.ONBOARDING_EMAIL, payload);
        } catch(err) {}
      }
    },
    sendMailOnSomeConfirm() {
      if (
        this.activeTabData.tabName == "DelinquencyProcess" ||
        this.activeTabData.tabName == "DocumentLibrary" ||
        this.activeTabData.tabName == "LeaseConfigurationLibrary"
      ) {
        let hostNameurl;
        var hostName = window.location.hostname;
        var nameSplit = hostName.split(".");
        nameSplit.shift();
        var name = nameSplit.join(".");
        hostNameurl = name;
        var stage_name = this.activeTabData.tabName
          .replace(/([A-Z])/g, " $1")
          .trim();
        this.cocoonUrl =
          this.onboardingData.company[0].subdomain +
          "." +
          hostNameurl +
          "/onboarding";
        var email_type = "";
        if (this.activeTabData.tabName == "DelinquencyProcess") {
          email_type = "ri_delinquency_confirmation";
        } else if (this.activeTabData.tabName == "DocumentLibrary") {
          email_type = "ri_document_library_confirmation";
        } else if (this.activeTabData.tabName == "LeaseConfigurationLibrary") {
          email_type = "ri_lease_configuration_confirmation";
        }
        var payload = {
          subject:
            stage_name +
            " for " +
            this.onboardingData.property.name +
            " Completed",
          email_type: email_type,
          ri_name: this.onboardingData.company[0].tech_contact_name,
          property_name: this.onboardingData.property.name,
          owner_name:
            this.onboardingData.company[0].firstname +
            " " +
            this.onboardingData.company[0].lastname,
          owner_email: this.onboardingData.company[0].email,
          cocoon_url: this.cocoonUrl,
          company_name: this.companyName,
          stage_name: stage_name
        };
        api.post(this, api.ONBOARDING_EMAIL, payload);
      }
    },
    confirmSettingEventDispatch() {
      EventBus.$on(this.activeTabData.sendData, event => {
        this.canConfirm = event.canConfirm;
        this.errorMessage = event.message ? event.message : this.errorMessage;
        if (this.canConfirm) {
          this.confirmSetting();
        } else {
          this.showMessageNotification({ description: this.errorMessage });
        }
        EventBus.$off(this.activeTabData.sendData);
      });
    },

    // confirmed the confiramation modal
    confirmedSetting() {
      this.confirmChecked = !this.confirmChecked;
      this.$store.dispatch("onboardingTabsStore/updateOnboardingTabStatus", {
        type: this.$route.name
      });
      this.showMessageNotification({ type: "success", description: "The " + this.activeTabData.title + " has been approved on " + moment().format("MMM DD, YYYY @ h:mma.") });
      this.confirmSettingModal = false;
    }
  },
  destroyed() {
    EventBus.$off(this.activeTabData.validateData);
  }
};
</script>

<style lang="scss" scoped>
.confirm-btn {
  .clickable-cursor {
    cursor: pointer;
  }

  .hb-primary-button {
    background: #e1fae3 !important;
    border: none;
    margin-top: -10px;
  }

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
  }
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #e1fae3 !important;
  }
}
</style>
