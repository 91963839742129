var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"fill-height mt-65 navigation transform-drawer ml-60",attrs:{"width":"200","flat":"","value":_vm.drawer,"disable-resize-watcher":"","clipped":"","fixed":"","elevation-0":"","mobile-breakpoint":"415","id":"main-navigation-drawer"}},[_c('v-list',{staticClass:"nav-drawer-list mt-2",attrs:{"dense":""}},[_vm._l((_vm.menus),function(item,a){return [_c('router-link',{key:a,class:[item.status == 'locked' ? 'disableClass' : 'enableClass'],attrs:{"to":item.to,"event":item.status == 'locked' ? '' : 'click'}},[(Object.keys(item.items).length === 0)?_c('v-list-item',_vm._g({key:a,staticClass:"py-1 px-4",class:[item.status == 'locked' ? 'disableClass' : 'enableClass'],attrs:{"to":item.to},on:{"click":function($event){return _vm.activeLinkTab(item)}}},item.status == 'locked' ? { click: _vm.openModal } : {}),[_c('v-list-item-action',{staticClass:"ma-0 mr-2"},[_c('hb-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"hb-default-font-size",attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))])])],1),_c('v-list-item-action',{staticClass:"ml-0 mr-1"},[(item.status == 'locked')?_c('hb-icon',{staticStyle:{"margin-bottom":"5px"},attrs:{"small":true,"color":'#637381'}},[_vm._v("mdi-lock")]):_vm._e()],1)],1):_c('v-list-group',{key:a,class:[
          item.tabName === _vm.tab.tabName ? 'activeClass' : 'notActive'
        ],attrs:{"append-icon":"mdi-menu-down","disabled":_vm.disableCorporate,"id":a + 'navAccordion',"value":_vm.panel,"dense":""},on:{"click":function($event){return _vm.activeLinkTab(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('router-link',{attrs:{"to":item.to}},[_c('v-list-item',{staticClass:"py-1 px-0"},[_c('v-list-item-action',{staticClass:"ma-0 mr-2"},[_c('hb-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"hb-default-font-size",attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))])])],1),(item.status == 'locked')?_c('v-list-item-action',{staticClass:"ml-4"},[(item.status == 'locked')?_c('hb-icon',{staticStyle:{"margin-bottom":"5px","margin-left":"26px"},attrs:{"small":true,"color":'#637381'}},[_vm._v("mdi-lock")]):_vm._e()],1):_vm._e()],1)],1)]},proxy:true}],null,true)},[(item.status !== 'locked')?_c('router-link',{attrs:{"to":item.to}},_vm._l((item.items),function(itemChild,b){return _c('v-list-item',{key:b,staticClass:"list-item-child",class:[
              itemChild.tabName === _vm.tab.tabName ? 'activeClass' : 'notActive'
            ],on:{"click":function($event){return _vm.openModal(itemChild)}}},[_c('v-list-item-action',{staticClass:"ma-0 ml-1 mr-1",class:[
                itemChild.status == 'locked' ? 'disableClass' : 'enableClass'
              ],on:{"click":function($event){return _vm.activeLinkTab(itemChild)}}},[(itemChild.status == 'complete')?_c('hb-icon',{attrs:{"small":true,"color":"#02AD0F"}},[_vm._v("mdi-check-circle-outline")]):_vm._e(),(itemChild.status == 'locked')?_c('hb-icon',{staticStyle:{"margin-bottom":"5px"},attrs:{"color":'#637381',"small":true}},[_vm._v("mdi-lock")]):_vm._e()],1),_c('v-list-item-content',{class:[
                itemChild.status == 'locked' ? 'disableClass' : 'enableClass'
              ],attrs:{"Vertical-align":"center"},on:{"click":function($event){return _vm.activeLinkTab(itemChild)}}},[_c('v-list-item-title',[_c('span',{staticClass:"hb-default-font-size",attrs:{"title":itemChild.title}},[_vm._v(_vm._s(itemChild.title))])])],1)],1)}),1):_vm._e()],1)],1)]})],2),(_vm.dialog)?_c('dependency-modal',{attrs:{"dependencyList":_vm.dependencyList,"title":_vm.title},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }