<template>

    <v-row class="hb-table-row ma-0 pa-0">
        <v-col md="3" class="key-label pl-6 pt-3">
            <div>{{ promotionType.text || 'Promo' }} Code</div>
        </v-col>
        <v-col md="9" class="pa-0">
            <hoverable-edit-field
                :activeEditing="activelyEditing == 'couponCode'"
                :hasPermissionToEdit="hasPermission('manage_promotions')"
                name="couponCode"
                :displayActionButtons="((coupons && coupons.length) || showAddCouponForm) ? true : false"
            >
                <template slot="editingMode">
                    <v-row class="ma-0 pr-0 pt-1" v-if="!showAddCouponForm">
                        <v-col md="12" class="ml-0 pl-0" :class="coupons.length ? 'align-right' : ''" >
                            <hb-link color="primary" @click="showAddCouponForm = true" small>Click to Add</hb-link>
                        </v-col>
                    </v-row>
                    <v-col class="py-0 pl-0 pr-0" :class="{'pt-4' : showAddCouponForm}">
                        <div class="form-section pa-0" v-if="coupons.length > 0">
                            <div class="content-view mt-0 pa-0">
                                <v-data-table
                                    :headers="headers"
                                    :items="coupons"
                                    :items-per-page="5"
                                    class="hb-data-table"
                                    disable-pagination
                                    hide-default-footer
                                >   
                                    <template v-slot:item.start_date="{ item }">
                                        {{item.start_date | formatDateTimeCustom('MMM DD, YYYY')}}
                                    </template>
                                    <template v-slot:item.end_date="{ item }">
                                        {{item.end_date | formatDateTimeCustom('MMM DD, YYYY')}}
                                    </template>
                                    <template v-slot:item.active="{ item }">
                                        <span v-if="item.active">
                                            <hb-icon class="pr-2" color="#101318">mdi-table-actions-enable</hb-icon>
                                        </span>
                                        <span v-else>
                                            <hb-icon class="pr-2" color="#101318">mdi-table-actions-disable</hb-icon>
                                        </span>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <hb-menu
                                            options
                                            align-right
                                        >
                                            <v-list>
                                                <v-list-item @click="editCoupon(item)">
                                                    <v-list-item-title>Edit</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="toggleCoupon(item, 0)" v-show="item.active">
                                                    <v-list-item-title>Disable</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="toggleCoupon(item, 1)" v-show="!item.active">
                                                    <v-list-item-title>Enable</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </hb-menu>
                                        <!--
                                        <hb-btn color="secondary" class="mr-2" @click="editCoupon(item)" small>Edit</hb-btn>
                                        <hb-btn color="destructive" @click="toggleCoupon(item, 0)" v-show="item.active" small>Disable</hb-btn>
                                        <hb-btn color="primary" @click="toggleCoupon(item, 1)" v-show="!item.active" small>Enable</hb-btn>
                                        -->
                                        <!-- <button @click="toggleCoupon(item, 0)" v-show="item.active" class="tertiary-btn trash w-button btn-sm">Disable</button>
                                        <button  @click="toggleCoupon(item, 1)" v-show="!item.active" class="primary-btn w-button btn-sm">Enable</button> -->
                                        <!-- <v-icon
                                            small
                                            class="mr-2"
                                            @click="editItem(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            small
                                            @click="deleteItem(item)"
                                        >
                                            mdi-delete
                                        </v-icon> -->
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                    </v-col>
                    <v-row class="ma-0" v-if="showAddCouponForm">
                        <v-col md="9" class="pa-1 pl-0">
                            <!-- <v-checkbox
                                solo
                                flat
                                id="pretax"
                                name="pretax"
                                data-name="pretax"
                                v-model="coupon.active"
                                label="Add Promotion Code"
                                hide-details
                                class="ml-n1 mt-2 mb-4"
                            ></v-checkbox> -->

                            <v-text-field
                                counter="20"
                                :label="coupon.code ? 'Name':''"
                                placeholder="Enter a promo code name"
                                v-model="coupon.code"
                                v-validate="'required|alpha_num'"
                                data-vv-as="coupon code"
                                name="promotion_code"
                                id="promotion_code"
                                :rules="[
                                () => !!coupon.code && coupon.code.length <= 20 || 'Max 20 characters'
                                ]"
                                :error-messages="errors.first('promotion_code')"
                                :hide-details="errors.first('promotion_code') == 'The coupon code field is required' || coupon.code.length <= 20"
                                class="pt-0"
                                :class="{'pt-4': coupon.code}">
                            </v-text-field>
                            <v-text-field
                            hide-details
                            dense
                            :label="coupon.max_uses ? 'Uses':''"
                            placeholder="How many times can this promo code be used?"
                            v-model="coupon.max_uses"
                            v-validate="'required|numeric'"
                            data-vv-as="max uses"
                            name="coupon_max_uses"
                            id="coupon_max_uses"
                            :error-messages="errors.first('coupon_max_uses')"
                            class="pt-0"
                            :class="{'pt-4': coupon.max_uses}">
                            </v-text-field>
        <!--                    <v-select-->
        <!--                        :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"-->
        <!--                        hide-details-->
        <!--                        v-model="coupon.max_uses"-->
        <!--                        name="coupon_max_uses"-->
        <!--                        id="coupon_max_uses"-->
        <!--                        label="Max Users"-->
        <!--                        single-line-->
        <!--                        class="col-5 px-0"-->
        <!--                    ></v-select>-->

                            <v-row class="ma-0 pb-5">
                                <v-col class="pl-0">
                                    <HbDatePicker
                                    v-model="coupon.start_date"
                                    :default_today="true"
                                    :label="'Start Date'"
                                    :adjust-label="true"
                                    :solo="false"
                                    >
                                    </HbDatePicker>
                                </v-col>
                                <v-col>
                                    <HbDatePicker
                                    v-model="coupon.end_date"
                                    :default_today="true"
                                    :label="'End Date'"
                                    :adjust-label="true"
                                    :solo="false"
                                    >
                                    </HbDatePicker>
                                </v-col>
                            </v-row>

                            <v-row class="ma-0 pb-5">
                                <hb-btn
                                    small
                                    color="primary"
                                    :disabled="isLoading($options.name)"
                                    @click="saveCoupon">
                                    Add
                                </hb-btn>
                                <hb-link class="pl-5 pt-1" color="primary" @click="showAddCouponForm = false" small>Cancel</hb-link>
                                <span v-show="isLoading($options.name)">
                                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                                </span>
                            </v-row>

                            <!-- <v-row class="ma-0 pb-5">
                                <v-col md="6" class="py-0 pl-0">
                                    <v-menu
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                id="promo_start_date"
                                                name="promo_start_date"
                                                data-vv-as="start date"
                                                hide-details
                                                v-model="coupon.start_date"
                                                label="Start Date"
                                                prepend-icon="mdi-calendar-blank"
                                                v-on="on"
                                                :class="{'has-error': errors.has('promo_start_date') }"
                                            >
                                                <template v-slot:message="{ item }">
                                                    {{ item }} asdf
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="coupon.start_date" :show-current="false" no-title scrollable></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col md="6" class="pa-0">
                                    <v-menu
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                id="promo_end_date"
                                                name="promo_end_date"
                                                data-vv-as="end date"
                                                required
                                                hide-details
                                                v-model="coupon.end_date"
                                                label="End Date"
                                                prepend-icon="mdi-calendar-blank"
                                                v-on="on"
                                                :class="{'has-error': errors.has('promo_end_date') }"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="coupon.end_date" :show-current="false" no-title scrollable></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row> -->
                        </v-col>

                        <!-- <v-col class="text-right d-flex align-end justify-end pb-8 pr-5">
                            <span v-show="!isLoading($options.name)">
                                <a class="" @click="editPromoCode = false">Cancel</a>
                                <a class="ml-2" @click.prevent="saveCoupon">Save</a>
                            </span>
                            <span v-show="isLoading($options.name)">
                                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                            </span>
                        </v-col> -->
                    </v-row>
                </template>
                <template slot="readMode" v-if="editMode">
                  <div>
                    <div
                      class="mt-1"
                      v-for="(coupon, couponIndex) in coupons"
                      :key="coupon.id"
                    >
                      <v-row>
                        <v-col class="ma-0 pb-0 pt-0" md="12">Name: {{ coupon.code }}</v-col>
                        <v-col class="ma-0 pb-0 pt-0" md="12">Uses: {{ coupon.max_uses }}</v-col>
                        <v-col class="ma-0 pb-0 pt-0" md="12">{{ coupon.start_date | formatDateServices }} - 
                            {{ coupon.end_date | formatDateServices }}</v-col>
                      </v-row>
                      <v-divider class="mt-2 mb-2" v-if="couponIndex != coupons.length - 1"></v-divider>
                    </div>
                    <span v-if="!showAddCouponForm && coupons && !coupons.length">
                      No Codes found!
                    </span>
                  </div>
                </template>
            </hoverable-edit-field>
        </v-col>

    </v-row>
    <!-- <div>

        <div class="form-section pa-0 mb-0 no-border-section">
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

                <v-row class="top-border mrl--25px  no-top-border">
                    <v-col md="4" class="key-label pl-6 pt-4">
                        <div class="font-15px">Coupon Code</div>
                    </v-col>
                    <v-col md="8" class="pa-1 pl-6 pr-6">
                        <div class="form-input-container">
                            <v-text-field
                                :disabled="!!coupon.id"
                                solo
                                flat
                                hide-details
                                placeholder="Enter Name"
                                single-line
                                v-model="coupon.code"
                                v-validate="'required|alpha_num'"
                                data-vv-as="coupon code"
                                :class="{'has-error': errors.has('coupon_code') }"
                                name="coupon_code"
                                id="coupon_code"
                                class="pa-0 mt-0 promotion-field"
                            > </v-text-field>
                            <span v-show="errors.has('coupon_code')" class="status-block error-block field-error">{{ errors.first('coupon_code') }}</span>
                        </div>
                    </v-col>
                </v-row>

                <v-row class="top-border mrl--25px">
                    <v-col md="4" class="key-label pl-6 pt-4">
                        <div class="font-15px">Description</div>
                    </v-col>
                    <v-col md="8" class="pa-1 pl-6 pr-6">
                        <v-textarea
                            solo
                            flat
                            hide-details
                            placeholder="Enter Description"
                            rows="2"
                            single-line
                            v-validate="'max:1000'"
                            v-model="coupon.description"
                            :class="{'has-error': errors.has('coupon_description') }"
                            name="coupon_description"
                            id="coupon_description"
                            class="pa-0 mt-0 pb-1 promotion-field"
                        > </v-textarea>
                        <span v-show="errors.has('coupon_description')" class="status-block error-block field-error">{{ errors.first('coupon_description') }}</span>
                    </v-col>
                </v-row>

                <v-row class="top-border mrl--25px">
                    <v-col md="4" class="key-label pl-6 pt-4">
                        <div class="font-15px">Max Uses</div>
                    </v-col>
                    <v-col md="8" class="pa-1 pl-6 pr-6">
                        <v-text-field
                            solo
                            flat
                            hide-details
                            placeholder="---"
                            single-line
                            v-model="coupon.max_uses"
                            v-validate="'numeric'"
                            :class="{'has-error': errors.has('coupon_max_uses') }"
                            name="coupon_max_uses"
                            id="coupon_max_uses"
                            class="pa-0 mt-0">
                        </v-text-field>
                        <span v-show="errors.has('coupon_max_uses')" class="status-block error-block field-error">{{ errors.first('coupon_max_uses') }}</span>
                    </v-col>
                </v-row>

                <v-row class="top-border mrl--25px">
                    <v-col md="4" class="key-label pl-6 pt-5">
                        <div class=" font-15px">Start Date</div>
                    </v-col>
                    <v-col md="8" class="pa-3 pl-6 pr-6 pb-5">
                        <hb-date-picker
                            @click:clear="coupon.start_date = ''"
                            label="Select a Start Date"
                            :clearable="true"
                            :dense="true"
                            data-vv-as="start date"
                            v-model="coupon.start_date">
                        </hb-date-picker>
                        <span v-show="errors.has('coupon_start_date')" class="status-block error-block field-error">{{ errors.first('coupon_start_date') }}</span>
                    </v-col>
                </v-row>

                <v-row class="top-border mrl--25px border-bottom">
                    <v-col md="4" class="key-label pl-6 pt-5">
                        <div class=" font-15px">End Date</div>
                    </v-col>
                    <v-col md="8" class="pa-3 pl-6 pr-6 pb-5">
                        <hb-date-picker
                            @click:clear="coupon.end_date = ''"
                            label="Select an End Date"
                            :clearable="true"
                            :dense="true"
                            data-vv-as="start date"
                            v-model="coupon.end_date">
                        </hb-date-picker>
                        <span v-show="errors.has('coupon_end_date')" class="status-block error-block field-error">{{ errors.first('coupon_end_date') }}</span>
                    </v-col>
                </v-row> -->

            <!-- <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Coupon Code</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 300px;" :class="{'has-error': errors.has('coupon_code') }">
                        <input
                                :disabled="!!coupon.id"
                                class="w-input input"
                                type="text"
                                v-model="coupon.code"
                                v-validate="'required|alpha_num'"
                                data-vv-as="coupon code"
                                :class="{'has-error': errors.has('coupon_code') }"
                                name="coupon_code"
                                id="coupon_code"
                        />
                    </div>

                    <span v-show="errors.has('coupon_code')" class="status-block error-block field-error">{{ errors.first('coupon_code') }}</span>
                </div>
            </div>

            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Description</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 300px;" :class="{'has-error': errors.has('coupon_name') }">
                            <textarea
                                    class="w-input input"
                                    v-model="coupon.description"
                                    :class="{'has-error': errors.has('coupon_description') }"
                                    name="coupon_description"
                                    id="coupon_description"
                            ></textarea>
                    </div>

                    <span v-show="errors.has('coupon_name')" class="status-block error-block field-error">{{ errors.first('coupon_description') }}</span>
                </div>
            </div>

            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Max Uses</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 300px;" :class="{'has-error': errors.has('coupon_max_uses') }">
                        <input
                                class="w-input input"
                                type="number"
                                v-model="coupon.max_uses"
                                v-validate="'numeric'"
                                :class="{'has-error': errors.has('coupon_max_uses') }"
                                name="coupon_max_uses"
                                id="coupon_max_uses"
                        />
                    </div>

                    <span v-show="errors.has('coupon_max_uses')" class="status-block error-block field-error">{{ errors.first('coupon_max_uses') }}</span>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Start Date</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 300px;" :class="{'has-error': errors.has('coupon_start_date') }">
                        <datepicker
                                clear-button
                                format="MM/dd/yyyy"
                                v-model="coupon.start_date"
                                wrapper-class="date-wrapper"
                                input-class="input light w-input date-input" ></datepicker>
                    </div>

                    <span v-show="errors.has('coupon_start_date')" class="status-block error-block field-error">{{ errors.first('coupon_start_date') }}</span>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">End Date</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 300px;" :class="{'has-error': errors.has('coupon_end_date') }">
                        <datepicker
                                clear-button
                                format="MM/dd/yyyy"
                                v-model="coupon.end_date"
                                wrapper-class="date-wrapper"
                                input-class="input light w-input date-input"></datepicker>
                    </div>

                    <span v-show="errors.has('coupon_end_date')" class="status-block error-block field-error">{{ errors.first('coupon_end_date') }}</span>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">&nbsp;</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" >
                        <button class="w-button primary-btn" :disabled="isLoading($options.name)" @click.prevent="saveCoupon">Save Coupon</button>
                        <a @click.prevent="clearForm" class="close-link">Clear</a>
                        <span v-show="isLoading($options.name)" >
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                        </span>
                    </div>

                </div>
            </div> -->
        <!-- </div> -->
    <!-- </div> -->


</template>

<script type="text/babel">

	import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import HbDatePicker from '../assets/HummingbirdDatepicker';
	import moment from 'moment';
	import api from '../../assets/api.js';
	import Datepicker from 'vuejs-datepicker';
	import FullTable from '../assets/FullTable.vue';
    import HoverableEditField from "../assets/HoverableEditField.vue";
    import { EventBus } from '../../EventBus.js';
    import { mapGetters, mapActions } from "vuex";
	export default {
		name: "ManageCoupons",
		data() {
			return {
                activelyEditing: '',
                showAddCouponForm: false,
				coupon: {
					id: '',
					code: '',
					description: '',
					max_uses: null,
					start_date: null,
					end_date: null,
					active: true
				},
                coupons:[],
				structure: [
					{
						label: "Code",
						key: "code",
						sortable:true,
						column_type: 'string',
						width: 150,
					},

					{
						label: "Status",
						key: "active",
						sortable:true,
						column_type: 'string'
					},

					{
						label: "Uses",
						key: "uses",
						sortable:true,
					},
					{
						label: "Time Period",
						key: "start_date",
						sortable:true,
						column_type: 'string'
					},

					{
						label: "Actions",
						key: "actions",
						sortable:true,
						column_type: 'string'
					}
				],
				filters:{
					search:{
					},
					columns:[
						'code',
						'uses',
						'start_date',
						'active',
						'actions'
					],
					sort:{
						field:'code',
						dir: "ASC"
					},
					page: 1,
					limit:25,
					offset:0
                },
                headers: [
                    { text: 'Name', value: 'code', sortable: false },
                    { text: 'Uses', value: 'max_uses', sortable: false },
                    { text: 'Start Date', value: 'start_date', width: 110, sortable: false },
                    { text: 'End Date', value: 'end_date', width: 110, sortable: false },
                    { text: 'Active', value: 'active', sortable: false },
                    { text: '', value: 'actions', align: 'right', sortable: false}
                ]
			}
		},
		props: [
			'promotion_id', 'promotionType'
		],
		async created(){
			await this.fetchCoupons();
            EventBus.$on('activeEdit', this.setActiveEditName);
		},
        components:{
	        Datepicker,
	        Status,
            Loader,
            FullTable,
            HbDatePicker,
            HoverableEditField
        },
        computed: {
            ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission'
            }),
            editMode() {
                if (this.promotion_id) {
                    return true;
                }
                return false;
            },
        },
		methods:{
            setActiveEditName(name) {
                this.activelyEditing = name;
            },
            async editCoupon(coupon){

	            this.coupon = {
	            	id: coupon.id,
		            code: coupon.code,
		            description: coupon.description,
		            max_uses: coupon.max_uses,
		            start_date: coupon.start_date ? moment.utc(coupon.start_date).startOf('day').format('YYYY-MM-DD'): moment.utc(new Date()).startOf('day').format('YYYY-MM-DD'),
	                end_date: coupon.end_date ? moment.utc(coupon.end_date).startOf('day').format('YYYY-MM-DD'): moment.utc(new Date()).startOf('day').format('YYYY-MM-DD'),
		            active: coupon.active
	            };
                this.showAddCouponForm = true;
            },
			async toggleCoupon(coupon, active){
                coupon.active = active;

                var data = {
					code: coupon.code,
					description: coupon.description,
					max_uses: coupon.max_uses,
	                start_date: coupon.start_date ? moment.utc(coupon.start_date).startOf('day').format('YYYY-MM-DD'): new Date(),
	                end_date: coupon.end_date ? moment.utc(coupon.end_date).startOf('day').format('YYYY-MM-DD'): new Date(),
					active: coupon.active
				};

				let response = await api.put(this, api.PROMOTIONS + this.promotion_id + '/coupons/' + coupon.id, data, this.$options.name);

            },

			async fetchCoupons(){
				const r = await api.get(this, api.PROMOTIONS + this.promotion_id + '/coupons');
				this.coupons = r.coupons;
            },

            async saveCoupon(){

            	let coupon = JSON.parse(JSON.stringify(this.coupon));


	            const data = {
		            code: coupon.code,
		            description: coupon.description,
                    max_uses: coupon.max_uses,
                    start_date: coupon.start_date ? moment.utc(coupon.start_date).startOf('day').format('YYYY-MM-DD'): moment.utc(new Date()).startOf('day').format('YYYY-MM-DD'),
                    end_date: coupon.end_date ? moment.utc(coupon.end_date).startOf('day').format('YYYY-MM-DD'): moment.utc(new Date()).startOf('day').format('YYYY-MM-DD'),
                    active: coupon.active
	            };

	            let status = await this.validate(this);

                if(!status) return;

                if(this.coupon.id){
		            let response = await api.put(this, api.PROMOTIONS + this.promotion_id + '/coupons/' + this.coupon.id, data, this.$options.name);
                } else {
		            let response = await api.post(this, api.PROMOTIONS + this.promotion_id + '/coupons', data, this.$options.name);
                }

                this.fetchCoupons();
                this.clearForm();




            },

			clearForm(){
				this.coupon = {
					id: null,
					code: '',
                    description: '',
                    max_uses: null,
                    start_date: null,
                    end_date: null,
                    active: true
				}
                this.showAddCouponForm = false;
            }

        },
        destroyed(){
            EventBus.$off('activeEdit', this.setActiveEditName);
        },
	}

</script>

<style scoped>
    .mrl--25px{
        margin-left: -25px;
        margin-right: -24px;
    }
    .key-label{
      background: #F4F6F8;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .no-top-border{
        border-top: 0;
    }
    .border-bottom{
        border-bottom: 1px solid #DFE3E8;
    }
    .overflow-text {
        overflow-wrap: anywhere;
    }
    .scrollable-div{
        max-height: 350px;
        overflow: auto;
    }
    .promotion-icon{
        position: relative;
        top: 5px;
    }
    .no-border-section{
        border: 0 !important;
    }
</style>
