<template>
    <div class="AccessControl">
      <access :isOPS="true" level="Property" @setAccessType="selectedAccessType" ref="AccessControl"></access>
    </div>
</template>

<script>
import Access from '../../../settings/AccessControl/Index.vue';
import { EventBus } from '../../../../EventBus';

export default {
 components: {
   Access
 },
 data() {
    return {
      AccessTypeCheck: null
    }
},
 mounted(){
     // Get event from App footer checkbox
     EventBus.$on('get_accesscontrol_data',this.checkForData);
   },
   methods:{
        // Get event from App footer checkbox
        async checkForData() {
        // Emit event to footer checkbox
            if (this.AccessTypeCheck) {
              const canConfirm = this.AccessTypeCheck != null ? true : false;
              EventBus.$emit("receive_accesscontrol_data", {canConfirm : canConfirm}); 
            } else {
              EventBus.$emit("receive_accesscontrol_data", {message : 'Setup your Access Control in order to confirm the settings.'}); 
            }
        },
        selectedAccessType(val) {
          this.AccessTypeCheck = val;
        }
   }
};
</script>

<style lang="scss" scoped>
  .AccessControl {
     margin-top: -10px;
     margin-right: 5px;
  }
</style>