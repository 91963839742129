<template>
    <div>
        <delinquency :isOps="true" ></delinquency>
    </div>
</template>
<script>
import delinquency from '../../../settings/Automation/Groups.vue'
import { mapGetters, mapActions } from "vuex";

  
export default {
    components:{
        delinquency
    },
    computed: {
    ...mapGetters({
      onboardingData: "onboardingTabsStore/getOnboardingData"
    })
  },
}
</script>
<style scoped>

</style>