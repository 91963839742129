<template>
  <div>
    <SpreadSheet
      type="Space Mix"
      :downloadLink="downloadlink"
      :uploadLink="uploadLink"
      ref="spacemix"
    ></SpreadSheet>
  </div>
</template>

<script>
import SpreadSheet from "../../../includes/Onboarding/Spreadsheet/Index.vue";
import api from "../../../../assets/api.js";
import { mapGetters} from "vuex";
import { EventBus } from '../../../../EventBus';
export default {
  components: {
    SpreadSheet
  },
  data() {
    return {
      downloadlink: api.DOWNLOAD_SPREADSHEET_SPACEMIX,
      uploadLink: api.UPLOAD_SPREADSHEET_SPACEMIX,
    };
  },
  mounted(){
     // Get event from App footer checkbox
     this.checkForData();
     EventBus.$on('get_spacemix_data',this.checkForData);
   },
    computed: {
    ...mapGetters({
      onboardingData: "onboardingTabsStore/getOnboardingData"
    })
    },
  methods:{
     async checkForData(){
       if (this.$refs.spacemix) {
      // Emit event to footer checkbox
        let canConfirm;
        let data ={
          property_name:this.onboardingData.property.name
        }
        await api.get(this, api.SPACE_MIX_CONFIRM,data).then(r => {
          if(r.length > 0){
            canConfirm = true;
          }
        });
        EventBus.$emit("receive_spacemix_data", {canConfirm : canConfirm})
       }
    },
    destroyed(){
      EventBus.$off('get_spacemix_data');
    }
  }
}
</script>
