import ADDRESS from '@/constants/address.js';
import { countryStateCityMixin } from '@/mixins/countryStateCityMixin.js';
export const formatTitleMixin = {
    data() {
        return {
            errorList: {}
        }
    },
    mixins: [countryStateCityMixin],
    async created() {
        this.ADDRESS = ADDRESS;
        await this.setCountiesAndStates();
    },
    methods: {
        async setCountiesAndStates() {
            this.setCountriesListEvent();
            this.setStatesListEvent(ADDRESS.DEFAULT_COUNTRY);
        },
        formatTitle(workflow_full_path) {
            const parts = workflow_full_path.split(' > ');
            if (parts.length > 0) {
                const state = parts[0];
                const formattedState = this.stateListRawMixin.find(item => item.isoCode === state)?.name || state;
                return [formattedState, ...parts.slice(1)].join(' > ');
            }
            return workflow_full_path;
        }
    }
};