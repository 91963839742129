<template>
  <hb-modal
    size="medium"
    title="Notice"
    v-model="dialogModal"
    @close="close()"
    :closeInsteadOfCancel="true"
    :confirmation="true"
    show-help-link
  >
    <template v-slot:content>
      <div class="content-height px-6 font-style">
        In order to set up the {{title}} you need to complete:
        <div>
          <ul v-for="(list, index) in dependencyList" :key="index + 'list'">
            <li>{{ list | splitCamelCase }}</li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:icon> </template>
    <template v-slot:actions> </template>
  </hb-modal>
</template>
<script>
export default {
  data() {
    return {
      dialogModal: true
    };
  },
  props: ["dependencyList" ,"title"],
  filters: {
    splitCamelCase(text) {
      var result = text.replace(/([A-Z])/g, " $1");
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    }
  },
  methods: {
    close() {
      this.$emit("closeModal");
      this.dialogModal = false;
    }
  }
};
</script>

<style>
.content-height {
  min-height: 150px;
  max-height: 600px;
  overflow: auto;
}
.px-6 {
  padding: 24px;
}
.font-style {
  font-family: "Graphik Web";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #101318;
}
</style>
