<template>
  <hb-modal size="medium" :title="formatTitle(title)" v-model="dialog" confirmation
    @close="$emit('close')">
    <template v-slot:content>
      <div class="py-4 px-6">
        <div class="description" v-html="description"></div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn color="primary" @click="$emit('ok')" :disabled="disabled" :loading="disabled">{{ AUTOMATION.SAVE_STAGE
        }}</hb-btn>
    </template>
  </hb-modal>
</template>
<script type="text/babel">
import AUTOMATION from '@/constants/automation.js';
import { formatTitleMixin } from "@/mixins/formatTitleMixin.js";
export default {
  name: "StageWarning",
  data() {
    return {
    };
  },
  mixins: [formatTitleMixin],
  props: {
    description:
    {
      type: String,
      default() {
        return '';
      }
    },
    title:
    {
      type: String,
      default() {
        return '';
      }
    },
    value: {
      type: Boolean,
      default() {
        return false;
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  created() {
    this.AUTOMATION = AUTOMATION;
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  destroyed() {
  },
  methods: {
  }

};
</script>