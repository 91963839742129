<template>
    <div>
        <FeeLibrary level="Corporate" ref="feeLibraryRef" :isOPS="true"></FeeLibrary>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FeeLibrary from "../../../settings/Fees/Index.vue";
import { EventBus } from '../../../../EventBus';
import api from '../../../../assets/api.js';

export default {
    components: {
    FeeLibrary,
  }, 
  computed: {
    ...mapGetters({
      activeTabData: "onboardingTabsStore/getActiveTab",
    }),
  },
  mounted(){
     // Get event from App footer checkbox
     EventBus.$on('get_feelibrary_data',this.checkForData);
   },
   methods:{
        // Get event from App footer checkbox
        async checkForData() {
        // Emit event to footer checkbox
            if (this.$refs.feeLibraryRef) {
                 let canConfirm = this.$refs.feeLibraryRef.feesList.length ? true : false;
                if(canConfirm){
                    await this.checkProductForAccount().then((result) => {
                        let canConfirm = result;
                        EventBus.$emit("receive_feelibrary_data", {canConfirm : canConfirm, message : 'Setup account codes for fees in order to confirm ' + this.activeTabData.title + ' settings.'});
                    });                   
                }else{
                    EventBus.$emit("receive_feelibrary_data", {canConfirm : canConfirm}); 
                }
            }
        },
        async checkProductForAccount(){            
            let products = this.$refs.feeLibraryRef.feesList;
            let result = products.every(function(element) {
                if (element.income_account_id) return true
                else return false
            });            
            return result;                 
        }
   },
   destroyed(){
       EventBus.$off('get_feelibrary_data');
   }
}
</script>