<template>
    <div class="tax-proile">
      <TaxProfiles :isOPS="true" level="Property" ref="TaxProfiles"></TaxProfiles>
    </div>
</template>

<script>
import TaxProfiles from '../../../settings/Tax/Index.vue';
export default {
 components: {
   TaxProfiles
 },
};
</script>

<style lang="scss">
  .tax-proile {
     margin-top: -10px;
  }
</style>