<template>
   

    <div>

        <hb-modal v-model="dialog" show-help-link size="large" :title="isEdit ? 'Edit Tax Profile' : 'Add Tax Profile'">
        <template v-slot:subheader v-if="!selected.id">
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            Complete the required fields to add a new tax profile.
        </template>
        <template v-slot:content>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name) && selected.id" :message="errorGet($options.name)" status="error" class="mx-6 mt-3"></status>

            <hb-form label="Tax Name" required>
                <v-text-field
                    label="Enter Name"
                    v-validate="'required|max:45'"
                    required
                    id="name"
                    name="name"
                    v-model="taxProfile.name"
                    single-line
                    :error-messages="errors.first('name')"
                    hide-details
                > </v-text-field>
            </hb-form>
            
            <hb-form label="State" required>
                <vuetify-statepicker
                    :id="'state'"
                    :name="'state'"
                    :single_line="true"
                    v-validate="'required'"
                    v-model="taxProfile.state"
                    :selected="isOPS && Object.keys(selected).length === 0 ? taxProfile.state : []"
                    :error_message="errors.first('state')"
                    hide_details
                ></vuetify-statepicker>
            </hb-form>

            <hb-form label="Liability Account" v-if="account_ids.length > 0">
                <v-select
                    single-line
                    id="liability"
                    name="liability"
                    v-validate="`${incomeAccountRequired ?  'required' : ''}`"
                    :error-messages="errors.first('liability') ? 'Liability account is required as accounting service is enabled' : ''"
                    hide-details
                    data-vv-as="liability account"
                    label="Select an Account"
                    :items="account_ids"
                    item-value="id"
                    v-model="taxProfile.account_id" >
                    <template v-slot:selection="{ item }">
                    {{item.code}} : {{item.name}}
                    </template>
                    <template v-slot:item="{ item }">
                    {{item.code}} : {{item.name}}
                    </template>
                </v-select>
            </hb-form>


<!--            <v-row class="ma-0 pa-0 top-border">-->
<!--                <v-col md="3" class="key-label pl-6 pt-2">-->
<!--                    <div class="pt-2">GL Code</div>-->
<!--                </v-col>-->
<!--                <v-col md="5" class="pl-1">-->
<!--                    <v-text-field-->
<!--                        placeholder="Enter GL Code"-->
<!--                        v-validate="'required|max:45'"-->
<!--                        required-->
<!--                        id="gl_code"-->
<!--                        name="gl_code"-->
<!--                        v-model="taxProfile.gl_code"-->
<!--                        solo-->
<!--                        flat-->
<!--                        single-line-->
<!--                        data-vv-as="GL code"-->
<!--                        :error-messages="errors.first('gl_code')"-->
<!--                        :hide-details="!errors.has('gl_code')"-->
<!--                    > </v-text-field>-->
<!--                </v-col>-->
<!--            </v-row>-->

            <hb-form label="Tax Rate %" required last>
                <v-text-field
                    label="Enter Rate"
                    v-validate="'required|between:0,100'"
                    required
                    id="tax_rate"
                    name="tax_rate"
                    v-model="taxProfile.tax_rate"
                    single-line
                    data-vv-as="tax rate"
                    :error-messages="errors.first('tax_rate')"
                    hide-details
                ></v-text-field>
            </hb-form>

        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="save"><span v-if="isEdit">Save</span><span v-else>Add</span></hb-btn>
        </template>
    </hb-modal>

    <income-account-alert
    v-if="showAlertDialog"
    v-model="showAlertDialog"
    @confirm="confirmAlert"
    @close="showAlertDialog = false">
    </income-account-alert>

</div>



</template>






<script type="text/babel">
import Status from '../../includes/Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import api from '../../../assets/api.js';
import VuetifyStatepicker from '../../includes/VuetifyStatepicker.vue';
import { mapGetters, mapActions } from 'vuex';
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import IncomeAccountAlert from '../IncomeAccountAlertModal/IncomeAccountAlert.vue'

export default {
    name: 'AddEditTaxProfiles',
    data() {
        return {

            taxProfile: {
                name: '',
                state: '',
                account_id: '',
                tax_rate: ''
            },
            isEdit: false,
            showAlertDialog: false,
            alertConfirmed : false,
        }
    },
    components: {
        Loader,
        Status,
        VuetifyStatepicker,
        IncomeAccountAlert
    },
    mixins: [notificationMixin],
    props: ['selected', 'value', 'state', 'accounts','isOPS'],
    async created() {

        if(this.selected && this.selected.id) {
            this.isEdit = true;
            this.taxProfile = JSON.parse(JSON.stringify(this.selected));
        } else {
            if(this.state) {
                this.taxProfile.state = this.state;
            }
        }

        // await this.getAccountingType({ isTax : true });
        await this.getAccountingType();

    },
    computed: {
        ...mapGetters({
                incomeAccountRequired: 'accountingStore/incomeAccountRequired',
                recordCountWithNullIncomeAccount: 'accountingStore/recordCountWithNullIncomeAccount',
        }),

        account_ids(){
            return this.accounts.filter(a => a.category && a.category.toLowerCase() === 'liability');
        },
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        ...mapActions({
            addTaxProfile: 'taxProfileStore/addTaxProfile',
            editTaxProfile: 'taxProfileStore/editTaxProfile',
            getAccountingType : 'accountingStore/getAccountingType',
        }),
        // async fetchAccounting(){
        //     let p = await api.get(this, api.SETTINGS + 'accounting');
        //     this.accounts = p.account.COA
        // },


        async confirmAlert() {
            this.alertConfirmed = true;
            this.showAlertDialog = false;
            this.save()
        },

        async save() {

            this.$validator.validateAll().then(async (status) => {
                if(!status) throw "error";

                // if(this.incomeAccountRequired && this.recordCountWithNullIncomeAccount && !this.alertConfirmed) {
                //     // show modal
                //     this.showAlertDialog = true;
                //     return
                // }

                let data = this.taxProfile;
                if(this.isEdit) {
                    await this.editTaxProfile(data).then(response => {
                        this.$emit('close');
                        let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: "The '"+ this.taxProfile.name +"' has been updated on " + currentLocalDate });
                        
                    },
                    err => {
                        this.showMessageNotification({ id: this.$options.name, type: "error", description: err });
                    });
                } else {
                    await this.addTaxProfile(data).then(res => {
                        this.$emit('close', res.tax_profile);
                        let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: "The '"+ res.tax_profile.name +"' has been created on " + currentLocalDate });
                    },
                    err => {
                        this.showMessageNotification({ id: this.$options.name, type: "error", description: err });
                    });
                }
            }).catch(err => {
                this.showMessageNotification({ id: this.$options.name, type: "error", description: "You have errors on your form.  Please fix them before continuing"});
            });
        }
    }


}
</script>
<style scoped>
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
</style>>
