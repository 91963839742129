<template>
    <hb-modal
        size="large"
        :title="title"
        v-model="dialog"
        @close="$emit('closeWindow')"
        show-help-link
    >
        <template v-slot:subheader>
            Enter the user's basic information. 
        </template>
        <template v-slot:content>
            <hb-form label="Status" required>
                <HbSelect
                    :clearable="false"
                    v-model="user.status"
                    :items="statusList"
                    item-text="name"
                    item-value="id"
                    v-validate="'required'"
                    data-vv-scope="default"
                    data-vv-name="status"
                    data-vv-as="status"
                    :error="errors.has('default.status')"
                    placeholder="Select Status"
                    autofocus
                />
            </hb-form>
            <hb-form label="Employee ID">
                <HbTextField
                    v-model="user.employee_id"
                    v-validate="'max:45'"
                    data-vv-scope="default"
                    data-vv-name="employee_id"
                    data-vv-as="Employee ID"
                    :error="errors.has('default.employee_id')"
                    placeholder="Employee ID"
                />
            </hb-form>
            <hb-form label="User Name" v-if="editFlag">
                {{ user.user_name }}
            </hb-form>
            <hb-form label="Name " multiple required>
                <v-row>
                    <v-col cols="12" sm="6" class="py-0 my-0">
                        <HbTextField
                            v-model="user.first"
                            v-validate="'required|max:45'"
                            data-vv-scope="default"
                            data-vv-name="first_name"
                            data-vv-as="First Name"
                            :error="errors.has('default.first_name')"
                            placeholder="First Name"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0 my-0">
                        <HbTextField
                            v-model="user.last"
                            v-validate="'required|max:45'"
                            data-vv-scope="default"
                            data-vv-name="last_name"
                            data-vv-as="Last Name"
                            :error="errors.has('default.last_name')"
                            placeholder="Last Name"
                        />
                    </v-col>
                </v-row>
            </hb-form>
            <hb-form label="Email" required>
                <HbTextField
                    v-model="user.email"
                    v-validate="'required|email|max:45'"
                    data-vv-scope="default"
                    data-vv-name="email"
                    data-vv-as="Email"
                    :error="errors.has('default.email')"
                    placeholder="Enter Email"
                />
            </hb-form>
            <hb-form label="Mobile Number" required>
                <v-row>
                    <v-col cols="3" class="py-0 my-0">
                    <HbSelect
                        v-model="countryCode"
                        :items="countryCodesList"
                        :clearable="false"
                        placeholder="Code"
                    >
                        <template v-slot:prepend-inner> + </template>
                        <template v-slot:item="data"> +{{ data.item }} </template>
                    </HbSelect>
                    </v-col>
                    <v-col cols="9" class="py-0 my-0">
                    <HbTextField
                        v-model="user.phone"
                        v-validate="'required|length:14'"
                        v-mask="'(###) ###-####'"
                        data-vv-scope="default"
                        data-vv-name="phone"
                        data-vv-as="Phone"
                        :error="errors.has('default.phone')"
                        placeholder="Phone"
                        full
                    />
                    </v-col>
                </v-row>
            </hb-form>
            <hb-form label="Primary Role" required multiple>
                <HbSelect
                    :clearable="false"
                    v-model="user.role_id"
                    :items="filteredRolesList"
                    item-text="name"
                    item-value="id"
                    v-validate="'required'"
                    data-vv-scope="default"
                    data-vv-name="role_id"
                    data-vv-as="Role"
                    :error="errors.has('default.role_id')"
                    placeholder="Select Role"
                />
                <v-row>
                    <v-col cols="12" sm="12" class="py-0 my-0 hb-text-light">
                        Select the properties you want to grant this employee access to.
                    </v-col>
                </v-row>
                <hb-combobox
                    v-model="user.PrimaryProperties"
                    :items="propertyItems"
                    id="primary_properties"
                    name="primary_properties"
                    label="Select Properties"
                    data-vv-scope="default"
                    data-vv-name="primary_properties"
                    data-vv-as="Properties"
                    :error="errors.has('default.primary_properties')"
                    select
                    item-value="id"
                    item-text="name"
                    return-object
                >
                    <template v-slot:item="{item, attrs}">
                        <v-list-item-action> 
                            <v-icon v-if="attrs.inputValue">check_box</v-icon> 
                            <v-icon v-else :color="item.disabled ? '#DFE3E8' : '#9b9b9b'">check_box_outline_blank</v-icon>
                        </v-list-item-action>
                        <v-list-item-content> 
                            <v-list-item-title> 
                                <span v-if="item.number" :class="{'text--disabled': item.disabled}">{{ item.number }} - </span>
                                <span :class="{'text--disabled': item.disabled}">{{ item.city }} - {{ item.name }}</span>
                            </v-list-item-title> 
                        </v-list-item-content> 
                    </template>
                </hb-combobox>
                <v-row>
                    <v-col cols="12" sm="12" class="py-0 my-0 hb-text-light">
                        Select the Non Hummingbird Properties you want to grant this employee access to.
                    </v-col>
                </v-row>
                <hb-combobox
                    v-model="user.NonHbProperties"
                    :items="nonHbProperties"
                    id="inter_properties"
                    name="inter_properties"
                    label="Select Properties"
                    select
                    item-value="id"
                    item-text="name"
                    return-object
                >
                    <template v-slot:item="{item, attrs}">
                        <v-list-item-action> 
                            <v-icon v-if="attrs.inputValue">check_box</v-icon> 
                            <v-icon v-else>check_box_outline_blank</v-icon>
                        </v-list-item-action>
                        <v-list-item-content> 
                            <v-list-item-title> 
                                <span v-if="item.number">{{ item.number }} - </span>
                                <span>{{ item.city }} - {{ item.name }}</span>
                            </v-list-item-title> 
                        </v-list-item-content> 
                    </template>
                </hb-combobox>
            </hb-form>
            <hb-form label="Inter Property Operations" description="Select the properties where this employee can take a payment from." v-if="isInterPropertyPayment">
                <template v-slot:tooltipBody>
                    This role enables user to search for tenants on assigned properties and take payments for those tenants
                </template>
                <hb-combobox
                    v-model="user.InterProperties"
                    :items="interPropertyItems"
                    id="inter_properties"
                    name="inter_properties"
                    label="Select Inter Properties"
                    select
                    item-value="id"
                    item-text="name"
                    return-object
                >
                    <template v-slot:item="{item, attrs}">
                        <v-list-item-action>
                            <v-icon v-if="attrs.inputValue">check_box</v-icon> 
                            <v-icon v-else :color="item.disabled ? '#DFE3E8' : '#9b9b9b'">check_box_outline_blank</v-icon> 
                        </v-list-item-action>
                        <v-list-item-content> 
                            <v-list-item-title> 
                                <span v-if="item.number" :class="{'text--disabled': item.disabled}">{{ item.number }} - </span>
                                <span :class="{'text--disabled': item.disabled}">{{ item.city }} - {{ item.name }}</span>
                            </v-list-item-title> 
                        </v-list-item-content> 
                    </template>
                </hb-combobox>
            </hb-form>
        </template>
        <template v-slot:actions>
            <hb-btn v-if="!editFlag" color="primary"  @click="save">Add</hb-btn>
            <hb-btn v-else color="primary" @click="update">Update</hb-btn>
        </template>
    </hb-modal>
</template>
<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import PHONE from "@/constants/phone.js";

    import { isValidPhoneNumber } from 'libphonenumber-js';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import { mapGetters } from 'vuex';

    export default {
        name: "EditUser",
        data() {
            return {
                user:{
                    id: null,
                    first: null,
                    last: null,
                    email: null,
                    phone: null,
                    role_id: null,
                    pin: null,
                    status: null,
                    Properties: [],
                    InterProperties: [],
                    NonHbProperties: [],
                    PrimaryProperties: [],
                    employee_id: null
                },
                inter_property_role_id: null,
                primary_role_id: null,
                stepper: 1,
                roles: [],
                properties: [],
                nonHbProperties: [],
                title: '',
                editFlag: false,
                statusList: [
                    { name: 'Active', id: 1 },
                    { name: 'Inactive', id: 0 }
                ],
                tabHeader: 0,
                countryCodesList: PHONE.COUNTRY_CODES,
                countryCode: PHONE.COUNTRY_CODES[0],
                resizeStepper: 0,
                textFieldFirstNameFocused: false,
                textFieldLastNameFocused: false,
            }
        },
        mixins: [ notificationMixin ],
        components:{
            Status
        },
        props:[
            'selected',
            'value',
            'getCountryCode'
        ],
        watch: {
            stepper () {
                this.tabHeader = this.stepper - 1
            },
            "errors.items"(val) {
                if(val && val.length) {
                    val.forEach(element => {
                        if(element.field == 'phone') {
                            element.msg = 'The phone field must contains at least 10 digits'
                        }
                    });
                }
            }
        },
        created(){
           this.reset();
           if(this.getCountryCode.length && this.selected.id) {
            this.countryCode = this.countryCodesList.find(el=> el == this.getCountryCode)
           } else {
            this.countryCode = this.countryCodesList[0];
           }
        },
        computed: {
            ...mapGetters({
                isInterPropertyPayment: 'propertiesStore/isInterPropertyPayment',
                allPropertiesList: 'propertiesStore/properties',
                primaryRoleProperties: 'propertiesStore/primaryRoleProperties',
                getUserData: 'authenticationStore/getUserData',
                storeInterPropertyName: 'authenticationStore/getInterPropertyName'
            }),
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            propertyItems() {

                return this.userSpecificPropertiesList.map(p => {
                    let index = this.user.InterProperties.findIndex(x=> x.id === p.id);
                    return {
                        id: p.id,
                        name: p.name,
                        number: p.number,
                        city: p.city,
                        gds_id: p.gds_id,
                        disabled: (index === -1) ? false : true,
                    }
                });
            },
            interPropertyItems() {
                return this.userSpecificPropertiesList.map(p => {
                    let index = this.user.PrimaryProperties?.findIndex(x=> x.id === p.id);
                    return {
                        id: p.id,
                        name: p.name,
                        number: p.number,
                        city: p.city,
                        gds_id: p.gds_id,
                        disabled: (index === -1) ? false : true,
                    }
                });
            },
            interPropertyOperationsRole() {
                return this.roles.find(r => r.name?.toLowerCase().split(" ").join("-") === this.storeInterPropertyName);
            },
            userSpecificPropertiesList() {
                return this.getUserData?.id == this.selected?.id ? this.allPropertiesList : this.primaryRoleProperties;
            },
            filteredRolesList() {
                return this.roles.length ? this.roles.filter(item => item.name?.toLowerCase().split(" ").join("-") !== this.storeInterPropertyName) : [];
            }

        },
        methods:{
            setSelectedUserRoleIds() {
                this.selected?.Roles?.forEach(r => {
                    if(r.name?.toLowerCase().split(" ").join("-") === this.storeInterPropertyName) {
                        this.inter_property_role_id = r.role_id;
                    }
                    else {
                        this.primary_role_id = r.role_id;
                        this.user.status = r.status;
                    }
                });
            },
            checkStepOne(){
                       if(this.countryCode && this.user.phone){
                        
                        try {
                            var checkIfPhoneNumberValid = isValidPhoneNumber('+' + this.countryCode + this.user.phone);
                            if(!checkIfPhoneNumberValid){
                                this.errorSet(this.$options.name, "Please enter a valid phone number.");
                                return false;
                            } else{ 
                                return true;}
                        } catch(err) {
                            this.errorSet(this.$options.name, "Please enter a valid phone number.");
                            return false;
                        }
                        
                    } else{
                        this.errorSet(this.$options.name, "Please enter a valid phone number.");
                        return false;
                    } 
                    

                
                
            },
            save(){
                this.$validator.validateAll('default').then(status => {
                    if(!status) return;
                    
                    if(this.countryCode && this.user.phone){
                        try {
                            var checkIfPhoneNumberIsValid = isValidPhoneNumber('+' + this.countryCode + this.user.phone);
                            if(!checkIfPhoneNumberIsValid){
                                this.showMessageNotification({description: "Please enter a valid phone number."});
                                return;
                            }
                        } catch(err) {
                            this.showMessageNotification({description: "Please enter a valid phone number."});
                            return;
                        }

                    } else {
                        this.showMessageNotification({description: "Please enter a valid phone number."});
                        return;
                    }

                    let payload = {...this.user};
                    payload.phone = '+'.concat(this.countryCode.concat(payload.phone));
                    payload.RolesProperties = [];
                    //transform primary role properties
                    payload.RolesProperties.push({role_id: payload.role_id, NonHbProperties: payload.NonHbProperties.map(p => p.id), Properties: payload.PrimaryProperties?.map(ip => ip.id)});
                    //transform inter property role properties
                    if(this.user.InterProperties.length) {
                        payload.RolesProperties.push({role_id: this.interPropertyOperationsRole?.id, Properties: payload.InterProperties.map(ip => ip.id)});
                    }
                    return api.post(this, api.ADMIN, payload).then(r => {
                        this.$emit('refetch');
                        this.$emit('closeWindow');
                        this.$emit('saved', null, r)
                        // this.showMessageNotification({ id: this.$options.name, type: "success", description: `Administrator added successfully.` });
                    }).catch(err => {
                        this.showMessageNotification({ id: this.$options.name, type: "error", description: err });
                    })
                });
            },
            update (){
                this.$validator.validateAll('default').then(status => {
                    if(!status) return;

                    if(this.countryCode && this.user.phone){
                        try {
                            var checkIfPhoneNumberIsValid = isValidPhoneNumber('+' + this.countryCode + this.user.phone);
                            if(!checkIfPhoneNumberIsValid){
                                this.showMessageNotification({description: "Please enter a valid phone number."});
                                return;
                            }
                        } catch(err) {
                            this.showMessageNotification({description: "Please enter a valid phone number."});
                            return;
                        }

                    } else {
                        this.showMessageNotification({description: "Please enter a valid phone number."});
                        return;
                    }

                    let payload = {...this.user};
                    payload.phone = '+'.concat(this.countryCode.concat(payload.phone));
                    //transform primary role properties
                    let primary_role = payload?.RolesProperties?.find(r => r.role_id === this.primary_role_id);
                    primary_role.NonHbProperties = payload.NonHbProperties?.map(p => p.id);
                    primary_role.Properties = payload.PrimaryProperties?.map(p => p.id);
                    primary_role.role_id = payload.role_id;
                    //transform inter property role properties
                    if(this.inter_property_role_id) {
                        let inter_property_role = payload?.RolesProperties?.find(r => r.role_id === this.inter_property_role_id);
                        inter_property_role.Properties = payload.InterProperties.map(ip => ip.id);
                    } else if (this.interPropertyOperationsRole?.id){
                        payload.RolesProperties.push({role_id: this.interPropertyOperationsRole.id, Properties: payload.InterProperties.map(ip => ip.id)});
                    }
                    return api.put(this, api.ADMIN + this.user.id, payload).then(r => {
                        this.$emit('refetch');
                        this.$emit('closeWindow');
                        this.$emit('showSuccessMsg',null, r, payload)
                        // this.showMessageNotification({ id: this.$options.name, type: "success", description: `Administrator updated successfully.` });
                    }).catch(err => {
                        this.showMessageNotification({ id: this.$options.name, type: "error", description: err });
                    })
                });
            },
            next () {
                this.$validator.validateAll('form_'+ this.stepper).then(status => {
                    if(!status) return;
                    if (this.stepper == 1){
                        var passedPhoneNumberCheck = this.checkStepOne();
                        if (passedPhoneNumberCheck == false){
                            return;
                            } 
                            else {
                                this.stepper = 2;
                            }
                    }
                    else if (this.stepper == 2) {
                            this.stepper = 3;
                    } 
                    else if (this.stepper == 3) {
                        this.save();
                    }
                })
            },
            back () {
                if (this.stepper === 1) {
                  return
                } else {
                   this.stepper -= 1
                }
            },
            async fetchRoles (){
                await api.get(this, api.ROLES).then(r => {
                    this.roles = r.roles;
                });
            },
            fetchProperties(){
                    this.properties = this.userSpecificPropertiesList.map(p => {
                        return {
                            id: p.id,
                            name: p.Address.address,
                            number: p.number,
                            city: this.$options.filters.formatAddress(p.Address),
                            gds_id: p.gds_id ?? ""
                        }
                    });
                    if(this.editFlag) {
                        //primary role properties
                        let primary_role = this.selected?.RolesProperties?.find(r => r.role_id === this.primary_role_id);
                        let properties = primary_role?.Properties || [];
                        let newUserPropertiesList = [];
                        if(properties.length) {
                            this.properties.forEach(p => {
                                let index = properties.findIndex(x=> x === p.id)
                                if (index !== -1) {
                                    newUserPropertiesList.push(p)
                                }
                            });
                        }
                        this.user.PrimaryProperties = newUserPropertiesList
                        //inter-property role properties
                        let inter_property_role = this.selected?.RolesProperties?.find(r => r.role_id === this.inter_property_role_id);
                        let inter_properties = inter_property_role?.Properties || [];
                        let newUserInterPropertiesList = [];
                        if(inter_properties.length) {
                            this.properties.forEach(p => {
                                let index = inter_properties.findIndex(x=> x === p.id)
                                if (index !== -1) {
                                    newUserInterPropertiesList.push(p)
                                }
                            });
                        }
                        this.user.InterProperties = newUserInterPropertiesList;
                    }
            },
            async fetchNonHbProperties(){
                api.get(this, api.NON_HB_PROPERTIES).then(r => {
                    this.nonHbProperties = r.properties.map(p => {
                        return {
                            id: p.id,
                            name: p.Address.address,
                            number: p.number,
                            city: this.$options.filters.formatAddress(p.Address),
                            gds_id: p.gds_id ?? ""
                        }
                    });

                    if(this.editFlag) {
                        //non hb properties of primary role
                        let primary_role = this.selected?.RolesProperties?.find(r => r.role_id === this.primary_role_id);
                        let non_hb_properties = primary_role?.NonHbProperties || [];
                        if(non_hb_properties.length) {
                            let newUserNonHbPropertiesList = []
                            this.nonHbProperties.forEach(p => {
                                let index = non_hb_properties.findIndex(x=> x === p.id)
                                if (index !== -1) {
                                    newUserNonHbPropertiesList.push(p)
                                }
                            });
                            this.user.NonHbProperties = newUserNonHbPropertiesList
                        }
                    }
                });
            },
            fetchData(id){
                api.get(this, api.ADMIN + id).then(r => {

                    this.user = {
                        Role: r.admin.Role,
                        role_id: r.admin.role_id,
                        Properties: r.admin.Properties.map(p => {
                            return {
                                id: p.id,
                                name: p.name,
                                number: p.number,
                            }
                        })
                    }
                });
            },
            async reset () {
                this.stepper = 1

                if (this.selected.id){
                    this.editFlag = true
                    this.selected.InterProperties = [];
                    this.selected.PrimaryProperties = [];
                    this.user = JSON.parse(JSON.stringify(this.selected));
                    this.user.pin = this.user.pin || ''
                    this.user = {...this.user, phone : this.selected.Phones.length > 0 ? this.selected.Phones[0].phone : ''}
                    this.title = 'Edit User'
                    this.setSelectedUserRoleIds();
                } else {
                    this.title = 'Add New User'
                }

                await this.fetchRoles()
                await this.fetchProperties()
                await this.fetchNonHbProperties()
            }   
        }
    }


</script>
<style scoped>
.stepper-header {
    width: 500px;
    box-shadow: none;
}
.v-stepper--alt-labels .v-stepper__step {
    flex-basis: unset !important;
}
.step-headline {
    font-size: 14px;
    font-style: normal;
    color: #637381;
}
.field-label {
  background-color: #F9FAFB;
  height: 64px;
  color: #101318;
}
.field-label-1 {
  background-color: #F9FAFB;
  height: 48px;
  color: #101318;
}
.phone-input {
  height: 32px;
  color: #101318;
  width: 100%;
}
.phone-input:focus {
    outline: none;
}
span{
    color: #101318;
}
.input-error {
    border-bottom: 1px solid #FB4C4C;
}
.phone-error {
    color: #FB4C4C;
    margin-left: 12px;
}
.country-code > .hb-select-wrapper {
    margin-top: 14px !important;
} 
</style>
