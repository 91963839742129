<template class="uploadDocument">
  <hb-modal
    v-model="dialogModal"
    size="medium"
    title="Warning: A Fee May Apply"
    @close="closePopup()"
    confirmation
    show-help-link
  >
    <template v-slot:content>
      <div class="px-6 pt-4 font-style">
        <div>
          <div>
            Depending on the document you may have to pay a fee. We will contact
            you once your Online Property Setup is complete.
          </div>
          <b>Note:</b> The only supported file type to upload is a .doc
          extension.
          <br />
          <div class="mt-2 mb-2">
            Are you sure you want to use your own document?
          </div>
        </div>
        <div v-if="error" style="color: red">
          Please Select a Document of type .doc
        </div>
        <v-file-input
          accept=".docx,.doc"
          :rules="[rules.size]"
          v-model="files"
          placeholder="Select a Document"
          @change="isCorrectFile()"
        ></v-file-input>
        <v-select
          v-if="type === 'other'"
          :items="documentType"
          :rules="[rules.requiredType]"
          v-model="selectedDocumentType"
          label="What does this document apply to?"
        ></v-select>
      </div>
    </template>
    <template v-slot:actions>
      <hb-btn
        color="primary"
        :disabled="isDisable"
        @click="upload()"
        >
          <v-progress-circular v-if="isUploading" indeterminate color="white" :size="15" :width="2" class="mr-1"></v-progress-circular>
          Upload
        </hb-btn
      >
    </template>
  </hb-modal>
</template>

<script>
import api from "../../../../assets/api.js";
import moment from 'moment';
import { EventBus } from '../../../../EventBus';
import { notificationMixin } from  '../../../../mixins/notificationMixin.js';

export default {
  mixins: [notificationMixin],
  props: ["data", 'type'],
  data() {
    return {
      isUploading: false,
      dialogModal: true,
      files: undefined,
      error: false,
      documentType: ["State Specific Document", "General Documents"],
      selectedDocumentType:'',
      rules: {
          size: value => !value || value.size < process.env.VUE_APP_OPS_DOCUMENT_LIBRARY_UPLOAD_DOCUMENT_MAX_SIZE || "The file size should not exceed 20 MB",     
          requiredType: value => !!value || 'Please select the document type',
     },
    };
  },

  methods: {
    isCorrectFile() {
      var fileFormat = /(\.docx|\.doc)$/i;
      if (this.files && this.files.name) {
        if (!fileFormat.exec(this.files.name)) {
          this.files = undefined;
          this.error = true;
        } else {
          this.error = false;
        }
      }
    },
    upload() {
      
      if (this.files && this.files.name) {
        this.isUploading = true;
        var formData = new FormData();
        formData.append("file", this.files);

        if ( this.type==='other') {
          formData.append("subtype", this.selectedDocumentType);
        }
        let docId = this.type !== 'other' ? this.data.id : '' ;
        this.$http.post(api.ONBOARDING + 'upload-document/' + docId, formData).then(res => {
          let message = "Your '"+this.files.name+"' has been replaced the '"+res.data.data.name+"' on "+moment().format('MMM DD, YYYY @ h:mma.');
          if(this.type == 'other'){
            message = "Your '"+res.data.data.name+"' has been uploaded on "+moment().format('MMM DD, YYYY @ h:mma.');
          }
          this.showMessageNotification({ type: 'success', description: message });
          this.isUploading = false;
          this.$emit("onDocumentUploaded", this.type);
        }).catch(err => {
          this.isUploading = false;
          this.showMessageNotification({ description: err.data.data.msg });
        })
      }
    },
    
    closePopup() {
      this.$emit("closeUploadDocumentDialog");
    },
  },
  computed : {
    isDisable() {
      if ( this.type==='other' ) {
        if (this.files && this.files.name && this.files.size < process.env.VUE_APP_OPS_DOCUMENT_LIBRARY_UPLOAD_DOCUMENT_MAX_SIZE && this.selectedDocumentType ) {
          return false
        } else {
          return true
        }
      } else {
        if (this.files && this.files.name && this.files.size < process.env.VUE_APP_OPS_DOCUMENT_LIBRARY_UPLOAD_DOCUMENT_MAX_SIZE) {
          return false
        } else {
          return true
        }
      }  
    },
  }
};
</script>

<style lang="scss" scoped>
.uploadDocument {
  .px-6 {
    padding: 15px;
  }
.hb-modal-content .v-input .v-label {
    line-height: 20px;
    font-size: 12px !important;
}
  .font-style {
    font-family: "Graphik Web";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #101318;
  }
 
 
}

 .v-text-field {
     padding-top: 0px !important;
   
}
 .v-input .v-label {
    font-size: 14px !important;
}
.v-input .v-select__selections {
   font-size: 14px !important;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    font-size: 14px !important;
}
</style>
