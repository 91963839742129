<template>
  <v-navigation-drawer
    width="200"
    flat
    :value="drawer"
    class="fill-height mt-65 navigation transform-drawer ml-60"
    disable-resize-watcher
    clipped
    fixed
    elevation-0
    mobile-breakpoint="415"
    id="main-navigation-drawer"
  >
    <v-list dense class="nav-drawer-list mt-2">
      <template v-for="(item, a) in menus" >
        <router-link :to="item.to" :key="a"  :class="[item.status == 'locked' ? 'disableClass' : 'enableClass']" :event="item.status == 'locked' ? '' : 'click'" >
        <v-list-item
          class="py-1 px-4"
          v-if="Object.keys(item.items).length === 0"
          :to="item.to"
          :key="a"
          :class="[item.status == 'locked' ? 'disableClass' : 'enableClass']"
          v-on="item.status == 'locked' ? { click: openModal } : {}"
          @click="activeLinkTab(item)"
        >
          <v-list-item-action class="ma-0 mr-2" >
            <hb-icon>{{ item.icon }}</hb-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span class="hb-default-font-size" :title="item.title">{{
                item.title
              }}</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="ml-0 mr-1">
            <hb-icon v-if="item.status == 'locked'" style="margin-bottom: 5px;"  :small="true" :color="'#637381'">mdi-lock</hb-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-group
          append-icon="mdi-menu-down"
          @click="activeLinkTab(item)"
          :disabled="disableCorporate"
          v-bind:class="[
            item.tabName === tab.tabName ? 'activeClass' : 'notActive'
          ]"
          :id="a + 'navAccordion'"
          :value="panel"
          v-else
          dense
          :key="a"
        >
          <template v-slot:activator class="pr-0">
            <router-link :to="item.to">
              <v-list-item class="py-1 px-0">
                <v-list-item-action class="ma-0 mr-2">
                  <hb-icon>{{ item.icon }}</hb-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="hb-default-font-size" :title="item.title">{{
                      item.title
                    }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="ml-4" v-if="item.status == 'locked'">
                  <hb-icon v-if="item.status == 'locked'" style="margin-bottom: 5px; margin-left: 26px;"  :small="true" :color="'#637381'">mdi-lock</hb-icon>
                </v-list-item-action>
              </v-list-item>
            </router-link>
          </template>
          <router-link :to="item.to" v-if="item.status !== 'locked'">
            <v-list-item
              class="list-item-child"
              :key="b"
              v-for="(itemChild, b) in item.items"
              v-bind:class="[
                itemChild.tabName === tab.tabName ? 'activeClass' : 'notActive'
              ]"
              @click="openModal(itemChild)"
            >
              <v-list-item-action
                class="ma-0 ml-1 mr-1"
                @click="activeLinkTab(itemChild)"
                :class="[
                  itemChild.status == 'locked' ? 'disableClass' : 'enableClass'
                ]"
              >
                <hb-icon v-if="itemChild.status == 'complete'" :small="true" color='#02AD0F'>mdi-check-circle-outline</hb-icon>
                <hb-icon v-if="itemChild.status == 'locked'" :color="'#637381'" style="margin-bottom: 5px;" :small="true" >mdi-lock</hb-icon>
              </v-list-item-action>
              <v-list-item-content
                @click="activeLinkTab(itemChild)"
                :class="[
                  itemChild.status == 'locked' ? 'disableClass' : 'enableClass'
                ]"
                Vertical-align="center"
              >
                <v-list-item-title>
                  <span class="hb-default-font-size" :title="itemChild.title">{{
                    itemChild.title
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>
        </router-link>
      </template>
    </v-list>
    <dependency-modal
      v-if="dialog"
      :dependencyList="dependencyList"
      :title="title"
      @closeModal="closeModal"
    ></dependency-modal>
  </v-navigation-drawer>
</template>
<script>
import DependencyModal from "../DependencyModal/Index.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Navigation",
  props: ["drawer", "menus"],

  data() {
    return {
      getRoute: this.$router.history.current.path,
      dialog: false,
      dependencyList: [],
      title: "",
      panel: false
    };
  },
  mounted() {
    if (this.$route.name === "corporate") {
      this.panel = true;
    }
  },
  watch: {
    $route() {
      if (this.$route.name === "corporate") {
        this.panel = true;
      }
    }
  },
  components: {
    DependencyModal
  },
  computed: {
    ...mapGetters({
      tab: "onboardingTabsStore/getActiveTab",
      propertyStatus: "onboardingTabsStore/getPropertySettingStatus",
      propertyTabs: "onboardingTabsStore/getPropertyTabs",
      onboardingData: "onboardingTabsStore/getOnboardingData",
    }),
    disableCorporate() {
      if(this.menus.corporate.status == 'locked') {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    activeLinkTab(tabData) { 
      if (this.onboardingData && this.onboardingData.propertyData.length &&
        tabData.tabName == "property" &&
        this.propertyStatus == "complete"
      )
        this.$store.dispatch(
          "onboardingTabsStore/setActiveTab",
          this.propertyTabs["TaxRates"]
        );
      else this.$store.dispatch("onboardingTabsStore/setActiveTab", tabData);
     
    },
    openModal(data) {
      if (data.status === "locked") {
        this.dialog = true;
        this.dependencyList = data.dependsOn;
        this.title = data.title;
      }
    },
    closeModal() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.mt-65 {
  margin-top: 65px;
}
.ml-60 {
  margin-left: 60px;
}

.v-list-item__title {
  font-size: 14px !important;
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: break-spaces !important;
}

.list-item-child:hover {
  background: #e0f5f5;
}
.activeClass {
  background: #e0f5f5 !important;
  color: #101318;
}

.notActive {
  background: #ffffff;
  color: #637381;
}

.disableClass {
  pointer-events: none;
  color: #637381;
}
.enableClass {
  cursor: pointer;
}

.v-list-group--active > .v-list-group__header {
  background: #e0f5f5;
}
.v-list-group--active > .v-list-group__header span,
.v-list-group--active > .v-list-group__header i {
  color: black !important;
  font-weight: 400 !important;
}
a {
  text-decoration: none;
}
/* need to change z index in mobile view */
.navigation {
  z-index: 0 !important;
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  .navigation {
  z-index: 2 !important;
} */
/* } */
</style>