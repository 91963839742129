<template>
  <v-navigation-drawer
    class="pa-0 app-navigation "
    :class="{ isMobile: $vuetify.breakpoint.xs }"
    :width="$vuetify.breakpoint.smAndUp ? 60 : '100%'"
    clipped
    disable-resize-watcher
    hide-overlay
    app
    elevation-1
    mobile-breakpoint="1"
    flat
  >
    <v-layout
      :column="$vuetify.breakpoint.smAndUp"
      :fill-height="$vuetify.breakpoint.smAndUp"
      :row="$vuetify.breakpoint.xs"
      :wrap="$vuetify.breakpoint.xs"
    >
      <v-list
        :row="$vuetify.breakpoint.xs"
        :wrap="$vuetify.breakpoint.xs"
        class="mt-sm-1"
        :class="{ isMobile: $vuetify.breakpoint.xs }"
        style="padding-left: 9px"
        dense
      >
        <router-link :to="'/onboarding'">
          <v-list-item
            class="my-sm-1 pr-1"
            :style="
              $vuetify.breakpoint.xs ? 'padding-left:0;' : 'padding-left:0px;'
            "
          >
            <hb-btn
              icon
              tooltip="Home"
              @click="setActive()"
              active-state-on
              :active="true"
            >
              mdi-home
            </hb-btn>
          </v-list-item>
        </router-link>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
export default {
  methods: {
    setActive() {
      this.$store.dispatch("onboardingTabsStore/setActiveTab");
    }
  }
};
</script>

<style scoped>
.app-navigation {
  z-index: 1 !important;
}
</style>
