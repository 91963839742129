<template>
<div>
  <v-expansion-panels v-model="panel" multiple class="hb-expansion-panel">
    <v-expansion-panel class="mt-0">
      <v-expansion-panel-header class="hb-default-font-size pt-4 pb-5 px-4">
        <v-col cols="12" class="pa-0 ma-0">
          <span class="hb-larger-font-size font-weight-medium" >State Specific Documents</span >
        </v-col>
      </v-expansion-panel-header>
      <v-divider></v-divider>
      <v-expansion-panel-content class="pa-0 ma-0" elevation="2">
        <hb-data-table
          :headers="headers"
          :items="items"
          :loading="isFetching"
          loading-text="Loading... Please wait"
          disable-pagination
          hide-default-header
          hide-default-footer
        >
          <template v-slot:item.name="{ item }" >
            <v-row align="center" class="doc-name">
                 <span class="checkbox ml-2" :class="((item.status === 'downloaded') || (item.status === 'confirmed')) ? 'clickable-cursor' : 'not-allowed-cursor'" @click="onSelectDocumentCheckbox(item)">
                    <input type="checkbox" class="w-checkbox-input clickable-cursor" v-model="item.selected" />
                    <label :class="((item.status === 'downloaded') || (item.status === 'confirmed')) ? 'clickable-cursor' : 'not-allowed-cursor'"></label>
                </span>
              <label>{{ item.label }}</label >
              <span v-if="!item.overrides_doc_id">
                <hb-icon color='#02AD0F' small="true" >mdi-check-decagram</hb-icon>
                <label>Tenant Pro-Doc</label>
                <label class="ml-1" style="color:#a94442" v-if="item.required == 1">*</label>
              </span>
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu close-on-click  close-on-content-click offset-y class="d-inline-block" >
              <template v-slot:activator="{ on, attrs }">
               <span>
                <hb-btn v-if="!item.overrides_doc_id" color="secondary" @click="onDownloadDocument(item)" class="download_and_review_btn" small>
                  <v-progress-circular v-if="item.isDownloading" indeterminate color="primary" :size="15" :width="2" class="mr-1"></v-progress-circular>
                  Download & Review
                </hb-btn>   
                <a v-if="item.overrides_doc_id" @click="onDownloadDocument(item)" class="replaced_download" small>
                  <v-progress-circular v-if="item.isDownloading || item.isDeleting" indeterminate color="primary" :size="15" :width="2" class="mr-2"></v-progress-circular>
                  {{ overrideDocument(item) }}
                </a>            
               </span>
                <span v-bind="attrs" v-on="on" class="d-inline-block">
                  <hb-btn icon active-state-off>
                    mdi-dots-vertical
                  </hb-btn>
                </span>
              </template>
              <v-list>
                 <v-list-item v-if="!item.overrides_doc_id" :ripple="false"  @click="uploadDocumentDialog_open(item)">
                  <v-list-item-title :ref='item.id' class="upload-document">Replace with my own document</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.overrides_doc_id" :ripple="false"  @click="deleteDocument(item)">
                  <v-list-item-title :ref='item.id' class="upload-document">Use Tenant Pro document</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </hb-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</div>
</template>
<script>
import { EventBus } from '../../../../EventBus';
import api from '../../../../assets/api.js';
import { mapGetters } from 'vuex';
import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
export default {
  mixins: [notificationMixin],
  data() {
    return {
      isFetching: false,
      dialog: false,
      success: false,
      notificationName: "",
      headers: [ { text: "", value: "name" }, {   text: "",  value: "actions", align: "right", sortable: false, width: "10px", fixed: true, },],
      items: [],
      panel: [0],  // expansion panel default open
      doc_type:'state_specific',
      canConfirm: false,
    };
  },
  computed:{
        ...mapGetters({
               onboardingData: "onboardingTabsStore/getOnboardingData"
        }),
    },
  created() {
    this.fetchData();
  },
  mounted(){
  // Get event from App footer checkbox
   EventBus.$on('get_documentLibrary_data',this.checkForData);
  },
  methods: {
    fetchData(){
       if(this.onboardingData && this.onboardingData.propertyData.length){
        this.isFetching = true;
        api.get(this, api.ONBOARDING + 'documents?type=state&state='+this.onboardingData.property.Address.state).then(d => {
          if (d.documentList && d.documentList.length) {
            // Added extra fields
            d.documentList.forEach((entry,i) => {
              entry.index = i;
              entry.selected = (entry.status === 'confirmed') ? true:false;
              entry.isDownloading = false;
              entry.isDeleting = false;
            });
            this.items = [...d.documentList];
          }
          this.isFetching = false;
        }).catch(err => {
            this.isFetching = false;
            this.showMessageNotification({ description: err });
        });
       }
      
    },
    uploadDocumentDialog_open(item){
      this.$emit("openUploadDocumentDialog", item, 'state');
    },
    onSelectDocumentCheckbox(item) {
        if (['downloaded', 'confirmed'].includes(this.items[item.index].status)) {
            this.items[item.index].selected = !this.items[item.index].selected;
            this.items[item.index].status = this.items[item.index].selected ? 'confirmed' : 'downloaded';
            this.checkRequiredDocument();
            this.$emit("onSelectDocumentCheckbox", this.items[item.index], 'stateSpecificDocuments');
        } else {
          this.showMessageNotification({ description: "In order to check the checkbox and confirm, Please download and review the document." });
        }
    },
    onDownloadDocument(item) {
      this.items[item.index].isDownloading = true;
      this.items[item.index].status = 'downloaded';
      this.$emit("onDownloadDocument", this.items[item.index], 'stateSpecificDocuments');
    },
    checkForData() {
      this.checkRequiredDocument();
      EventBus.$emit("receive_documentLibrary_data", {canConfirm : this.canConfirm, message : ' Please download, review and confirm Rental Agreement document / Lease Agreement document to confirm the Document library.'});
    },
    checkRequiredDocument(){
      let countForRequiredDocument = 0
      let countForSelectedDocument = 0
         this.items.forEach((entry) => {
           if (entry.required){ 
             countForRequiredDocument++;
             if (entry.selected) {
               countForSelectedDocument++;
             }

           } 

          });
          this.canConfirm = (countForRequiredDocument === countForSelectedDocument)
    },
    deleteDocument(item) {
      this.items[item.index].isDeleting = true;
      this.$emit("onDeleteDocument", this.items[item.index], 'stateSpecificDocuments');
    },
    overrideDocument(item) {
      let timestamp = '';
      let file = '';
      let extension = '';
      if (item && item.override_document_name) {
        timestamp = item.override_document_name.split('_').pop();
        file = item.override_document_name.replace('_' + timestamp, '');
        extension = item.override_document_name.split('.').pop();
      }
      return file + '.' + extension;
    }
  },

  destroyed(){
       EventBus.$off('get_documentLibrary_data');
   }
};
</script>
<style lang="scss" scoped>
  .replaced_download {
    text-decoration: none;
    margin-right: 10px;
   font-size: 14px;
    color: #306FB6;
  }
  .download_and_review_btn {
    color: black !important;
    text-decoration: none;
    margin-right: 10px;
  }

  .v-application .d-inline-block {
      display: inline-block !important;
      margin-right: -8px !important;
  }
  .upload-document{
    cursor: pointer;
  }
  .custom-checkbox{
    margin-top: -4px !important;
  }
  .theme--light.v-expansion-panels.hb-expansion-panel .v-expansion-panel--active > .v-expansion-panel-header {
      max-height: 50px;
      min-height: 50px;
  }
  .doc-name{
    color: black;
    font-size: 14px;
  }
  .not-allowed-cursor {
      cursor: not-allowed;
  }
</style>
