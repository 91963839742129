<template>
  <div>
    <v-expansion-panels multiple class="hb-expansion-panel">
      <v-expansion-panel class="mt-0">
        <v-expansion-panel-header class="hb-default-font-size pt-5 pb-5 px-4">
          <v-col cols="12" class="pa-0 ma-0">
            <span class="hb-larger-font-size font-weight-medium" >Other Documents (Any additional documents you would like to add)</span >
            <hb-btn
              color="secondary"
              small="small"
              class="add-document-btn"
              @click="uploadDocumentDialog_open()"
            >
              Add Document
            </hb-btn>
          </v-col>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content class="ma-0" elevation="2">
          <hb-data-table
            :headers="headers"
            :items="items"
            :loading="isFetching"
            loading-text="Loading... Please wait"
            disable-pagination
            hide-default-header
            hide-default-footer
          >
            <template v-slot:item.name="{ item }">
              <v-row align="center">
                <label class="ml-4 doc-name">{{ item.subtype }}</label>
              </v-row>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-progress-circular v-if="item.isLoading" indeterminate color="primary" :size="15" :width="2" class="mr-2" ></v-progress-circular>
              <a class="mr-2 other-document-label" small>{{ item.name }}</a>
              <v-menu
                close-on-click
                close-on-content-click
                offset-y
                class="d-inline-block"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="d-inline-block">
                    <hb-btn icon active-state-off>
                      mdi-dots-vertical
                    </hb-btn>
                  </span>
                </template>
                <v-list class="menu-list other-document-actions">
                  <v-list-item :ripple="false" class="menu-list-item" @click="onDownloadDocument(item)" >
                    Download
                  </v-list-item>
                  <v-list-item :ripple="false" class="menu-list-item" @click="deleteDocument(item)" >
                    Delete
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </hb-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import api from "../../../../assets/api.js";
import { EventBus } from '../../../../EventBus';
import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
export default {
  mixins: [notificationMixin],
  data() {
    return {
      isFetching: false,
      dialog: false,
      success: false,
      notificationName: "",
      headers: [
        { text: "", value: "name" },
        { text: "", value: "actions", align: "right", sortable: false, width: "10px", fixed: true, },
      ],
      items: [],
      includeFiles: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isFetching = true;
      api .get(this, api.ONBOARDING + "documents?type=other") .then((d) => {
          if (d.documentList && d.documentList.length) {
            // Added extra fields
            d.documentList.forEach((entry, i) => {
              entry.index = i;
              entry.selected = entry.status === "confirmed" ? true : false;
              entry.isLoading = false;
            });
            this.items = [...d.documentList];
          } else if (d.documentList && d.documentList.length == 0) {
            this.items = [];
          }
          this.isFetching = false;
        })
        .catch((err) => {
          this.isFetching = false;
          this.showMessageNotification({ description: err });
        });
    },
    uploadDocumentDialog_open() {
      this.$emit("openUploadDocumentDialog", {}, "other");
    },
    onDownloadDocument(item) {
      this.items[item.index].isLoading = true;
      this.$emit("onDownloadDocument", item, "otherDocuments");
    },
    deleteDocument(item) {
      this.items[item.index].isLoading = true;
      this.$emit("onDeleteDocument", item, "other");
    },
  },
};
</script>

<style lang="scss" scoped>
  .add-document-btn {
    float: right;
    margin-right: 10px;
  }
  .download_and_review_btn {
    color: black !important;
    text-decoration: none;
    margin-right: 10px;
  }

  .v-application .d-inline-block {
    display: inline-block !important;
    margin-right: -8px !important;
  }
  .upload-document {
    cursor: pointer;
  }
  .custom-checkbox {
    margin-top: -4px !important;
  }
  .theme--light.v-expansion-panels.hb-expansion-panel
    .v-expansion-panel
    > .v-expansion-panel-header {
    max-height: 50px;
    min-height: 50px;
  }
  .menu-list-item {
    height: 27px !important;
    min-height: 27px;
  }
  .hb-larger-font-size {
    line-height: 27px;
  }
  .doc-name {
    color: black;
    font-size: 14px;
  }
  .v-menu__content {
    z-index: 101 !important;
  }
  .other-document-actions {
    cursor: pointer;
  }
  .other-document-label {
    font-size: 14px;
    color: #306fb6;
  }
</style>
