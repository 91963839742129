<template>
  <div>
    <v-card elevation="0" class="mb-5 pr-1">
      <div class="mt-2" v-if="onboardingData && onboardingData.propertyData.length">
        <hb-notification
        v-if="tab.tabName == 'property' && onboardingPercentage >= 50"
        type="success"
        notDismissable
        >
        {{corporateSuccessMessage}}
       </hb-notification>
      </div>
      <div class="mt-4 mx-auto">
        <v-expansion-panels
          v-model="panel"
          class="panel-header-color-box"
          multiple
        >
          <v-expansion-panel id="accordion-video">
            <v-expansion-panel-header class="panel-header-color">
              <div class="d-flex align-center">
                <v-icon class="pr-2" style="color:#3D8FFF;">info</v-icon
                >{{ tab.helpText }}
              </div>
            </v-expansion-panel-header>
             <v-expansion-panel-content class="video-panel">
              <v-row class="text_row" v-if="tab.videoUrl && tab.content">
                <v-col cols="4" lg="4" class="pa-0">
                  <div :class="'wistia_embed videoFoam=true wistia_async_'+changeVideo" style='height:65%;width:28%;'></div>
                </v-col>
                <v-col cols="8" lg="8" class="pa-0 pl-5">
                  <div class="video-txt" v-html="tab.content"></div>
                </v-col>
              </v-row>
              <v-row class="container justify-center" justify="center" v-else> <span class="grey--text text-h5">We are still working on the full version of "Cocoon".</span></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      panel: [0],
      items: 1,
      changeVideo:"6lglpceuft",
    };
  },
  computed: {
    ...mapGetters({
      tab: "onboardingTabsStore/getActiveTab",
      companyName: "authenticationStore/getCompanyName",
      onboardingPercentage: 'onboardingTabsStore/getProgressPercentage',
      onboardingData: "onboardingTabsStore/getOnboardingData",
      companyStatus: "onboardingTabsStore/getCompanyStatus"
    }),
    corporateSuccessMessage() {
      if(this.companyStatus == 'launched') {
        return "Congratulations! You have completed all the Corporate Settings."
      } else {
        return "Congratulations! You have completed all the Corporate Settings. You can still go back and make changes to the corporate settings at anytime."
      }
    }
  },
  watch: {
    tab: function (val) {
        this.changeVideo = val.videoUrl
         
    },   
  },
  created() {
    var scripts = [
      "https://fast.wistia.com/assets/external/E-v1.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    
  }
};
</script>
<style lang="scss" scoped>
.mt-70 {
  margin-top: 70px;
}

.ml-200 {
  margin-left: 200px;
}

.panel-header-color {
  background: #e7f1ff;
  font-family: "Graphik Web";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #101318;
}
.panel-header-color-box {
  // box-shadow: inset 0px -1px 0px #dfe3e8;
  // border: 1.5px solid #02ad0f;
  border: 2px solid #7b61ff;
  box-sizing: border-box;
  border-radius: 4px;
}

/* custom scrollbar */
.video-txt::-webkit-scrollbar {
  width: 10px;
}
.video-txt::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.video-txt::-webkit-scrollbar-thumb {
  background: #888; 
}
.video-txt{
  overflow-y: auto;
  height: 200px;
  height: 210px;
  margin-right: -30px;
}
.video-panel{
  min-height:200px;
}
.div_video {
  height:65%;
  position:absolute;
  width:30%;
}
.text_row {
  margin: 1.5% 1.5% 0% 0%;
}
</style>
