<template>
  <div>
    <div v-if="isLoadedOnboarding">
    <div id="overlay"></div>
    <on-boarding-header
      v-on:toggle-drawer="drawer = !drawer"
      :drawer="drawer"
    ></on-boarding-header>
    <app-navigation />
    <navigation :drawer.sync="drawer" :menus.sync="links" />
    <notifications v-if="isAdmin"></notifications>
    <v-flex
      class="hb-flex"
      v-bind:class="[drawer ? 'view-drawer' : 'no-drawer']"
    >
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <app-header
          @toggle="rightDrawer = !rightDrawer"
            :rightDrawer="rightDrawer"
          ></app-header>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="!rightDrawer ? 12 : 9" class="pt-0">
          <VideoAccordion ></VideoAccordion>
          <slot  />
          <div
            v-bind:class="[drawer ? 'footer-view-drawer' : 'footer-no-drawer', rightDrawer ? 'footer-right-drawer' : 'footer-no-right-drawer',noRightDrawer ? 'footer-with-no-right-drawer' : '' ]"
          >
            <app-footer :rightDrawerFlag="rightDrawer"></app-footer>
          </div>
        </v-col>
        <v-col v-if="rightDrawer"
          cols="2"
          :class="{ 'd-none': !rightDrawer }"
        >
          <app-right-navigation
            style="margin-top: 145px;"
          ></app-right-navigation>
        </v-col>
      </v-row>
       <hb-global-notification
            v-model="successError"
            :type="successErrorType"
            :description="successErrorDescription"
            :list="successErrorList && successErrorList.length ? successErrorList : []"
            :title="successErrorTitle"
            :icon="successErrorIcon"
            :complete="successErrorComplete"
        >
            <span v-if="successErrorContent" v-html="successErrorContent"></span>
            <template v-slot:footer>
                <span v-if="successErrorFooter" v-html="successErrorFooter"></span>
            </template>
        </hb-global-notification>
    </v-flex>
    </div>
    <div v-else class="loading">
                <v-progress-circular  indeterminate color="primary" ></v-progress-circular>
          </div>
  </div>
</template>

<script>
import onBoardingHeader from "../components/includes/Onboarding/AppHeader/Index.vue";
import AppNavigation from "../components/includes/Onboarding/AppNavigation/MainNavigationLeft.vue";
import Navigation from "../components/includes/Onboarding/AppNavigation/LeftNavigation.vue";
import Onboarding from "../components/onboarding/Index.vue";
import VideoAccordion from "../components/includes/Onboarding/Accordion/Index.vue";
import Notifications from "../components/assets/Notifications.vue";
import AppFooter from "../components/includes/Onboarding/AppFooter/Index.vue";
import AppHeader from "../components/includes/Onboarding/AppSubHeader/Index.vue";
import AppRightNavigation from "../components/includes/Onboarding/AppNavigation/RightNavigation.vue";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '../EventBus.js';

export default {
  name: "OnBoading-layout",
  components: {
    onBoardingHeader,
    AppNavigation,
    Navigation,
    Onboarding,
    VideoAccordion,
    Notifications,
    AppFooter,
    AppHeader,
    AppRightNavigation
  },
  data() {
    return {
      drawer: true,
      rightDrawer: false,
      successError: false,
      successErrorType: '',
      successErrorDescription: '',
      successErrorList: [],
      successErrorTitle: '',
      successErrorIcon: '',
      successErrorComplete: false,
      successErrorContent: '',
      successErrorFooter: ''
    };
  },
  watch: {
    successError(){
      if(this.successError){
        setTimeout(() => {
        this.closeSuccessError();
        }, 2000);
      }
    },
    tab(value){
       this.checkMenu();
    },
    '$route' (to, from) {
      this.checkMenu();     
      let tab = (this.canGotoTaxRate() && this.tab.tabName == 'property') ? this.propertyTabs['TaxRates'] : this.tab;
      this.$store.dispatch("onboardingTabsStore/setActiveTab",tab);
    },
    onboardingPercentage (value,oldVal) {
      if (value >= 50 && this.onboardingData.propertyData.length) {     
        if(this.propertyStatus == 'locked'){
            this.$store.dispatch( "notificationsStore/setNotificationWithButton", { id: this.$options.name, text: 'You’ve completed all your corporate settings and are ready to begin setting up your property', subtext: this.onboardingData.propertyData[0].name} );
            this.$store.dispatch("onboardingTabsStore/updateOnboardingTabStatus", { type: 'mainmenu', screenname : 'property', status : 'in-progress' });
         }      
      }
       let tab = (this.canGotoTaxRate() && this.tab.tabName == 'property') ? this.propertyTabs['TaxRates'] : this.tab;
      this.$store.dispatch("onboardingTabsStore/setActiveTab",tab); 
    }
  },
  computed: {
    ...mapGetters({
      menuStatusData: "onboardingTabsStore/getOnboardingTabStatus",
      links: "onboardingTabsStore/getOnboardingTabs",
      isAdmin: "authenticationStore/isAdmin",
      tab: "onboardingTabsStore/getActiveTab",
      propertyStatus : 'onboardingTabsStore/getPropertySettingStatus',
      onboardingPercentage: 'onboardingTabsStore/getProgressPercentage',
      propertyTabs : 'onboardingTabsStore/getPropertyTabs',
      onboardingData: "onboardingTabsStore/getOnboardingData",
      isLoadedOnboarding: 'onboardingTabsStore/isLoadedOnboarding'
    }),
    noRightDrawer(){
      if( this.drawer == false &&  this.rightDrawer == true){
        return true;
      }
    }
  },
  mounted() {    
    if(this.$route.name === 'onBoarding'){
      this.$store.dispatch("onboardingTabsStore/setActiveTab"); 
    }else if(this.$route.name === 'corporate'){
      this.$store.dispatch("onboardingTabsStore/setActiveTab", this.links['corporate']); 
    }else if(this.$route.name === 'property'){
      let tab = this.canGotoTaxRate() ? this.propertyTabs['TaxRates'] : this.links['property'];
      this.$store.dispatch("onboardingTabsStore/setActiveTab",tab); 
    }
  },
  methods : {
    checkMenu(){      
      if (this.tab.tabName !== "property" && this.$route.name === 'property' ) {
        this.rightDrawer = true;
      } else {
        this.rightDrawer = false;
      }
    },
    canGotoTaxRate(){
      if(this.$route.name !== 'property'){
        return false;
      }else{
        return this.onboardingData && this.onboardingData.propertyData.length && this.propertyStatus == 'complete';
      }
    },
  }
};
</script>
<style lang="scss" >
.view-drawer {
  margin-left: 260px;
  padding: 65px 18px 10px;
  // overflow-y: scroll;
  margin-bottom: 30px;
}
.no-drawer {
  margin-left: 65px;
  padding: 65px 18px 10px;
  overflow-y: scroll;
}

.footer-view-drawer {
  position: fixed;
  bottom: 0;
  margin-left: -5px;
  z-index: 8;
  width: calc(100% - 285px);
}
.footer-no-drawer {
  position: fixed;
  bottom: 0;
  margin-left: -12px;
  width: calc(100% - 85px);
  z-index: 100;
}

.footer-right-drawer{
  position: fixed;
  bottom: 0;
  margin-left: -5px;
  z-index: 8;
  width: calc(100% - 505px);
}

.loading {
    height: 100vh;
    vertical-align: middle;
    margin: 0 auto;
    .v-progress-circular {
        position: relative;
        top: 40%;
        left: 40%;
    }
}
a {
  text-decoration: none !important;
}

.footer-with-no-right-drawer{
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: calc(100% - 305px);
  margin-left:-10px;
}

.loading {
    height: 100vh;
    vertical-align: middle;
    margin: 0 auto;
    .v-progress-circular {
        position: relative;
        top: 40%;
        left: 40%;
    }
}
</style>
