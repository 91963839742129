<template>
    <div class="discount">
      <Discount :isOPS="true" ref="discountLibrary"></Discount>
    </div>
</template>

<script>
import Discount from '../../../settings/Promotions/Index.vue';

export default {
 components: {
   Discount
 },
};
</script>

<style lang="scss">
  .discount {
     margin-top: -10px;
  }
</style>