<template>
    <div>
        <hb-modal v-model="dialog" show-help-link size="large" :title="activeGroup.id ? 'Edit Workflow' : 'Add New Automation Process'" @close="closeWindow">
            <template v-slot:content>                
                <hb-form label="Process Name" required>
                    <HbTextField
                        v-validate="'required'"
                        v-model="activeGroup.name"
                        label="Name"
                        id="processName"
                        data-vv-name="processName"
                        data-vv-as="Process Name"
                        data-vv-scope="workflow"
                        name="processName"
                        single-line
                        autofocus
                        :error=" errors.collect('workflow.processName').length > 0 "
                    ></HbTextField>
                
                </hb-form>


                <hb-form label="Properties" >
                    Select all the properties where you want to use this automation.
                    <br /><br />
                    
                    <HbCheckbox
                        label="Select All"
                        v-model="selectAll"
                        @change="toggleProperties"
                    ></HbCheckbox>
                    <div v-if="dialog">
                        <HbCheckbox
                            v-for="(p, i) in facilityList"
                            :key="p.id"
                            :value="p.id"
                            :label="p.details"
                            v-model="workflow_properties[i].selected"
                        ></HbCheckbox>
                    </div>
                    
                </hb-form>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="checkPropertyDelinquency">Save</hb-btn>      
            </template>
        </hb-modal>
        <hb-modal
            :title="modalText.title"
            confirmation
            size="medium"
            v-model="delinquencyExists"
            @close="$emit('closeModal')"
            show-help-link
        >
            <template v-slot:content>
                <div class="px-6 py-2">
                    <p>{{modalText.body}}
                        <div class="pb-4">
                            <ul style="list-style: disc">
                                <li v-for="(item, i) in enrolledDelinquencies" :key="i">{{item.property_name}} assigned to {{item.trigger_group_name}}</li>
                            </ul>
                        </div>
                    <p>This may impact the delinquency schedule of the existing delinquent tenants.</p>
                    <p>Would you like to continue? </p>
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn primary @click="save">Proceed</hb-btn>
            </template>
        </hb-modal>    
    </div>
 
</template>

<script text="text/babel">
import { mapGetters, mapActions } from "vuex";
import api from "../../../assets/api.js";
import Status from '../../includes/Messages.vue';
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
export default {
    name: "AddEditWorkflow",
    components: {
        Status
    },
    mixins: [ notificationMixin ],
    data: () => ({
        workflow_properties: [],
        activeGroup: {},
        selectAll: false,
        delinquencyExists: false,
        enrolledDelinquencies: []
    }),
    props: ["group", "value", "facilityList"],
    computed: {
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        modalText(){
            return {
                title: "Update Delinquency",
                body: `The ${this.enrolledDelinquencies.length > 1 ? 'properties are' : 'property is'} already assigned to the workflow${this.enrolledDelinquencies.length > 1 ? 's' : ''} as mentioned below. 
                    Moving forward would reassign the ${this.enrolledDelinquencies.length > 1 ? 'properties' : 'property'} to ${this.activeGroup.name} workflow.`
            }
            
        }
    },
    created() {
        if(this.group){
            this.activeGroup = JSON.parse(JSON.stringify(this.group)); 
        }
      
      this.facilityList.forEach(p => {
            let selected = this.activeGroup.Properties.find(agp => agp.id === p.id);
            selected = selected ? true : false;
            this.workflow_properties.push({id: p.id, selected})
        }) 
    },
    mounted() {
    
    },
    methods: {
        ...mapActions({
            getGroupById: "triggersStore/getGroupById",
            addGroup: "triggersStore/addGroup",
            editGroup: "triggersStore/editGroup"
        }),
        toggleProperties(selectAll){
            console.log("selectAll", selectAll)
            this.workflow_properties = [];
            if (selectAll){
                
                this.workflow_properties = this.facilityList.map(val => ({id: val.id, selected: true})); 
            }
            else{
                this.workflow_properties = this.facilityList.map(val => ({id: val.id, selected: false})); 
            }

        }, 

        async checkPropertyDelinquency() {
            let status = await this.$validator.validateAll("workflow");
            if (!status) {
                return;
            }
            
            let response, selected_props = []
            try {
                if(this.workflow_properties.length) {

                    this.workflow_properties.forEach(val => {
                        if(val.selected) selected_props.push(val.id)
                    })

                    let data = {
                        property_ids: selected_props,
                        trigger_group_id: this.activeGroup.id
                    }

                    response = await api.post(this, api.PROPERTIES + "delinquency-workflows/", data);
                }

                if(response && response.length) {
                    this.enrolledDelinquencies = response;
                    this.delinquencyExists = true
                }
                else {
                    await this.save();
                }
            } catch(err){
                this.showMessageNotification({type: 'error', description: err});
            }
        },

        async save() {

            let selected_props = [];
            let deselected_props = [];
            this.workflow_properties.forEach(val => {
                if(val.selected) { 
                    selected_props.push({id: val.id});
                } else {
                    deselected_props.push({id: val.id});
                } 
            });

            let data = {
                id: this.activeGroup.id ? this.activeGroup.id : 0,
                name: this.activeGroup.name,
                description: "",
                Properties:
                    selected_props.length &&
                    selected_props,
                removeProperties:
                    deselected_props.length &&
                    deselected_props
            };

            try {
                if(this.activeGroup.id) {
                   await api.put(this, api.TRIGGERS + "groups/" + this.activeGroup.id, data)
                } else {
                    await api.post(this, api.TRIGGERS + "groups/", data);
                }
                this.$emit('refresh', this.workflow_properties);
                this.closeWindow();
                this.showMessageNotification({type: 'success', description: "Changes have been saved successfully"});

            } catch(err){
                this.showMessageNotification({type: 'error', description: err});
            }
            
            
        },
        closeWindow() {
            this.activeGroup = {
                id: '', 
                name: '',
                description: '',
                Properties: []
            }
            this.$emit("close");
        }
    },
    watch:{
        group(val, oldVal){
            this.activeGroup = JSON.parse(JSON.stringify(this.val)); 
        }
    }
};
</script>

<style lang="scss" scoped>
</style>

