<template>
    <div class="fees-proile">
      <FeesProfiles :isOPS="true" level="Property" ref="FeesProfiles"></FeesProfiles>
    </div>
</template>

<script>
import FeesProfiles from '../../../settings/Fees/Index.vue';
export default {
 components: {
   FeesProfiles
 },
};
</script>

<style lang="scss">
  .fees-proile {
     margin-top: -10px;
     margin-right: 5px;
  }
</style>