<template>
  <div class="px-4">
    <div class="progress-bar">
      <label class="white--text"
        >Onboarding Progress:
        <span> {{ value }}%</span>
      </label>
      <div class="pt-1">
        <v-progress-linear
          class=""
          :value="value"
          :color="color"
          :background-color="bgColor"
          rounded
        >
        </v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#FB4C4C',
    },
    bgColor: {
      type: String,
      default: '#DFE3E8',
    },
    
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
    .progress-bar {
    width: 188px;
    }
</style>