<template>
    <div class="LeaseTemplates">
      <LeaseTemplates :isOPS="true" level="Property" ref="leaseConfigurationRef"></LeaseTemplates>
    </div>
</template>

<script>
import LeaseTemplates from '../../../settings/PropertyTemplates/Index.vue';
export default {
 components: {
   LeaseTemplates
 },
};
</script>

<style lang="scss">
  .LeaseTemplates {
     margin-top: -10px;
     margin-right: 5px;
  }
</style>
