<template>
  <v-card elevation="0.5" class="pa-3 mt-2">
    <div class="pl-1">
      <v-row class="mt-1 ma-0 pa-0" md="12">
        <v-col :md="rulesCategoriesWidth" class="pl-0">
          <v-select
            :items="ruleMainCategories"
            :disabled="oldRule && oldRule.attribute != ''"
            class="pa-0 mt-0"
            flat
            attach
            hide-details
            label="Category 1"
            v-model="new_rule.attribute"
          ></v-select>
        </v-col>
        <v-col
          :md="rulesCategoriesWidth"
          v-if="new_rule.attribute == 'price'"
          class="pa-0 pt-3"
        >
          <v-select
            :items="Object.values(rule_comparison)"
            label="Operator"
            class="pa-0 mt-0"
            flat
            hide-details
            v-model="new_rule.comparison"
          >
          </v-select>
        </v-col>
        <v-col :md="new_rule.attribute == 'price' ? rulesCategoriesWidth : 7" class="pl-2">
          <v-select
            v-if="new_rule.object && new_rule.attribute && rule_values.length"
            class="pa-0 mt-0"
            v-model="new_rule.values"
            :items="rule_values"
            label="Category 2"
            multiple
            item-text="name"
            item-value="value_label" 
            return-object
            flat
            hide-details
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="toggleSelectAll"
              >
                <v-list-item-action>
                  <v-icon :color="new_rule.values.length > 0 ? '#757575' : ''">
                    {{ category2Icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">
                Multiple
                {{ new_rule.object === "property" ? "Properties" : "Units" }}
                Selected ({{ new_rule.values.length }})
              </span>
            </template>
          </v-select>
          <!-- In case of price -->
          <v-text-field
            class="pa-0 mt-0"
            flat
            hide-details
            id="rule_value"
            name="rule_value"
            label="Amount"
            type="text"
            v-if="new_rule.attribute && !rule_values.length"
            v-model="new_rule.values"
          />
        </v-col>
      </v-row>
    </div>
    <v-row
      md="2"
      class="mt-4 ma-0 pa-0"
      v-if="new_rule.object && new_rule.attribute"
    >
      <hb-btn :disabled="disableSaveRule" @click="setRule" color="secondary" small>Save Rule</hb-btn>
      <a class="hb-link ml-3 mt-1" @click="cancelRule">Cancel</a>
    </v-row>
  </v-card>
</template>

// Adding or editing promotion rule
<script>
import { PROMOTION } from "./Constants.js";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '../../../EventBus.js';

export default {
  name: "EditPromotionRule",

  data() {
    return {
      new_rule: {
        object: "unit",
        attribute: "",
        comparison: "",
        values: ""
      },
      rule_values: [],
      rule_comparison : ""
    };
  },

  created() {
    this.PROMOTION = PROMOTION;

    if (this.oldRule) {
      EventBus.$emit("displayAddNewRuleOption", false);
      if(this.oldRule.attribute === 'price') {
        this.new_rule = {
          ...this.oldRule,
          values: this.oldRule.values.length && this.oldRule.values[0].value || 0
        }
      } else {
        this.new_rule = { ...this.oldRule }
      }
    }
  },

  props: {
    promotionRules: {
      type: Array,
      default: []
    },
    oldRule: {
      type: Object
    },
    addPromotionRule: {
      type: Function
    },
    updatePromotionRule: {
      type: Function
    },
  },

  computed: {
    ...mapGetters({
      unit_sizes: "filterStore/unit_size",
      unit_categories: "filterStore/unit_category",
      categories: "settingsStore/unitMixes",
      floors: "settingsStore/unitFloors"
    }),

    selectedAllCategories2 () {
      return this.new_rule.values.length === this.rule_values.length;
    },

    selectedSomeCategories2 () {
      return this.new_rule.values.length > 0 && !this.selectedAllCategories2;
    },

    category2Icon () {
      if (this.selectedAllCategories2) return 'mdi-checkbox-marked'
      if (this.selectedSomeCategories2) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    rulesCategoriesWidth() {
      return this.new_rule.attribute == "price" ? "4" : "5";
    },

    ruleMainCategories() {
      if (this.promotionRules.length == 0) {
        return PROMOTION.RULE_ATTRIBUTES.unit;
      }

      const alreadySelectedCategories = this.promotionRules.map(
        (pr) => pr.attribute
      );

      const categoriesAvailable = PROMOTION.RULE_ATTRIBUTES.unit.map((r) =>
        alreadySelectedCategories.includes(r.value.toLowerCase())
          ? { ...r, disabled: true }
          : r
      );

      return categoriesAvailable;
    },

    disableSaveRule() {
      return (
        this.new_rule.object == "" || this.new_rule.values === "" || this.new_rule.values.length === 0
      );
    },
  },

  watch: {
    "new_rule.object"(val) {
      if (!val) this.rule_attributes = [];
      this.rule_attributes = PROMOTION.RULE_ATTRIBUTES[val.toLowerCase()];
      this.new_rule.attribute = "";
    },

    "new_rule.attribute"(val) {
      if (!val) {
        this.rule_values = [];
      } 

      /* Previously there used to be property rule object as well,
         Now there is only unit */
      switch (this.new_rule.object.toLowerCase()) {
        case "unit":
          if(!this.oldRule) {
            this.new_rule.values = [];
          }

          switch (val.toLowerCase()) {
            case "category":
              this.rule_values = this.categories.map(c => {
                return {
                  ...c,
                  value: c.id
                }
              });
              this.new_rule.comparison = "In";
              break;
            case "floor":
              this.rule_values.push(...this.floors);
              this.new_rule.comparison = "In";
              break;
            case "unit_category":
              this.rule_values = this.unit_categories.map(c => {
                return {
                  ...c,
                  value: c.id
                }
              });
              this.new_rule.comparison = "In";
              break;
            case "size":
              this.rule_values.push(...this.unit_sizes);
              this.new_rule.comparison = "In";
              break;
            case "price":
              this.rule_values = "";
              this.rule_comparison = PROMOTION.OPERATORS.EQUALITY;
              if(!this.oldRule) {
                this.new_rule.comparison = PROMOTION.OPERATORS.EQUALITY.greater.value;
              }
              break;
          }
      }
    },
  },

  methods: {
    setRule() {
      let comparison = "";
      switch (this.new_rule.comparison) {
        case "In":
          comparison = "=";
          break;
        case "Not In":
          comparison = "!=";
          break;
        default:
          comparison = this.new_rule.comparison; // In case of Price
          break;
      }

      let newRule = {
        object: this.new_rule.object,
        attribute: this.new_rule.attribute.toLowerCase(),
        comparison: comparison,
        values: [],
        allAvailableValues: this.rule_values,
      };
      
      // In case of price
      if (!Array.isArray(this.new_rule.values)) {
        newRule.values = [{
          value: this.new_rule.values,
          value_label: this.new_rule.values
        }]
      } else {
        this.new_rule.values.map((r) => {
          newRule.values.push({
            value: r.value || r.name,
            value_label: r.name || r.value_label
          }); 
        });
      }

      if(this.oldRule) {
        EventBus.$emit("updatePromotionRule", newRule);
        EventBus.$emit("promotionRuleEditing", '');
      } else {
        this.$emit("addPromotionRule", newRule);
      }

      this.resetNewRule();
    },

    cancelRule() {
      this.resetNewRule();
      EventBus.$emit("promotionRuleEditing", '');
      EventBus.$emit("displayAddNewRuleOption", false);
    },

    resetNewRule() {
      this.new_rule = {
        object: "unit",
        attribute: "",
        comparison: "",
        values: "",
      };
    },

    toggleSelectAll () {
      this.$nextTick(() => {
        if (this.selectedAllCategories2) {
          this.new_rule.values = [];
        } else {
          this.new_rule.values = this.rule_values.slice();
        }
      })
    },
  },
};

</script>