<template>
    <div class="taxprofile" >
        <Tax :level="showMenuType" ref="taxProfileRef" :isOPS="true"></Tax>
    </div>
</template>

<script>
import Tax from '../../../settings/Tax/Index.vue';
import { EventBus } from '../../../../EventBus';

export default {
    name:"TaxProfiles",
    components:{
        Tax
    },
    data(){
        return{
          showMenuType : 'Corporate',
        }   
    },
    mounted(){
     // Get event from App footer checkbox
     EventBus.$on('get_taxprofile_data',this.checkForData);
   },
   methods:{
    // Get event from App footer checkbox
    checkForData() {
      // Emit event to footer checkbox
      if (this.$refs.taxProfileRef) {
        let canConfirm = this.$refs.taxProfileRef.taxProfiles.length;
        EventBus.$emit("receive_taxprofile_data", {canConfirm : canConfirm});
      }
    },
 },
 destroyed(){
   EventBus.$off('get_taxprofile_data');
 }
}
</script>
<style lang="scss" scoped>
 .taxprofile {
    margin-top: 0px;
    max-height: 500px;
    margin-bottom: 75px;
  }
</style>