<template>
  <div>
    <div class="slide-out-section">
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
      <div class="interaction-category-header">
        <h3 class="interaction-heading">Admin Role</h3>
        <p></p>
      </div>
    </div>

    <div class="slide-out-section" v-if="admin.role_id">
      <v-row>
        <v-col cols="2">Role</v-col>
        <v-col v-show="editing">
          <v-select
            :items="roles"
            v-model="admin.role_id"
            item-value="id"
            item-text="name"
            v-validate="'required'"
          >
          </v-select>
        </v-col>
        <v-col v-show="!editing"> {{admin.Role.name}} </v-col>
      </v-row>
      <v-row >
        <v-col cols="2">Property Access</v-col>
        <v-col v-show="!editing" >
          <v-row v-if="admin.Properties.length" >
            <v-col cols="4" v-for="p in admin.Properties">{{p.name}}</v-col>
          </v-row>
          <div v-else>
            No properties selected
          </div>
        </v-col>
        <v-col v-show="editing" >
          <v-row no-gutters>
            <v-col cols="6" v-for="p in properties">

              <v-checkbox
                class="pa-0 ma-0"
                v-model="admin.Properties"
                :value="p"
                :label="p.name"
              >

              </v-checkbox>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>



    <div class="slide-out-section">
      <button v-show="!editing" @click="editing = true" class="w-button secondary-btn">Edit Permissions</button>
      <button v-show="editing" @click="editing = false" class="w-button tertiary-btn">Cancel</button>&nbsp;
      <button v-show="editing" @click="saveSettings" :disabled="isLoading($options.name)" class="w-button primary-btn">Save Settings</button>
      <span v-show="isLoading($options.name)" >
            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
        </span>
    </div>
  </div>

</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: "PermissionsSettings",
        data() {
            return {
                editing: false,
                loaded:false,
                lead:{
                    id: null
                },
                chosen:[],
                emails:[],
                texts:[],
                activity:[],
                convertSelector: '',
                id: null,
                admin: {},
                roles:[],

                properties:[]

            }
        },
        created(){
            this.id = this.contact_id;
            this.fetchData();
            this.fetchRoles();
            this.fetchProperties();
        },
        components:{
            Loader,
            Status,
            Dropdown,
            Datepicker
        },
        filters:{

        },
        methods: {

            fetchProperties(){
                api.get(this, api.PROPERTIES, {all: true}).then(r => {
                    this.properties = r.properties.map(p => {
                        return {
                            id: p.id,
                            name: p.name,
                            number: p.number,
                        }
                    });

                    
                });
            },
            fetchData(){
                api.get(this, api.ADMIN + this.id).then(r => {

                    this.admin = {
                        Role: r.admin.Role,
                        role_id: r.admin.role_id,
                        Properties: r.admin.Properties.map(p => {
                            return {
                                id: p.id,
                                name: p.name,
                                number: p.number,
                            }
                        })
                    }
                });
            },

            fetchRoles(){
                api.get(this, api.ROLES).then(r => {
                    this.roles = r.roles;
                });
            },


            saveSettings(){
                api.put(this, api.ADMIN + this.contact_id + '/role', this.admin).then(r => {
                    this.fetchData();
                    this.editing = false;
                    this.successSet(this.$options.name, "User permissions have been updated");
                });
            }

        },
        props:['contact_id'],
        watch:{
            contact_id(contact_id){
                this.id = contact_id;
                this.fetchData();
            }
        }
    }
</script>

<style scoped>

  .settings-form .w-form-label {
    display: block;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0px;

  }

  .bordered {
    border: 1px solid #f0f0f5;


  }
  .interaction-category-header label{
    margin-left: 10px;
  }
  .interaction-category-header{
    margin-bottom:10px;
  }

</style>
