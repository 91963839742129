<template>
    <hb-modal size="medium" confirmation :footerCancelOption="allow_to_delete_trigger ? true : false" :title="allow_to_delete_trigger ? 'Delete Stage or Action' : 'Can\'t delete due to dependency'" v-model="dialog" show-help-link @close="$emit('close')">
        <template v-slot:content>
            <div v-if="allow_to_delete_trigger" class="py-4 px-6">
                {{ AUTOMATION.DELETE_STAGE_WARNING_TEXT }}
            </div>
            <div v-else class="py-4 px-6">
                {{ AUTOMATION.DELETE_DEPENDENCIES_TEXT }}
            </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn v-if="allow_to_delete_trigger" color="destructive" :disabled="disabled" :loading="disabled"
                @click="$emit('confirmDeleteStage')">Delete</hb-btn>
            <hb-btn v-else color="primary" @click="$emit('close')">Okay</hb-btn>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
import AUTOMATION from '@/constants/automation.js';
export default {
    name: 'DeleteStageActionWarningModal',
    data() {
        return {
        };
    },
    async created() {
        this.AUTOMATION = AUTOMATION;
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    props: {
        allow_to_delete_trigger: {
            type: Boolean,
            default() {
                return true;
            }
        },
        value: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
}
</script>

<style scoped>
.sub_title,
.process {
    font-weight: 500;
}
</style>