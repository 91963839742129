<template>
    <div class="discount">
      <CoveragePlans level="Corporate" :isOPS="true" ref="coveragePlans"></CoveragePlans>
    </div>
</template>

<script>
import CoveragePlans from '../../../settings/InsuranceSettings.vue';

export default {
 components: {
   CoveragePlans
 },
};
</script>

<style lang="scss">
  .discount {
     margin-top: -10px;
  }
</style>