<template>
  <div>
    <v-row class="ma-0 pa-0 mb-4">
      <div
        class="horizontal-form-input"
        v-show="promotionRules && promotionRules.length"
      >
        <div
          class="param-row pl-0"
          v-for="(rule, ruleIndex) in promotionRules"
          :key="rule.attribute"
        >
          <v-card v-if="ruleEditing != rule.attribute" class="mx-0 px-5 mt-2" elevation="0.5" width="800">
            <v-row>
              <v-col md="11">
                <v-row>
                  <span class="pt-3 ml-3">
                    Space {{ getRuleAttributeText(rule.attribute).text }} is
                  </span>
                  <v-col md="8" class="ma-0 pa-0 pl-3">
                    <v-chip
                      @click="ruleEditing = rule.attribute"
                      @click:close="removeRule(rule.attribute)"
                      class="mt-2 mr-1 mb-2"
                      close
                      :color="chip.color"
                      normal
                      outlined
                      style="verticalalign: middle"
                    >
                    {{ 
                      rule.values && Array.isArray(rule.values) ? 
                      `All ${getRuleAttributeText(rule.attribute).text} selcted (${rule.values.length})`: 
                      getRuleValueDescription(rule.values || "", rule.attribute, rule.comparison)
                    }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <div v-else>
            <edit-promotion-rule
              :oldRule="rule"
              :promotionRules="promotionRules"
            />
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import { PROMOTION } from "./Constants.js";
import EditPromotionRule from "./EditPromotionRule.vue";
import { EventBus } from '../../../EventBus.js';

export default {
  name: "PromotionRules",

  data() {
    return {
      chip: {
        text_color: "#00848E",
        color: "#00848E",
      },
      ruleEditing: ''
    };
  },

  components: {
    EditPromotionRule
  },

  created() {
    this.PROMOTION = PROMOTION;
    EventBus.$on('promotionRuleEditing', this.updateRuleEditing);
  },

  destroyed () {
    EventBus.$off('promotionRuleEditing', this.updateRuleEditing);
  },

  props: {
    promotionRules: {
      type: Array,
      default: [],
    },
    removeRules: {
      type: Function
    }
  },

  methods: {
    getRuleAttributeText(attribute) {
      return this.PROMOTION.RULE_ATTRIBUTES.unit.find(
        (a) => a.value.toLowerCase() === attribute
      );
    },

    getRuleValueDescription(value, attribute, comparisonOperator) {
      if (attribute.toLowerCase() == "price") {
        let comparisonOperatorText = "";
        const equalityOperators = Object.keys(
          this.PROMOTION.OPERATORS.EQUALITY
        );

        for (let i = 0; i < equalityOperators.length; i++) {
          if (
            this.PROMOTION.OPERATORS.EQUALITY[
              equalityOperators[i]
            ].value.toLowerCase() == comparisonOperator
          ) {
            comparisonOperatorText = this.PROMOTION.OPERATORS.EQUALITY[
              equalityOperators[i]
            ].text;
            break;
          }
        }

        return `${comparisonOperatorText} ${value}`;
      } else {
        return value;
      }
    },

    removeRule(attribute) {
      this.$emit('removeRules', attribute);
    },

    updateRuleEditing(value) {
      this.ruleEditing = value;
    }
  },
};
</script>

<style scoped>
.chip-hover {
  background-color: "#00848E";
}
</style>