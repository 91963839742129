<template>
  <v-navigation-drawer
    absolute
    permanent
    width="220"
    class="fill-height mt-60"
    right
  >
    <router-link :to="'property'">
      <v-list-item
        class="list-item-child"
        :key="b"
        v-for="(itemChild, b) in links"
        v-bind:class="[
          (itemChild.title === tab.title && $route.name === 'property') ? 'activeClass' : 'notActive' 
        ]"
      >
        <v-list-item-action
          class="ma-0 ml-1 mr-1"
          :class="[
            itemChild.status == 'locked' ? 'disableClass' : 'enableClass'
          ]"
          @click="activeLinkTab(itemChild)"
        >
        <hb-icon v-if="itemChild.status == 'locked'" :small="true" :color="'#101318'">mdi-lock</hb-icon>
          <hb-icon v-if="itemChild.status == 'complete'" :small="true" color='#02AD0F'>mdi-check-circle-outline</hb-icon>
        </v-list-item-action>
        <v-list-item-content
           @click="activeLinkTab(itemChild)"
          :class="[
            itemChild.status == 'locked' ? 'disableClass' : 'enableClass'
          ]"
        >
          <v-list-item-title>
            <span class="hb-default-font-size" :title="itemChild.title">{{
              itemChild.title
            }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </router-link>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
       title: "",
    };
  },
  computed: {
    ...mapGetters({
      links: "onboardingTabsStore/getPropertyTabs",
      tab: "onboardingTabsStore/getActiveTab"
    })
  },
  methods: {
    activeLinkTab(tabData) {
      this.$store.dispatch("onboardingTabsStore/setActiveTab", tabData);
    }
  }
};
</script>

<style scoped>
.list-item :hover {
  background: #e0f5f5;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.ml-60 {
  margin-left: 60px;
}

.v-list-item__title {
  font-size: 14px !important;
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: break-spaces !important;
}
.list-item-child{
  min-height:2px;
}
.list-item-child:hover {
  background: #e0f5f5;
}
.activeClass {
  background: #e0f5f5 !important;
  color: #101318;
}

.notActive {
  background: #ffffff;
  color: #637381;
}

.disableClass {
  pointer-events: none;
  color: #637381;
}
.enableClass {
  cursor: pointer;
}

.v-list-group--active > .v-list-group__header {
  background: #e0f5f5;
}
.v-list-group--active > .v-list-group__header span,
.v-list-group--active > .v-list-group__header i {
  color: black !important;
  font-weight: 400 !important;
}
a {
  text-decoration: none;
}
/* need to change z index in mobile view */
.navigation {
  z-index: 0 !important;
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  .navigation {
  z-index: 2 !important;
} */
/* } */
</style>
