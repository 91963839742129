<template>
    <div>
        <div class="slide-out-header-nav">
            <div class="slide-out-header-nav-item close" @click="$emit('close')">
                <div class="icon">
                    </div>
            </div>
        </div>
        <!-- CONTACT INFO -->
        <div class="slide-out-tenant-header">
            <div class="slide-out-header-icon">
                <div class="lg-tenant-icon">
                    <div class="text-block-4">{{ contact.first[0] | capitalize }}{{contact.last[0] | capitalize }}</div>
                </div>
            </div>
            <div class="slide-out-tenant-info">
                <h2 class="slide-out-tenant-heading">
                    {{contact.salutation}} {{contact.first}} {{contact.middle}} {{contact.last}} {{contact.suffix}}</h2>
                <div class="w-row" v-if="contact.Phones.length">
                    <div class="column w-col w-col-6">
                        <div>{{contact.Phones[0].type}}</div>
                    </div>
                    <div class="w-col w-col-6">
                        <div>{{contact.Phones[0].phone | formatPhone}}</div>

                    </div>
                </div>
                <div class="w-row">
                    <div class="column w-col w-col-6">
                        <div>Email:</div>
                    </div>
                    <div class="w-col w-col-6">
                        <div>{{contact.email}}</div>
                    </div>
                </div>
            </div>

        </div>
        <!-- / CONTACT INFO -->

        <!-- SUBNAV -->
        <div class="slide-out-subnav">
            <div class="slide-out-nav-item" :class="{active: view == 'overview'}" @click="view = 'overview'">
                <div class="icon"><strong></strong></div>
                <div>General Info</div>
            </div>
            <div class="slide-out-nav-item" :class="{active: view == 'notifications'}" @click="view = 'notifications'">
                <div class="icon"><strong></strong></div>
                <div>Notifications</div>
            </div>

            <div class="slide-out-nav-item" :class="{active: view == 'permissions'}" @click="view = 'permissions'">
                <div class="icon"><strong></strong></div>
                <div>Permissions</div>
            </div>

            <div class="slide-out-nav-item" :class="{active: view == 'activity'}" @click="view = 'activity'">
                <div class="icon"><strong></strong></div>
                <div>Activity</div>
            </div>


        </div>

        <!-- END SUBNAV -->

        <overview v-if="view == 'overview'" :contact="contact"></overview>
        <activity v-if="view == 'activity'" :contact_id="contact.id"></activity>
        <notifications v-if="view == 'notifications'" :contact_id="contact.id"></notifications>
        <permissions v-if="view == 'permissions'" :contact_id="contact.id"></permissions>
    </div>

    </div>

</template>

<script type="text/babel">
    import Activity from './Activity.vue'
    import Overview from './Overview.vue'
    import Permissions from './Permissions.vue'
    import Notifications from './Notifications.vue'

    export default{
        name: "AdminView",
        data(){
            return {
                showContact: false,
                view: null,
            }
        },
        props: {
            contact: {
                type:Object
            },
            show: {
                type: String,
                default: 'overview'
            },
            application_id:{
                required: false
            }
        },
        components:{
            Activity,
            Overview,
            Permissions,
            Notifications
        },
        created(){
            this.view = this.show;
        },
        methods:{
            selectOption(option){

            }
        }

    }


</script>