<template>
    <div class="auction">
      <AuctionSettings :isOPS="true" ref="auction"></AuctionSettings>
    </div>
</template>

<script>
import AuctionSettings from '../../../settings/Auctions/Index.vue';

export default {
 components: {
   AuctionSettings
 },
};
</script>

<style lang="scss">
  .auction {
     margin-top: -10px;
  }
</style>