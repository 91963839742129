<template>
  <v-app-bar app clipped-left style="background: #00848e; padding-left: 15px">
    <v-tooltip bottom open-delay="750" style="background: #334450">
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            class="ml-n2"
            :class="{ 'hb-button-icon-hamburger-hover': hover }"
            v-on="on"
            icon
            :ripple="false"
            color="#ffffff"
            @click="drawerToggle"
          >
            <hb-btn
              v-show="drawer"
              icon
              color="white"
              active-state-off
              hover-color="white"
              hover-background-color="internal-light-dark"
              :tooltip="drawer ? 'Hide Navigation' : 'Show Navigation'"
            >
              mdi-custom-main-menu
            </hb-btn>
            <hb-btn
              v-show="!drawer"
              icon
              color="white"
              active-state-off
              hover-color="white"
              hover-background-color="internal-light-dark"
              :tooltip="drawer ? 'Hide Navigation' : 'Show Navigation'"
            >
              mdi-arrow-expand-right
            </hb-btn>
          </v-btn>
        </v-hover>
      </template>
    </v-tooltip>
    <span>
      <i
        data-v-220c93f6=""
        aria-hidden="true"
        class="v-icon notranslate mdi mdi-settings theme--light;"
        style="font-size: 24px; color: #fff; padding-left: 10px"
      ></i>
    </span>
    <span class="ml-4" style="color: #fff"> Online Property Setup </span>
    <v-spacer />

    <progress-bar
      :value="onboardingPercentage"
      :color="onboardingPercentage == 100 ? '#32CD32' :'#FB4C4C'"
      :bgColor="'#DFE3E8'"
    ></progress-bar>

    <div class="py-2 px-4 dueDate text-no-wrap rounded-pill">
      Due Date: {{ dueDate }} Days
    </div>

    <img
      class="mt-n1 ml-3 mr-sm-1 ti-logo"
      src="/img/tenantinc-logo-white.png"
    />
    <a href="https://tenantinc.zendesk.com/hc/en-us/requests/new" target="blank" style="margin-top: 2px">
      <hb-btn color="white" icon  :activeStateOff="true" hover-background-color="none" hover-color="white" :tooltip="toolTipData">mdi-information</hb-btn>
    </a>
  </v-app-bar>
</template>

<script>
import ProgressBar from "../ProgressBar/Index.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "Header",
  data() {
    return {
      today: new Date(),
      color: ""
    };
  },
  components: {
    ProgressBar
  },
  methods: {
    drawerToggle() {
      this.$emit("toggle-drawer");
    },
    colorUpdate() {
      if (this.onboardingPercentage == 100) {
        this.color = "#02AD0F";
      } else {
        this.color = "#FB4C4C";
      }
    }
  },
  mounted() {
    this.colorUpdate();
  },
  watch: {
    onboardingPercentage() {
      this.colorUpdate();
    }
  },
  computed: {
    ...mapGetters({
      onboardingPercentage: "onboardingTabsStore/getProgressPercentage",
      onboardingData: "onboardingTabsStore/getOnboardingData"
    }),
    toolTipData() {
      if(this.onboardingData.company.length) {
        return (this.onboardingData.company[0].tech_contact_name + " |" + 
        this.$options.filters.formatPhone((this.onboardingData.company[0].tech_contact_phone)) + " | " +
        this.onboardingData.company[0].tech_contact_email);
      } else {
        return "";
      }
    },
    dueDate() {
      if (
        this.onboardingData &&
        this.onboardingData.propertyData.length &&
        this.onboardingData.propertyData[0].due_date
      ) {
        var debutDate = new Date();
        var finDate = new Date(this.onboardingData.propertyData[0].due_date);
        if (Math.abs(finDate) <= Math.abs(debutDate)) {
          return 0;
        } else {
          return Math.ceil(Math.abs(finDate - debutDate) / (1000*60*60*24));
        }
      }
      return "";
    }
  },

  props: ["drawer"]
};
</script>

<style scoped lang="scss">
.header {
  color: #fff;
  position: fixed;
  right: 0;
  left: 0;
  .hb-menu-icon {
    display: block;
    padding: 13px 10px;
  }

  .hb-quick-actions {
    color: #101318;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 320px;
    background: rgba(255, 255, 255, 1);
  }
}
.main {
  padding-top: 60px;
}
.ti-logo {
  width: 120px;
}
.dueDate {
  border: 2px solid white;
  color: white;
}
</style>
