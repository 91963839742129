<template>
  <div>
    <StateSpecificDocuments
      @openUploadDocumentDialog="openUploadDocumentDialog"
      @onDownloadDocument="downloadDocument"
      @onSelectDocumentCheckbox="selectDocumentCheckbox"
      @onDeleteDocument="deleteDocument"
      ref="StateSpecificDocuments"
    ></StateSpecificDocuments>
    
    <div class="divider"></div>
    
    <GeneralDocuments
      @openUploadDocumentDialog="openUploadDocumentDialog"
      @onDownloadDocument="downloadDocument"
      @onSelectDocumentCheckbox="selectDocumentCheckbox"
      @onDeleteDocument="deleteDocument"
      ref="GeneralDocuments"
    ></GeneralDocuments>
    
    <div class="divider"></div>
    
    <OtherDocuments
      @openUploadDocumentDialog="openUploadDocumentDialog"
      @onDownloadDocument="downloadDocument"
      @onDeleteDocument="deleteDocument"
      ref="OtherDocuments"
    ></OtherDocuments>

    <UploadDocument
       v-if="uploadDocument_dialog"
      :data="document"
      :type="docType"
      @closeUploadDocumentDialog="closeUploadDocumentDialog"
      @onDocumentUploaded="documentUploaded"
    ></UploadDocument>
  </div>
</template>

<script>
import StateSpecificDocuments from "./StateSpecificDocuments.vue";
import GeneralDocuments from "./GeneralDocuments.vue";
import OtherDocuments from "./OtherDocuments.vue";
import UploadDocument from "./UploadDocument.vue";
import api from "../../../../assets/api.js";
import moment from 'moment';
import { EventBus } from '../../../../EventBus';
import { notificationMixin } from  '../../../../mixins/notificationMixin.js';

export default {
  mixins: [notificationMixin],
  components: {
    StateSpecificDocuments,
    GeneralDocuments,
    OtherDocuments,
    UploadDocument,
  },
  data() {
    return {
      uploadDocument_dialog: false,
      document: {},
    };
  },
  methods: {
    openUploadDocumentDialog(doc, docType) {
      this.document = doc;
      this.docType = docType;
      this.uploadDocument_dialog = true;
    },
    closeUploadDocumentDialog() {
      this.uploadDocument_dialog = false;
    },
    downloadDocument(item, child) {
      this.$http .post(api.ONBOARDING + "download-document/" + item.id, null, { responseType: "blob", }) .then((res) => {
          var blob = new Blob([res.body], { type: res.headers.get("content-type"), });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = item.override_document_name || item.name;
          link.click();
          if (child === "stateSpecificDocuments") {
            this.$refs.StateSpecificDocuments.items[item.index].isDownloading = false;
            this.$refs.StateSpecificDocuments.fetchData();
          } 
          if (child === "generalDocuments"){
            this.$refs.GeneralDocuments.items[item.index].isDownloading = false;
            this.$refs.GeneralDocuments.fetchData();
          }
          if (child === "otherDocuments"){
            this.$refs.OtherDocuments.items[item.index].isLoading = false;
          }
        });
    },
    selectDocumentCheckbox(item, child) {
      api .post(this, api.ONBOARDING + "confirm-document", { id: item.id, status: item.status }) .then((data) => {
          if (data.status === "confirmed") {
            let message = "Your '"+item.name+"' has been confirmed on "+moment().format('MMM DD, YYYY @ h:mma.');
            this.showMessageNotification({ type: 'success', description: message });
          }
          if (child === "stateSpecificDocuments") {
            this.$refs.StateSpecificDocuments.fetchData();
          }
          if (child === "generalDocuments"){
            this.$refs.GeneralDocuments.fetchData();
          }
        })
        .catch((err) => {
          this.showMessageNotification({ description: err });
        });
    },

    documentUploaded(type) {
        this.uploadDocument_dialog = false;
        if (type === "state") {
          this.$refs.StateSpecificDocuments.fetchData();
        }
        if (type === "general"){
          this.$refs.GeneralDocuments.fetchData();
        }
        if (type === "other"){
          this.$refs.OtherDocuments.fetchData();
        }
    },
    async deleteDocument(item, child) {
      await this.$http.delete(api.ONBOARDING + "delete-document/" + item.id).then((res) => {
          let message = "The '"+item.label+"' has been restored to use the Tenant Pro-Doc on "+moment().format('MMM DD, YYYY @ h:mma.');
          if(child == 'other'){
            message = "The '"+item.label+"' has been deleted on "+moment().format('MMM DD, YYYY @ h:mma.');
          }
          this.showMessageNotification({ type: 'success', description: message });
          if (child === "stateSpecificDocuments") {
            this.$refs.StateSpecificDocuments.items[item.index].isDeleting = false;
            this.$refs.StateSpecificDocuments.fetchData();
          } 
          if (child === "generalDocuments"){
            this.$refs.GeneralDocuments.items[item.index].isDeleting = false;
            this.$refs.GeneralDocuments.fetchData();
          }
          if (child === "other"){
            this.$refs.OtherDocuments.items[item.index].isLoading = false;
            this.$refs.OtherDocuments.fetchData();
          }
        })
        .catch((err) => {
          this.showMessageNotification({ description: err.data.msg });
        });
    },
  },
};
</script>

<style  scoped>
.divider {
  margin-top: 5px;
}
</style>
