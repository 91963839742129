<template>
    <div>
        <div class="pagination">
                <div class="pagination-search">
                    <!--<input-->
                            <!--type="text"-->
                            <!--class="pagination-search-input input w-input"-->
                            <!--name="interaction-search"-->
                            <!--data-name="interaction-search"-->
                            <!--placeholder="Search Interactions"-->
                            <!--id="interaction-search"-->
                            <!--v-model="filters.search.description"-->
                    <!--&gt;-->
                    <!--<button class="w-button primary-btn" @click="fetchData">Update</button>-->
                </div>
                <div class="section-header-right">
                    <pagination
                            v-model="filters.page"
                            :limit="filters.limit"
                            :offset="filters.offset"
                            :result_count="result_count"
                            @input="fetchData"
                    ></pagination>
                </div>
            </div>


        <div class="activity" v-for="a in activity">
            <div class="activity-icon" :class="['action-' + a.ActivityObject.name, 'status_' + a.ActivityAction.name]">
            </div>
            <div class="activity-text">
                <p>
                   <span class="subdued"> {{a.created | formatLocalDateTime}}</span><br />
                    <span v-html="$options.filters.nl2br(a.message)"></span>
                </p>
            </div>
        </div>

    </div>
</template>


<script type="text/babel">

    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';
    import Pagination from '../assets/Pagination.vue';


    export default {
        name: "AdminActivity",
        data() {
            return {
                loaded:false,
                lead:{
                    id: null
                },
                activity:[],
                convertSelector: '',
                id: null,
                filters:{
                    page: 1,
                    limit: 25,
                    offset: 0
                },
                result_count: 0,


            }
        },
        created(){
            this.id = this.contact_id;
            this.fetchData();
        },
        components:{
            Loader,
            Status,
            Dropdown,
            Datepicker,
            Pagination
        },
        filters:{
            formatDate(value){
                if(!value) return null;
                return moment(value).format('MM/DD/YYYY');
            }
        },
        methods: {

            fetchData(){
                var _this = this;
                this.lead = {};
                this.filters.offset = (this.filters.page - 1) * this.filters.limit;
                
                api.get(this, api.ADMIN + this.contact_id + '/activity', this.filters).then(r => {
                    this.activity = JSON.parse(JSON.stringify(r.activity));
                    this.result_count = r.result_count;
                    _this.loaded = true;
                });
            },

            closeDropdown(){
                //TODO JQUERY FIX
                //$(event.target).closest('.dropdown-menu').dropdown('toggle');
            },
            saveInteraction(){
                // TODO Validate form!
                api.post(this, api.ADMIN + this.contact_id + '/activity').then(r => {
                    this.fetchData();
                });
            }

        },
        props:['contact_id'],
        watch:{
            contact_id(contact_id){
                this.id = contact_id;
                this.fetchData();
            },
            'filters.search': _.debounce(function(){
                this.fetchData();
            }, 150)
        }
    }

</script>

<style scoped>


    .activity {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        border-bottom: 1px solid #dce8ef;
    }

    .activity-icon{
        width: 37px;
        height: 37px;
        line-height: 31px;
        text-align: center;
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        margin: 15px 0px 0px 20px;
        font-size: 16px;

    }

    .activity-icon::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }
    .activity-icon.action-lead::before {
        font-family: 'Fa solid 900';
        font-weight: 900; content: "\f007";
    }

    .activity-icon.action-account::before {
        font-family:'Fa solid 900';
        font-weight: 900; content: "\f084";
    }

    .activity-icon.action-password::before {
        font-family: 'Fa solid 900';
        font-weight: 900; content: "\f084";
    }

    .activity-icon.action-category::before {
        font-family: 'Fa solid 900';
        font-weight: 900; content: "\f022";
    }
    .activity-icon.action-lead_status::before {
        font-family: 'Fa solid 900';
        font-weight: 900; content: "\f007";
    }
    .activity-icon.action-promotion::before {
        font-family: 'Fa solid 900';
        content: "\f06b";
    }

    .activity-text {
        padding: 13px 20px 5px;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }


</style>