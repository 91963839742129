<template class="spreadsheetuploadfile">
  <hb-modal
    v-model="dialogModal"
    size="medium"
    title="Upload Files"
    @close="close()"
    confirmation
    show-help-link
  >
    <template v-slot:content>
      <div class="px-6 font-style">
        <div>
          <b>Note:</b> Make sure that the spreadsheet you are uploading is
          complete.
        </div>
        <div v-if="error" style="color:red;">
          Please upload files of type .csv .xls or .xlsx
        </div>
        <v-file-input
          accept=".csv, .xls, .xlsx"
          v-model="files"
          placeholder="Select Spreadsheet"
          :rules="[rules.size]"
          @change="isCorrectFile()"
          prepend-icon="mdi-paperclip"
        ></v-file-input>
        <div>Supported file types:<b> .csv .xls or .xlsx</b></div>
         <div style="margin-top: 10px" v-if="type !=='products'" >
        <v-radio-group v-model="uploadType" row class="mt-0">
          <v-radio label="Trial Upload" value="trial" ></v-radio>
          <v-radio label="Final Upload" value="final" ></v-radio>
        </v-radio-group>
        </div>

      </div>
    </template>
    <template v-slot:actions>
      <hb-btn color="primary" :disabled="isDisable" @click="upload()"
        >Upload</hb-btn
      >
    </template>
  </hb-modal>
</template>

<script>
import api from "../../../../assets/api";
import { mapGetters, mapActions } from "vuex";
 import { EventBus } from '../../../../EventBus';
 import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
export default {
  mixins: [notificationMixin],
  data() {
    return {
      files: undefined,
      dialogModal: true,
      error: false,
      rules: {
        size: value =>
          !value ||
          value.size < 5242880 ||
          "The file size should not exceed 5MB!"
      },
       uploadType: "trial"
    };
  },
  props: ["uploadLink","type"],
  computed: {
    isDisable() {
      if (this.files && this.files.name && this.files.size < 5242880) {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters({
      onboardingData: "onboardingTabsStore/getOnboardingData"
    })
  },
  methods: {
    isCorrectFile() {
      var re = /(\.csv|\.xls|\.xlsx)$/i;
      if (this.files && this.files.name) {
        if (!re.exec(this.files.name)) {
          this.files = undefined;
          this.error = true;
        } else {
          this.error = false;
        }
      }
    },
    upload() {
      var formData = new FormData();
      formData.append("file", this.files);
      formData.append("property_name", this.onboardingData.property.name);
      formData.append("property_id", this.onboardingData.property.id);
      formData.append("upload_type", this.uploadType);

      this.$store.dispatch("notificationsStore/setValidatingNotification", {
        id: "validation",
        text: "We are currently validating your spreadsheet."
      });
      setTimeout(() => {
        this.$http.post(this.uploadLink, formData).then(
          function(response) {
            this.$emit("changedStatus", "success", this.files.name);
            this.$store.dispatch("notificationsStore/setRemoveNotification", {
              id: "validation_Open",
              formErrors: []
            });
            let currentLocalDate = this.$options.filters.formatDateTimeCustom(
              new Date(),
              "MMM DD, YYYY @ h:mma"
            );
            let message =
              "Your document has been validated on" + currentLocalDate + ".";

            this.showMessageNotification({ type: "success", description: message })
            this.$emit("closeDialog");
            this.dialogModal = false;
          },
          function(err) {
            this.$emit("changedStatus", "error", err.data.data.filename);
            this.$store.dispatch("notificationsStore/setRemoveNotification", {
              id: "validation_Open",
              formErrors: []
            });
            let message = err.data.data.filename
              ? "There was an error with the spreadsheet you uploaded. Download a marked up version of your spreadsheet below so you can see what you must correct. Then, once you've revised the spreadsheet, and addressed the errors, reupload it"
              : err.data.msg;
            this.showMessageNotification({ description: message });
            this.$emit("closeDialog");
            this.dialogModal = false;
          }
        );
      }, 2000);
    },
    close() {
      this.$emit("closeDialog");
      this.dialogModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.px-6 {
  padding: 24px;
}
.pb-6 {
  padding-bottom: 6px;
}

.font-style {
  font-family: "Graphik Web";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #101318;
}
</style>
