<template>
    <div class="CoveragePlans">
      <CoveragePlans :isOPS="true" level="Property" ref="CoveragePlans"></CoveragePlans>
    </div>
</template>

<script>
import CoveragePlans from '../../../settings/InsuranceSettings.vue';
export default {
 components: {
   CoveragePlans
 },
};
</script>

<style lang="scss">
  .CoveragePlans {
     margin-top: -10px;
     margin-right: 5px;
  }
</style>