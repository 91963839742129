<template>
    <div>
        <payment-configuration :isOps="true"></payment-configuration>
    </div>
</template>

<script>
import PaymentConfiguration from '../../../settings/PaymentConfiguration/index.vue';
export default {
    components:{
        PaymentConfiguration
    }
}
</script>