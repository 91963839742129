<template>
  <div>
    <hb-header class="pt-0" fewer-actions>
      <template v-slot:left>
        <hb-page-header v-if="!rightDrawer" :title="tab.title"> </hb-page-header>
        <hb-page-header v-if="(rightDrawer && onboardingData.propertyData.length)" :title="(onboardingData && onboardingData.propertyData.length) ? onboardingData.propertyData[0].name : tab.title"> </hb-page-header>
        <p class="mb-0" v-if="onboardingData.company.length">{{ onboardingData.company[0].name }}</p>
        <p class="mb-0" v-else>{{ companyName}}</p>
      </template>
      <template v-slot:right v-if="tab.tabName !== 'property' && $route.name === 'property'">
        <HbBtn
          class="ml-1"
          icon
          tooltip="Sidebar"
          :class="[rightDrawer ? 'hb-button-icon-hamburger-hover' : hover]"
          :active="rightDrawer"
          @click="toggleNav()"
          mdi-code="mdi-table-actions-custom-1"
        />
      </template>
    </hb-header>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      panel: [0],
      items: 1,
      successMessage:
        "Congratulations! You have completed all the Corporate Settings. You can still go back and make changes to the corporate settings at anytime."
    };
  },
  props: ["rightDrawer"],
  computed: {
    ...mapGetters({
      tab: "onboardingTabsStore/getActiveTab",
      companyName: "authenticationStore/getCompanyName",
      tabData:'onboardingTabsStore/getOnboardingTabs',
      onboardingData:'onboardingTabsStore/getOnboardingData',
    })
  },
  methods: {
    toggleNav() {
      this.$emit("toggle");
    }
  }
};
</script>
<style lang="scss" scoped>
.mt-70 {
  margin-top: 70px;
}

.ml-200 {
  margin-left: 200px;
}

.panel-header-color {
  background: #e7f1ff;
  font-family: "Graphik Web";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #101318;
}
.panel-header-color-box {
  // box-shadow: inset 0px -1px 0px #dfe3e8;
  // border: 1.5px solid #02ad0f;
  border: 2px solid #7b61ff;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
