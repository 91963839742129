<template>
  <div>
    <div v-if="hideBeginBtn" class="get-started-btn-container">
      <hb-btn class="get-started-btn" @click="updateBtnStatus()">
        Begin Setup
      </hb-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "corporateSettings",
  computed: {
    ...mapGetters({
      tabs: "onboardingTabsStore/getOnboardingTabs",
      companyStatus: "onboardingTabsStore/getCompanyStatus",
      menus: "onboardingTabsStore/getMenus"
    }),
    hideBeginBtn() {
      if(this.companyStatus == 'launched' && this.menus.corporate.status == 'locked') {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    updateBtnStatus() {
      this.$store.dispatch("onboardingTabsStore/setActiveTab", this.tabs['corporate']['items']['Administrators']); 
    }
  }
};
</script>

<style>
.get-started-btn-container {
  margin: 15px 0px;
}
.get-started-btn {
  background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  color: white;
}
</style>
