<template>
    <div class="ProductsProperty">
      <ProductsProperty :isOPS="true" level="Property" ref="ProductsProperty"></ProductsProperty>
    </div>
</template>

<script>
import ProductsProperty from '../../../settings/Products.vue';
export default {
 components: {
   ProductsProperty
 },
};
</script>

<style lang="scss">
  .ProductsProperty {
     margin-top: -10px;
     margin-right: 5px;
  }
</style>