<template>
  <div>
      <lease-configuration :isOPS="true" ref="leaseConfigurationRef"></lease-configuration>
  </div>
</template>

<script>
import LeaseConfiguration from '../../../settings/Templates.vue';
import { EventBus } from '../../../../EventBus';

export default {
    name: "LeaseConfigurationLibrary",
    components: {
        LeaseConfiguration
    },
    mounted(){
     // Get event from App footer checkbox
     EventBus.$on('get_leaseConfiguration_data',this.checkForData);
   },
   methods:{
    // Get event from App footer checkbox
    checkForData(){
      // Emit event to footer checkbox
      if (this.$refs.leaseConfigurationRef) {
        let canConfirm = this.$refs.leaseConfigurationRef.templates.length;
        EventBus.$emit("receive_leaseConfiguration_data", {canConfirm : canConfirm});
      }
    },
 },
 destroyed(){
   EventBus.$off('get_leaseConfiguration_data');
 }

}
</script>

<style>

</style>