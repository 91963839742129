<template>
    <div>
        <div>
            <hb-btn class="customize-btn" >
                <span class="checkbox clickable-cursor" @click="onClickCustomizeInHB">
                    <input type="checkbox" class="w-checkbox-input clickable-cursor" v-model="customizeInHB" :disabled="disableCustomizeBtn"/>
                    <label class="hb-text-night clickable-cursor"> Customize in Hummingbird </label>
                </span>
            </hb-btn>
        </div>
        <v-card class="mt-4 mb-3">
            <v-data-table
                :headers="headers"
                :items="documentList"
                item-key="id"
                disable-pagination
                hide-default-footer
                class="hb-data-table hb-data-table-cursor-on elevation-1"
                :loading="isFetching"
                loading-text="Loading... Please wait"
            >
                <template v-slot:[`item.document`]="{ item }">
                    <span class="checkbox" :class="((item.status === 'downloaded') || (item.status === 'confirmed') || (isDownloaded == true)) ? 'clickable-cursor' : 'not-allowed-cursor'" @click="onSelectDocumentCheckbox(item.index)">
                        <input type="checkbox" class="w-checkbox-input clickable-cursor" v-model="item.selected" />
                        <label :class="((item.status === 'downloaded') || (item.status === 'confirmed') ||  (isDownloaded == true)) ? 'clickable-cursor' : 'not-allowed-cursor'"></label>
                    </span>
                    <span>{{ item.label }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <hb-btn color="secondary" @click="onClickDownloadDocument(item)" small>
                        <v-progress-circular v-if="item.isDownloading" indeterminate color="primary" :size="15" :width="2"></v-progress-circular>
                        Download & Review
                    </hb-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { EventBus } from '../../../../EventBus';
import api from '../../../../assets/api.js';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { notificationMixin } from  '../../../../mixins/notificationMixin.js';

export default {
    name: 'DelinquencyProcess',
    mixins: [notificationMixin],
    data() {
        return {
            isFetching: false,
            canConfirm: false,
            customizeInHB: false,
            documentList : [],
            disableCustomizeBtn: false,
            isDownloaded:false,
            headers: [ { text: 'State Specific Delinquency Processes', value: 'document', class: 'label' },
                       { text: "", value: "actions", align: "right", sortable: false, width: 20, class: 'bg-white' } ],
        }
    },

    created() {
        this.fetchData();
        this.isDownloaded = sessionStorage.getItem("isDownloaded");
    },

    mounted(){ 
        // Get event from App footer checkbox
        EventBus.$on('get_delinquency_data',this.checkForData);
        if (this.documentList.length == 0) {
            this.customizeInHB = true;
            this.disableCustomizeBtn = true;
        } else {
            this.disableCustomizeBtn = false;
        }
    },

    computed:{
        ...mapGetters({
               onboardingData: "onboardingTabsStore/getOnboardingData"
        }),
    },

    methods: {
        fetchData() {
           if(this.onboardingData && this.onboardingData.propertyData.length){
                this.isFetching = true;
                api.get(this, api.ONBOARDING + 'documents?type=delinquency&state=' + this.onboardingData.property.Address.state).then(d => {
                    if (d.documentList && d.documentList.length) {
                    // Added extra fields
                        d.documentList.forEach((doc, i) => {
                            doc.index = i;
                            doc.selected = (doc.status === 'confirmed');
                            doc.isDownloading = false;
                        });
                        this.documentList = [...d.documentList];
                        this.customizeInHB = (this.documentList[0].status === 'customized');
                    }
                    this.isFetching = false; 
                }).catch(err => {
                    this.isFetching = false; 
                    this.showMessageNotification({ description: err });
                });
            }
        },

        onClickCustomizeInHB() {
                this.customizeInHB = !this.customizeInHB;
                this.canConfirm = this.customizeInHB;
                this.documentList[0].status = this.customizeInHB ? 'customized' : '';
                let data = {
                    id: this.documentList[0].id,
                    status: this.documentList[0].status
                }
                if (this.customizeInHB) {
                    this.documentList[0].selected = false;
                    api.post(this, api.ONBOARDING + 'confirm-document', data).then(data => {
                    this.documentList[0].status = data.status; 
                    this.customizeInHB = (data.status === 'customized');
                    if (data.status === 'customized') {
                        this.showMessageNotification({ type: 'success', description: 'You can Customize your documents in Hummingbird' });
                    }
                    }).catch(err => {
                        this.customizeInHB = false;
                        this.showMessageNotification({ description: err });
                    });
                }
        },
        
        onClickDownloadDocument(doc) {
            doc.isDownloading = true;
            sessionStorage.setItem("isDownloaded", doc.isDownloading);
            this.isDownloaded = doc.isDownloading;
            this.$http.post( api.ONBOARDING + 'download-document/' + doc.id, null, {responseType: 'blob'}).then( res =>  {
                var blob = new Blob([res.body], {type:res.headers.get('content-type')});
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = doc.name;
                link.click();
                doc.isDownloading = false;
                this.fetchData();
            }).catch(e => {
                doc.isDownloading = false;
                console.log(e);
            });
        },

        onSelectDocumentCheckbox(index) {
            if(this.isDownloaded){
                this.customizeInHB = false;
                this.documentList[index].status = 'downloaded';
            }
            if (!this.customizeInHB && ['downloaded', 'confirmed'].includes(this.documentList[index].status)) {
                this.documentList[index].selected = !this.documentList[index].selected;
                this.documentList[index].status = this.documentList[index].selected ? 'confirmed' : 'downloaded'; 
                this.canConfirm = this.documentList[index].selected ? true : false;
                let data = {
                    id: this.documentList[index].id,
                    status: this.documentList[index].status
                }
                api.post(this, api.ONBOARDING + 'confirm-document', data).then(data => {
                    this.documentList[index].status = data.status; 
                    if (this.documentList[index].status === 'confirmed') {
                        let message = "Your '"+this.documentList[index].name+"' has been confirmed on "+moment().format('MMM DD, YYYY @ h:mma.');
                        this.showMessageNotification({ type: 'success', description: message });
                    } 
                }).catch(err => {
                    this.showMessageNotification({ description: err });
                });
            } else {
                this.showMessageNotification({ description: "Either you have not downloaded the document Or You have selected Customize in Hummingbird." });
            }
        },

        checkForData() {
            this.checkCanConfirm();
            EventBus.$emit("receive_delinquency_data", {canConfirm : this.canConfirm, message : 'Either download, review and confirm the document or Check the checkbox to Customize in Hummingbird'});
        },
        checkCanConfirm() {
            this.canConfirm = this.customizeInHB || this.documentList.find(doc => doc.selected);
        }
    },

    destroyed(){
       EventBus.$off('get_delinquency_data');
   }

}
</script>

<style lang="scss">
    .customize-btn {
        .hb-primary-button {
            background: #E0F5F5 !important;
        }
    }
    .clickable-cursor {
        cursor: pointer;
    }
    .bg-white{
        background-color: #FFFFFF;
    }
    .label{
        background-color: #FFFFFF;
        font-size: 16px;
        color:#101318;
    }
    .doc-name{
        color: black;
        font-size: 14px;
    }
    .not-allowed-cursor {
        cursor: not-allowed;
    }
</style>
